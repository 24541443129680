import React from "react";
import cptCharlie from "../../assets/cptCharlie.png";
import extraTaskImage from "../../assets/pain diary/ExtraTask.png";
const EndMission7Continued = () => {
  return (
    <div className="w-full text-sm max-w-5xl bg-transparent md:w-[70%] lg:h-auto xl:pr-7 fadeIn mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-6 text-center text-[#6BBF59] md:text-3xl lg:text-4xl font-gillSans">
        Mission this week continued
      </h2>
      <div className="flex flex-col lg:flex-row items-center lg:items-start gap-5 text-base font-gillSans">
        {/* Left Text Section */}
        <div className="flex-1 space-y-1 ml-10">
          <p className="lg:text-xl">
            Captain Charlie has an extra task for you to do..
          </p>
          <p className="font-semibold lg:text-xl">
            Extra task: If a body part hurts, try practicing{" "}
            <span className="text-[#267e8c] font-bold">
              Tummy Breathing, using a heat pack, or deep pressure.
            </span>{" "}
            Captain Charlie wants to know if you practiced it during the week.
          </p>
          <p className="lg:text-xl">
            You can watch the video on Tummy Breathing in the resources section.
          </p>
        </div>

        {/* Right Image Section */}
        <div className="flex-1 flex justify-center">
          <img
            src={cptCharlie}
            alt="Captain Charlie"
            className="w-48 md:w-60 lg:w-72"
          />
        </div>
      </div>

     {/* Single Image Below Text */}
     <div className="flex items-center justify-center -mt-16 mr-7">
        <img
          src={extraTaskImage}
          alt="Extra Task Resources"
          className="w-1/2 max-w-3xl"
        />
      </div>
    </div>
  );
};

export default EndMission7Continued;
