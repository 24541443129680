import React from "react";
import mission1 from "../../../assets/Great Work Badges/17.png";
import mission2 from "../../../assets/Great Work Badges/18.png";
import mission3 from "../../../assets/Great Work Badges/19.png";
import mission4 from "../../../assets/Great Work Badges/20.png";
import mission5 from "../../../assets/Great Work Badges/21.png";
import mission6 from "../../../assets/Great Work Badges/22.png";
import mission7 from "../../../assets/Great Work Badges/23.png";
import mission8 from "../../../assets/Great Work Badges/24.png";
import mission9 from "../../../assets/Great Work Badges/25.png";
import mission10 from "../../../assets/Great Work Badges/26.png";
import mission11 from "../../../assets/Great Work Badges/27.png";
import mission12 from "../../../assets/Great Work Badges/28.png";

const GreatWorkBadges = () => {
  const badges = [
    { id: 1, label: "Mission 1", badge: mission1 },
    { id: 2, label: "Mission 2", badge: mission2 },
    { id: 3, label: "Mission 3", badge: mission3 },
    { id: 4, label: "Mission 4", badge: mission4 },
    { id: 5, label: "Mission 5", badge: mission5 },
    { id: 6, label: "Mission 6", badge: mission6 },
    { id: 7, label: "Mission 7", badge: mission7 },
    { id: 8, label: "Mission 8", badge: mission8 },
    { id: 9, label: "Mission 9", badge: mission9 },
    { id: 10, label: "Mission 10", badge: mission10 },
    { id: 11, label: "Mission 11", badge: mission11 },
    { id: 12, label: "Mission 12", badge: mission12 },
  ];

  return (
    <div className="grid grid-cols-4 gap-4 p-6">
      {badges.map((badge) => (
        <div
          key={badge.id}
          className={`flex items-center justify-center p-4`}
        >
          <img src={badge.badge} alt={badge.label} className="w-2/3 mb-2" />          
        </div>
      ))}
    </div>
  );
};

export default GreatWorkBadges;
