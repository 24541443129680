import React, { useEffect } from "react";
import coinIcon from "../../assets/Icons/Coin.png";
import annieImage from "../../assets/pain diary/Heat Pack 2.png";
import sailorAmyImage from "../../assets/Characters/SailorAmy.jpg";
import coins from "../../assets/audios/coins.mp3";

const UsingAHeatPackAmy = ({ onAmyClick }) => {
  useEffect(() => {
    const audio = new Audio(coins);

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-4">
          Using a Heat Pack
          <img src={coinIcon} alt="coin" className="w-14 h-12 ml-4 heartbeat" />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full mt-8">
        {/* Left: Annie Image */}
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={annieImage}
            alt="Annie"
            className="w-full md:w-64 lg:w-72 h-auto"
          />
        </div>

        {/* Right: Text Content */}
        <div className="w-full md:w-1/2 text-lg md:text-xl text-left space-y-4 mr-10">
          <p>
            Excellent job on learning all about using a heat pack when you have
            pain.
          </p>
          <p>
            When you have pain, you can ask your mum/dad or teacher for a heat
            pack to help with your pain.
          </p>
          <p>
            One of Captain Charlie's friends, Sailor Amy, wants to tell you why
            she likes to use a heat pack when her legs hurt. Click on the photo
            of Sailor Amy to hear more.
          </p>
          <div className="flex justify-center mt-4">
            <img
              src={sailorAmyImage}
              alt="Sailor Amy"
              className="w-28 h-28 rounded-full cursor-pointer shadow-lg hover:scale-105 transition-transform"
              onClick={onAmyClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsingAHeatPackAmy;
