import React, { useEffect } from "react";
import parrotImage from "../../assets/parrot.png";
import coinIcon from "../../assets/Icons/Coin.png";
import pg17 from "../../assets/audios/speech/Mission8/Page 17.mp3";
import coins from "../../assets/audios/coins.mp3";

const GoodJob = () => {
  useEffect(() => {
    const audio = new Audio(coins);    
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  useEffect(() => {
    const audio = new Audio(pg17);
    audio.play();

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full">
        {/* Left: Text Content */}
        <div className="w-full md:w-1/2 text-xl space-y-2 text-left ml-10">
          <h2 className="text-3xl md:text-4xl font-bold text-center flex items-center justify-cente mb-10 text-[#6BBF59]">
            Good Job
            <img
              src={coinIcon}
              alt="coin"
              className="w-14 h-12 ml-4 heartbeat"
            />
          </h2>
          <p>You are doing a fantastic job.</p>
          <p>You get an extra coin!</p>
        </div>

        {/* Right: Parrot Image */}
        <div className="w-full md:w-1/2 flex justify-center mr-10">
          <img src={parrotImage} alt="Parrot" className="w-full h-auto" />
        </div>
      </div>
    </div>
  );
};

export default GoodJob;
