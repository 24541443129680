import React from "react";

const PirateQuizReady = ({ carrier, onStart }) => {
    
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn">
      <h2 className="text-2xl md:text-3xl font-bold mb-6 ml-10">Pirate Quiz</h2>
      <p className="mb-4 text-xl ml-10">
        Boatswain {carrier.firstName}, your pirate task is to answer 2 very
        important questions.
      </p>
      <p className="mb-4 text-xl ml-10">Are you ready?</p>
      <button
        onClick={onStart}
        className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md text-xl ml-10"
      >
        Yes, Let’s Go!
      </button>
    </div>
  );
};

export default PirateQuizReady;
