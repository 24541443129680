import React, { useEffect } from "react";
import coinIcon from "../../assets/Icons/Coin.png";
import deepPressureHeadVideo from "../../assets/videos/Deep Pressure part 2.mp4";
import coins from "../../assets/audios/coins.mp3";

const DeepPressureHead = ({ onNext }) => {
  useEffect(() => {
    const audio = new Audio(coins);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-4">
          Deep Pressure
          <img src={coinIcon} alt="coin" className="w-12 h-12 ml-4 heartbeat" />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col items-center justify-between w-full mt-8">
        {/* Left: Text Content */}
        <div className="w-full text-lg md:text-xl text-left space-y-4">
          <p>Annie has another video for you.</p>
          <p>
            You can also use deep pressure when you have a pain in your{" "}
            <span className="font-bold">head</span>.
          </p>
          <p>Let’s watch the video to find out more.</p>
        </div>

        {/* Right: Video Section */}
        <div className="w-full flex flex-col items-center mt-8">
          <video
            controls
            className="w-1/2 rounded-md shadow-lg"
            src={deepPressureHeadVideo}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default DeepPressureHead;
