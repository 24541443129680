import React, { useEffect, useRef } from "react";
import tummyBreathing from "../../assets/videos/2. Tummy Breathing_F.mp4";

const YourTurnToTummyBreathing = () => {
    const audioRef = useRef(null);

    useEffect(() => {
        // Automatically play the audio when the component loads
        if (audioRef.current) {
            audioRef.current.play();
        }
    }, []);

    return (
        <div className="w-full max-w-5xl mx-auto p-8 bg-transparent fadeIn">
            <h2 className="text-3xl font-bold mb-4 text-center md:text-4xl font-gillSans mt-10">
                Your Turn: Tummy Breathing
            </h2>
            <div className="flex justify-center mt-4">
                <p className="text-center text-gray-700 font-gillSans text-xl">
                    When you are ready to practice Tummy Breathing, play the video below.
                </p>
            </div>
            <div className="flex items-center justify-center mt-6">
                <video
                    src={tummyBreathing}
                    controls
                    className="w-2/3 max-w-xl h-auto mb-4"
                    loop={false}
                ></video>
            </div>           
        </div>
    );
};

export default YourTurnToTummyBreathing;
