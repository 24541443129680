import React, { useEffect, useRef, useState } from "react";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import pg45Q1 from "../../assets/audios/speech/Mission7/pg45Q1.mp3";
import pg45Q2 from "../../assets/audios/speech/Mission7/pg45Q2.mp3";
import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";
import Badge7 from "./Badge7";


const PirateQuizQuestions = ({ setIsOnChildComponent }) => {
  const [currentStep, setCurrentStep] = useState(0); // Track current question
  const [completedSteps, setCompletedSteps] = useState([]); // Track completed steps
  const [selectedOption, setSelectedOption] = useState(null); // Track selected option
  const [isCorrect, setIsCorrect] = useState(null); // Track answer correctness
  const audioRef = useRef(new Audio(coins)); // Ref for coin audio
  const questionAudioRef = useRef(null); // Ref for question-specific audio

  const questions = [
    {
      question: "Why is Tummy Breathing important?",
      options: [
        { text: "It helps you run faster", isCorrect: false },
        {
          text: "It helps makes you feel better when you are in pain",
          isCorrect: true,
        },
        { text: "It makes you laugh", isCorrect: false },
      ],
      audio: pg45Q1,
    },
    {
      question: "When can you do Tummy Breathing? \n",
      options: [
        { text: "When you have pain (body part hurts)", isCorrect: true },
        {
          text: "When you are hungry.",
          isCorrect: false,
        },
        { text: "When you are playing outside.", isCorrect: false },
      ],
      audio: pg45Q2, // Audio for question 2
    },
  ];

  const playCoinAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  useEffect(() => {
    playCoinAudio();
  }, []);

  // Play the audio associated with each question on load and stop the previous one
  useEffect(() => {
    // Stop previous audio if playing
    if (questionAudioRef.current) {
      questionAudioRef.current.pause();
      questionAudioRef.current.currentTime = 0; // Reset playback position
    }

    // Create new audio for the current question and play it
    if (currentStep < questions.length) {
      questionAudioRef.current = new Audio(questions[currentStep].audio);
      questionAudioRef.current.play();
    }

    return () => {
      // Clean up by pausing the audio if the component unmounts or question changes
      if (questionAudioRef.current) {
        questionAudioRef.current.pause();
      }
    };
  }, [currentStep]);

  // Disable forward arrow when on a child question component
  useEffect(() => {
    if (currentStep !== null && currentStep < questions.length) {
      setIsOnChildComponent(true); // Disable forward arrow when a question is active
    } else {
      setIsOnChildComponent(false); // Enable forward arrow when quiz is completed
    }
  }, [currentStep, setIsOnChildComponent]);

  const handleOptionClick = (index, isCorrect) => {
    setSelectedOption(index);
    setIsCorrect(isCorrect);

    if (isCorrect) {
      setCompletedSteps([...completedSteps, currentStep]); // Mark step as completed
      setTimeout(() => {
        setCurrentStep((prev) => prev + 1); // Move to the next step after a delay
        setIsCorrect(null); // Reset state for next question
        setSelectedOption(null); // Reset selected option
      }, 2000); // 2-second delay
    }
  };

  if (currentStep >= questions.length) {
    sessionStorage.setItem("PirateQuizCompleted", "true");
    return (
      <div className="text-center">
        <Badge7 />
      </div>
    );
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-xl font-bold mb-4 text-center text-[#F79D84] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
        Pirate Quiz
        <img src={coin} alt="coin" className="w-12 heartbeat" />
      </h2>

      {/* Display the current question */}
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="text-xl">
          <li className="mt-1">{questions[currentStep].question}</li>

          {/* Display the options */}
          {questions[currentStep].options.map((option, index) => (
            <div
              key={index}
              className={`flex items-center mt-4 cursor-pointer ${
                isCorrect === true && selectedOption === index
                  ? "bg-yellow-200"
                  : isCorrect === false && selectedOption === index
                  ? "bg-red-200"
                  : ""
              }`}
              onClick={() => handleOptionClick(index, option.isCorrect)}
            >
              <span className="mr-4">{String.fromCharCode(97 + index)}. </span>
              <span>{option.text}</span>

              {/* Show feedback icon */}
              {selectedOption === index && (
                <img
                  src={option.isCorrect ? greenTick : redCross}
                  alt={option.isCorrect ? "Correct" : "Incorrect"}
                  className="ml-4 w-6 h-6"
                />
              )}
            </div>
          ))}

          {/* If the answer is incorrect, show the message */}
          {isCorrect === false && (
            <div className="text-red-500 mt-4 font-bold">
              Oops, let’s try again!
            </div>
          )}
        </ol>
      </div>
    </div>
  );
};

export default PirateQuizQuestions;
