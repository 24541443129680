import React, { useState } from "react";
import axios from "axios";
import cptCharlie from "../../assets/cptCharlie.png";
import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";
import CorrectHPQuiz from "./CorrectHPQuiz";
import IncorrectHPQuiz from "./IncorrectHPQuiz";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HeatPackTask = ({ setSection, carrier, audioRef }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const options = [
    { id: "a", text: "Run around" },
    { id: "b", text: "Eat food" },
    { id: "c", text: "Use a heat pack on the body part that hurts" },
  ];

  const handleOptionClick = (optionId) => {
    if (!isSubmitted) {
      setSelectedOption(optionId);
    }
  };

  const saveActivity = async () => {
    const activityValue = options.find(option => option.id === selectedOption)?.text;
    const payload = [
      {
        userId: carrier?.userId,
        missionId: "M8",
        pageNum: 28, 
        activityId: "HPQuiz",
        activityValue,
      },
    ];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      console.log("Activity saved successfully:", response.data);
      toast.success("Activity saved successfully!");
    } catch (error) {
      console.error("Error saving activity:", error);
      toast.error("Error saving activity. Please try again.");
    }
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    const correctAnswer = selectedOption === "c";
    setIsCorrect(correctAnswer);

    // Save activity via Axios
    await saveActivity(correctAnswer);

    if (correctAnswer) {
      sessionStorage.setItem("HeatPackTaskCompleted", "true");
    }
  };

  const handleTryAgain = () => {
    setIsSubmitted(false);
    setSelectedOption(null);
    setIsCorrect(null);
    setSection(25);
  };

  if (isSubmitted) {
    return isCorrect ? (
      <CorrectHPQuiz carrier={carrier} audioRef={audioRef} />
    ) : (
      <IncorrectHPQuiz onTryAgain={handleTryAgain} />
    );
  }

  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full mt-10">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-4">
          Task
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full mt-8">
        {/* Left: Pirate Image */}
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={cptCharlie}
            alt="Pirate"
            className="w-full md:w-64 lg:w-72 h-auto"
          />
        </div>

        {/* Right: Task Content */}
        <div className="w-full md:w-1/2 text-lg md:text-xl text-left space-y-2 mr-20 -mt-20">
          <p>
            Excellent work learning all about using a heat pack. Keep up the
            good work! There is a special badge waiting for you at the end of
            this mission.
          </p>
          <p>
            Captain Charlie wants to test your knowledge on using a heat pack
            when a body part hurts.
          </p>
          <p className="text-xl font-semibold">
            When you have pain, what can you do to help you feel better?
          </p>
          <ul className="space-y-1">
            {options.map((option) => (
              <li
                key={option.id}
                className={`flex items-center text-lg cursor-pointer ${
                  selectedOption === option.id && !isSubmitted
                    ? "bg-yellow-200"
                    : ""
                }`}
                onClick={() => handleOptionClick(option.id)}
              >
                <span className="mr-3">{`(${option.id})`}</span>
                <span className="text-xl font-semibold">{option.text}</span>
                {isSubmitted &&
                  selectedOption === option.id &&
                  option.id === "c" && (
                    <img
                      src={greenTick}
                      alt="Correct"
                      className="ml-4 w-6 h-6"
                    />
                  )}
                {isSubmitted &&
                  selectedOption === option.id &&
                  option.id !== "c" && (
                    <img
                      src={redCross}
                      alt="Incorrect"
                      className="ml-4 w-6 h-6"
                    />
                  )}
              </li>
            ))}
          </ul>
          {!isSubmitted && (
            <button
              onClick={handleSubmit}
              className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md"
            >
              Submit
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default HeatPackTask;
