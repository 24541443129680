import React, { useState } from "react";
import sailorpolly from "../../assets/Characters/SailorPolly.png";
import mateyMorgan from "../../assets/Characters/MateyMorgan.png";
import TummyBreathingWithPolly from "./TummyBreathingWithPolly";
import MateyMorganStory from "./MateyMorganStory";
import MateyMorganStory2 from "./MateyMorganStory2";

const TummyBreathingStories = ({ audioRef }) => {
    const [currentChild, setCurrentChild] = useState(null); // Track the active child component
    const [completedSteps, setCompletedSteps] = useState([]); // Track completed steps

    // Handle navigation to child components
    const handleChildNavigation = (childName) => {
        setCurrentChild(childName); // Set the current child to display
    };

    // Handle returning from a child component
    const handleNext = (completedStep) => {
        setCompletedSteps((prev) => [...prev, completedStep]); // Mark the step as completed
        setCurrentChild(null); // Return to the parent component
    };

    // Rendering child components based on currentChild state
    if (currentChild) {
        switch (currentChild) {
            case "sailorPolly":
                return <TummyBreathingWithPolly onNext={() => handleNext("sailorPolly")} audioRef={audioRef} />;
            case "mateyMorgan":
                return <MateyMorganStory onPlay={() => setCurrentChild("mateyMorganStory2")} audioRef={audioRef} />;
            case "mateyMorganStory2":
                return <MateyMorganStory2 onNext={() => handleNext("mateyMorgan")} />;
            default:
                return null;
        }
    }

    return (
        <div className="w-full text-sm max-w-4xl bg-transparent md:w-[80%] lg:h-auto xl:h-auto xl:pr-7 fadeIn mx-auto mt-10">
            {/* Header Section */}
            <div className="text-center mb-6">
                <h1 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center mt-10">
                    Tummy Breathing: Stories
                </h1>
                <p className="text-lg md:text-xl mt-6">
                    Listen to Sailor Polly and Matey Morgan’s story on Tummy Breathing.
                </p>
                <p className="text-lg md:text-xl mt-6">
                    Click on their pictures to hear more.
                </p>
            </div>

            {/* Characters Section */}
            <div className="grid grid-cols-2 gap-4 md:gap-8 justify-items-center">
                <div
                    className={`flex flex-col items-center cursor-pointer ${
                        completedSteps.includes("sailorPolly") ? "opacity-50" : ""
                    }`}
                    onClick={() => handleChildNavigation("sailorPolly")}
                >
                    <img src={sailorpolly} alt="sailorpolly" className="w-40 h-40 object-cover mb-2" />
                    <span className="text-xl font-bold">Sailor Polly</span>
                </div>
                <div
                    className={`flex flex-col items-center cursor-pointer ${
                        completedSteps.includes("mateyMorgan") ? "opacity-50" : ""
                    }`}
                    onClick={() => handleChildNavigation("mateyMorgan")}
                >
                    <img src={mateyMorgan} alt="mateyMorgan" className="w-40 h-40 object-cover mb-2" />
                    <span className="text-xl font-bold">Matey Morgan</span>
                </div>
            </div>
        </div>
    );
};

export default TummyBreathingStories;
