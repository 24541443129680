import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import helpAudio from "../../assets/audios/INeedHelp.mp3";
import SchoolAudio from "../../assets/audios/speech/Common/School.mp3";
import Annie2 from "../../assets/audios/speech/Mission 2/Annie2.mp3";
import Annie3 from "../../assets/audios/speech/Mission 2/Annie3.mp3";
import BodyMap1 from "../../assets/audios/speech/Mission 2/BodyMap1.mp3";
import BodyMapIntro from "../../assets/audios/speech/Mission 2/BodyMapIntro.mp3";
import bonusPointAudio from "../../assets/audios/speech/Mission 2/bonusPointAudio.mp3";
import Congratulations from "../../assets/audios/speech/Mission 2/Congratulations.mp3";
import FirstThenAudio from "../../assets/audios/speech/Mission 2/FirstThenAudio.mp3";
import QuickTaskAudio from "../../assets/audios/speech/Mission 2/M2 pg10 quick task.mp3";
import BodyPartHurts from "../../assets/audios/speech/Mission 2/M2 pg14 Knowing ur bodyparts.mp3";
import BodyPartHurtsPointing from "../../assets/audios/speech/Mission 2/M2 pg15 POinting to bodyparts.mp3";
import AnnieAudio from "../../assets/audios/speech/Mission 2/M2 pg21 Quest1 Annie.mp3";
import yourTurnAudio from "../../assets/audios/speech/Mission 2/M2 pg37 Now it's your turn.mp3";
import ThankyouAudio from "../../assets/audios/speech/Mission 2/M2 pg38 Excellent Job.mp3";
import BadgeAudio from "../../assets/audios/speech/Mission 2/M2 pg39 Special badge.mp3";
import BodyPartsAudio from "../../assets/audios/speech/Mission 2/M2 pg9 Recap.mp3";
import AnnieHurtAudio from "../../assets/audios/speech/Mission 2/M2 touch body map hurt annie.mp3";
import M2_21 from "../../assets/audios/speech/Mission 2/M2_21.mp3";
import m2pg1 from "../../assets/audios/speech/Mission 2/M2pg1.mp3";
import MissionthisWeekAudio from "../../assets/audios/speech/Mission 2/M2pg5.mp3";
import PirateQuizAudio from "../../assets/audios/speech/Mission 2/PirateQuizAudio.mp3";
import Quest1QuizCorrect from "../../assets/audios/speech/Mission 2/Quest1QuizCorrect.mp3";
import Quest1QuizInCorrect from "../../assets/audios/speech/Mission 2/Quest1QuizInCorrect.mp3";
import Quest2QuizCorrect from "../../assets/audios/speech/Mission 2/Quest2QuizCorrect25.mp3";
import Quest2QuizInCorrect from "../../assets/audios/speech/Mission 2/Quest2QuizInCorrect25.mp3";
import Quest3QuizCorrect28 from "../../assets/audios/speech/Mission 2/Quest3QuizCorrect28.mp3";
import QT10 from "../../assets/audios/speech/Mission 2/QuickTask10.mp3";
import QuizCompleted20 from "../../assets/audios/speech/Mission 2/QuizCompleted20.mp3";
import SeeYou from "../../assets/audios/speech/Mission 2/SeeYou.mp3";
import ThankAudio from "../../assets/audios/speech/Mission 2/ThankAudio.mp3";
import TouchParts from "../../assets/audios/speech/Mission 2/TouchParts.mp3";
import VisualAudio from "../../assets/audios/speech/Mission 2/VisualAudio.mp3";
import VisualAudio1 from "../../assets/audios/speech/Mission 2/VisualAudio1.mp3";
import VisualAudio3 from "../../assets/audios/speech/Mission 2/VisualAudio3.mp3";
import VisualAudio4 from "../../assets/audios/speech/Mission 2/VisualAudio4.mp3";
import VisualAudio5 from "../../assets/audios/speech/Mission 2/VisualAudio5.mp3";
import WelcomeBackAudio from "../../assets/audios/speech/Mission 2/WelcomeBack.mp3";
import Welldone from "../../assets/audios/speech/Mission 2/Welldone.mp3";
import MissionThisWeekAudio from "../../assets/audios/speech/Mission1/CommonMissionThisWeek.mp3";
import homeButton from "../../assets/homeBtn.png";
import sectionsBG from "../../assets/sectionsBG.png";
import speaker from "../../assets/speaker.png";
import BonusPoint from "../BonusPoint";
import BreakTimer from "../BreakTImer";
import ExcellentJob from "../ExcellentJob";
import FirstThenBoard from "../FirstThenBoard";
import ProgressBar from "../ProgressBar";
import Ready from "../Ready";
import School from "../School";
import Badge2 from "./Badge2";
import BodyMapSection from "./BodyMapSection";
import BodyMapSectionDone from "./BodyMapSectionDone";
import BodyMapSectionIntro from "./BodyMapSectionIntro";
import BodyPartSection from "./BodyPartSection";
import BodyPartSectionDone from "./BodyPartSectionDone";
import EndMission2 from "./EndMission2";
import Mission2Complete from "./Mission2Complete";
import Mission2Intro from "./Mission2Intro";
import MissionPath1 from "./MissionPath1";
import MissionPath2 from "./MissionPath2";
import MissionPath3 from "./MissionPath3";
import MissionPath4 from "./MissionPath4";
import MissionPath5 from "./MissionPath5";
import MissionPath6 from "./MissionPath6";
import MissionThisWeek2 from "./MissionThisWeek2";
import PQIntro from "./PQIntro";
import Quest1 from "./Quest1";
import Quest1Quiz from "./Quest1Quiz";
import Quest2 from "./Quest2";
import Quest2Quiz from "./Quest2Quiz";
import Quest3 from "./Quest3";
import Quest3Quiz from "./Quest3Quiz";
import QuickTask from "./QuickTask";
import Recap from "./Recap";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section4 from "./Section4";
import Section5 from "./Section5";
import SeeYou2 from "./SeeYou2";
import TaskTime from "./TaskTime";
import ThankYou from "./ThankYou";
import TopMission from "./TopMission";
import WelcomeBack2 from "./WelcomeBack2";

const Mission2 = () => {
  // Initialize the section state using useState
  const [section, setSection] = useState(1);
  const [isBreak, setIsBreak] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isOnChildComponent, setIsOnChildComponent] = useState(false);
  const audioRef = useRef(null);
  // Function to handle answer submission from Activity component
  const handleSubmit = (isSubmitted) => {
    setSubmitted(isSubmitted);
  };

  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  ); // Initialize with session storage value
  // useNavigate init.
  const navigate = useNavigate();

  // Decrease the section value when "Previous" button is clicked
  const handlePrevious = () => {
    setSubmitted(false);
    setSection(section - 1);
  };
  
  const location = useLocation();
  const carrier = location.state?.carrier;
  const userId = carrier?.userId;
  const saveStarsCollected = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}saveStarsCollected`,
        {
          userId: userId,
          missionId: 2, 
          starsCollected: 15, 
          totalStars: 15, 
        }
      );
      if (response.status === 200) {
        const { data } = response.data;
        
      }
    } catch (error) {
      console.error("Error saving stars:", error);
    }
  };

  if (section === 39) {
    saveStarsCollected();
  }

  // Determine if the "Next" button should be disabled
  const handleNext = () => {
    if (isOnChildComponent) {
      console.log("Please complete the current step by clicking Next.");
      return;
    }
    if (
      (section === 6 && !sessionStorage.getItem("schoolQuizCompleted")) ||
      (section === 10 && !sessionStorage.getItem("quickTaskCompleted")) ||
      (section === 20 && !sessionStorage.getItem("taskCompleted")) ||
      (section === 23 && !sessionStorage.getItem("quest1Completed")) ||
      (section === 25 && !sessionStorage.getItem("quest2Completed")) ||
      (section === 28 && !sessionStorage.getItem("quest3Completed")) ||
      (section === 32 && !sessionStorage.getItem("PQ2Completed"))
    ) {
      toast.error("Please complete the quiz before proceeding.");
      return;
    }

    if (isLastPage) {
      navigate("/map", { state: { carrier } });
    } else {
      setSection(section + 1);
    }

    if ([10, 20, 23, 25, 28].includes(section)) {
      setSubmitted(false);
    }
    // console.log(section);
  };

  const isPreviousDisabled = section === 1;
  const isLastPage = section === 40;

  const totalSections = 40;

  // Function to handle home button click
  const handleHomeClick = async () => {   
    navigate(-1);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    sessionStorage.setItem("firstThenInput", value); // Save to session storage
  };

  // Function to show toast notification
  const handleBreakClick = () => {
    toast.success("You can take a break now, your progress has been saved!");
    setIsBreak(true);
  };

  // Function to play help audio
  const handleHelpClick = () => {
    const audio = new Audio(helpAudio);
    audio.play();
  };

  // Function to handle closing the break timer
  const handleBreakClose = () => {
    setIsBreak(false);
  };

  // Function to handle play text to speech
  const handleSpeech = (subsection = section) => {
    const sectionAudioMap = {
      2: m2pg1,
      3: FirstThenAudio,
      4: WelcomeBackAudio,
      5: MissionthisWeekAudio,
      6: SchoolAudio,
      7: VisualAudio,
      9: BodyPartsAudio,
      10: QuickTaskAudio,
      10.1: QT10,
      11: Congratulations,
      12: VisualAudio1,
      13: BodyPartHurts,
      14: BodyPartHurtsPointing,
      15: BodyPartHurts,
      16: BodyMapIntro,
      17: BodyMap1,
      18: BodyPartHurts,
      20: TouchParts,
      20.1: QuizCompleted20,
      21: M2_21,
      22: AnnieAudio,
      23: AnnieHurtAudio,
      23.1: Quest1QuizCorrect,
      23.2: Quest1QuizInCorrect,
      24: Annie2,
      25: AnnieHurtAudio,
      25.1: Quest2QuizCorrect,
      25.2: Quest2QuizInCorrect,
      26: bonusPointAudio,
      27: Annie3,
      28: AnnieHurtAudio,
      28.1: Quest3QuizCorrect28,
      29: ThankAudio,
      30: VisualAudio3,
      31: PirateQuizAudio,
      32: yourTurnAudio,
      33: ThankyouAudio,
      34: BadgeAudio,
      35: VisualAudio4,
      36: Welldone,
      37: MissionThisWeekAudio,
      38: VisualAudio5,
      39: SeeYou,
    };

    const audioFile = sectionAudioMap[subsection];

    // Stop the currently playing audio
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    // Play the new audio file if it exists
    if (audioFile) {
      audioRef.current = new Audio(audioFile); // Assign new audio instance to ref
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [section]);

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6] animate-missionFade">
      <ToastContainer />
      {/* Top Bar */}
      <div className="flex flex-row items-center justify-between w-full">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 ml-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleHelpClick}
        >
          Help
        </button>
        <div className="flex-grow ml-0">
          <ProgressBar currentSection={section} totalSections={totalSections} />
        </div>
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 mr-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleBreakClick}
        >
          Break
        </button>
        <img
          src={speaker}
          alt="speaker"
          className={`hover:scale-110 ease-in-out transition duration-300 ${
            submitted ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (!submitted) {
              handleSpeech();
            }
          }}
        />
      </div>
      {/* Render ProgressBar component */}

      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {isBreak && <BreakTimer onClose={handleBreakClose} />}
          </div>
          <div className=" absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {/* Render content based on the current section */}
            {section === 1 && <Mission2Intro />}
            {section === 2 && <MissionPath1 />}
            {section === 3 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
            {section === 4 && (
              <WelcomeBack2 onNext={handleNext} carrier={carrier} />
            )}
            {section === 5 && (
              <MissionThisWeek2 onNext={handleNext} carrier={carrier} />
            )}
            {section === 6 && <School carrier={carrier} setIsOnChildComponent={setIsOnChildComponent}/>}
            {section === 7 && <MissionPath2 />}
            {section === 8 && <Ready onNext={handleNext} />}
            {section === 9 && <Recap />}
            {section === 10 && (
              <QuickTask
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
                handleSpeech={handleSpeech}
              />
            )}
            {section === 11 && <ExcellentJob />}
            {section === 12 && <MissionPath3 />}
            {section === 13 && <Section2 />}
            {section === 14 && <BodyPartSection />}
            {section === 15 && <BodyPartSectionDone />}
            {section === 16 && <BodyMapSectionIntro />}
            {section === 17 && <BodyMapSection />}
            {section === 18 && <BodyMapSectionDone />}
            {section === 19 && <TaskTime onNext={handleNext} />}
            {section === 20 && (
              <Section1
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
                handleSpeech={handleSpeech}
              />
            )}
            {section === 21 && <TopMission onNext={handleNext} />}
            {section === 22 && <Quest1 />}
            {section === 23 && (
              <Quest1Quiz
                handlePrevious={handlePrevious}
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
                handleSpeech={handleSpeech}
              />
            )}
            {section === 24 && <Quest2 />}
            {section === 25 && (
              <Quest2Quiz
                handlePrevious={handlePrevious}
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
                handleSpeech={handleSpeech}
              />
            )}
            {section === 26 && <BonusPoint />}
            {section === 27 && <Quest3 />}
            {section === 28 && (
              <Quest3Quiz
                handlePrevious={handlePrevious}
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
                handleSpeech={handleSpeech}
              />
            )}
            {section === 29 && <ThankYou carrier={carrier} />}
            {section === 30 && <MissionPath4 />}
            {section === 31 && (
              <PQIntro onNext={handleNext} carrier={carrier} />
            )}
            {section === 32 && (
              <Section4
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
              />
            )}
            {section === 33 && <Section5 />}
            {section === 34 && <Badge2 />}
            {section === 35 && <MissionPath5 />}
            {section === 36 && <Mission2Complete carrier={carrier} />}
            {section === 37 && <EndMission2 />}
            {section === 38 && <MissionPath6 />}
            {section === 39 && <SeeYou2 />}
            {section === 40 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-around">
        {/* "Previous" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            backgroundColor: "#3FA7D6",
            color: "#fffff",
          }}
        >
          <svg
            className="w-10 h-10 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        <div className="text-center text-xs text-white mt-6">
          <span style={{ fontSize: "1rem" }}>
            © 2024 | University of Galway, Ireland
          </span>
        </div>

        {/* "Next" button */}
        <button
          className={`px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200 ${
            isOnChildComponent ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isOnChildComponent}
          onClick={handleNext}
          style={{
            backgroundColor: "#3FA7D6",
          }}
        >
          <svg
            className="w-10 h-10"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Mission2;
