import React from "react";
import SeeYou from "../SeeYou";

const SeeYou7 = ({ carrier }) => {
  const rank = "Third Mate";
  return (
    <>
      <SeeYou rank={rank} carrier={carrier} />
    </>
  );
};

export default SeeYou7;
