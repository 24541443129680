import React, { useEffect } from "react";
import coins from "../../assets/audios/coins.mp3";
import coin from "../../assets/Icons/Coin.png";
import wongBakerPainScale from "../../assets/pain scale/Pain scale.png";

const Recap = () => {
  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };
  useEffect(() => {
    audioPlay();
  }, []);

  return (
    <div className="w-full flex justify-center -mt-20">
      <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
        {/* Title Section */}
        <div className="flex flex-row items-center justify-center gap-2">
          <h2 className="text-4xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
            Recap
            <img src={coin} className="w-8 ml-3 heartbeat" alt="coin" />
          </h2>
        </div>

        {/* Content Section */}
        <div className="flex flex-col items-center px-5 font-gillSans text-xl mt-5">
          {/* Left Side: Text */}
          <div className="text-gray-700 text-left w-full lg:w-2/3 text-center">
            <p>
              In the last mission you learned about using a pain scale to tell
              your mum/dad or teacher when you are in pain.
            </p>
            <p className="mt-5">
              You learned that a pain scale is a way to show how much something
              hurts.
            </p>
            <p className="mt-5">
              When you are hurting you can point to the faces that show how much
              pain you have.
            </p>
          </div>

          {/* Right Side: Images */}
          <div className="w-full lg:w-2/3 flex flex-col items-center mt-5">
            <img
              src={wongBakerPainScale}
              alt="Wong-Baker Pain Rating Scale"
              className="w-auto h-40"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recap;
