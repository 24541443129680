import React, { useState } from "react";
import meditate from "../../assets/specialSkills/Meditate.png";
import youcandoit from "../../assets/specialSkills/YCDI.png";
import okface from "../../assets/pain scale/0.png";
import CalmsYourBody from "./CalmsYourBody";
import DistractYourMind from "./DistractYourMind";
import YouCanDoIt from "./YouAreInControl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TummyBreathing4 = () => {
    const [currentChild, setCurrentChild] = useState(null); // Track the active child component
    const [completedSteps, setCompletedSteps] = useState([]); // Track completed steps

    // Handle navigation to child components
    const handleChildNavigation = (childName) => {
        setCurrentChild(childName); // Set the current child to display
    };

    // Handle returning from a child component
    const handleNext = (completedStep) => {
        setCompletedSteps((prev) => [...prev, completedStep]); // Mark the step as completed
        setCurrentChild(null); // Return to the parent component
        if (currentChild === "distracts-your-mind") {
            sessionStorage.setItem("tummyBreathingTaskCompleted", true);
        }
    };

    // Render the child component if one is selected
    if (currentChild) {
        switch (currentChild) {
            case "calm-your-body":
                return <CalmsYourBody onNext={() => handleNext("calm-your-body")} />;
            case "distracts-your-mind":
                return <DistractYourMind onNext={() => handleNext("distracts-your-mind")} />;
            case "you-can-do-it":
                return <YouCanDoIt onNext={() => handleNext("you-can-do-it")} />;
            default:
                return null;
        }
    }

    // Render the parent component (image grid)
    return (
        <div className="flex flex-col items-center text-center p-10">
            <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
                Task on Tummy Breathing
            </h2>
            <h3 className="text-2xl font-bold mb-4">Tummy Breathing is very important for 3 special reasons:</h3>
            <p className="text-xl mb-6">Click on the pictures to find out.</p>

            {/* Image Grid */}
            <div className="grid grid-cols-3 gap-8">
                <div
                    className={`flex flex-col items-center cursor-pointer ${
                        completedSteps.includes("calm-your-body") ? "opacity-50" : ""
                    }`}
                    onClick={() => handleChildNavigation("calm-your-body")}
                >
                    <img src={meditate} alt="Meditation" className="w-32 h-auto mb-2"/>
                    <span className="text-xl font-bold">Calms your Body</span>
                </div>
                <div
                    className={`flex flex-col items-center cursor-pointer ${
                        completedSteps.includes("distracts-your-mind") ? "opacity-50" : ""
                    }`}
                    onClick={() => handleChildNavigation("distracts-your-mind")}
                >
                    <img src={okface} alt="No Hurt" className="w-28 h-auto mb-2"/>
                    <span className="text-xl font-bold">Distracts your Mind</span>
                </div>
                <div
                    className={`flex flex-col items-center cursor-pointer ${
                        completedSteps.includes("you-can-do-it") ? "opacity-50" : ""
                    }`}
                    onClick={() => handleChildNavigation("you-can-do-it")}
                >
                    <img src={youcandoit} alt="You Can Do It" className="w-28 h-auto mb-2"/>
                    <span className="text-xl font-bold">You're in Control</span>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default TummyBreathing4;
