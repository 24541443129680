import React, { useRef } from "react";
import sailorAmy from "../../assets/Characters/SailorAmy.jpg";
import sailorAmyStory from "../../assets/audios/speech/Mission5/sailorAmyStory.mp3";

const SailorAmy = ({ onNext }) => {
  const audioRef = useRef(null);

  const handleStart = () => {
    // Play the audio when the button is clicked
    audioRef.current.play();
    onNext();
  };

  return (
    <div className="w-full max-w-4xl rounded-lg p-20 flex items-center space-x-6">
      <div className="flex-grow">
        <h2 className="text-4xl font-bold mb-2 text-left text-[#6BBF59] lg:text-4xl font-gillSans">
          Sailor Amy
        </h2>
        <p className="lg:text-xl text-left mb-4">
          Have a listen to Sailor Amy talk about how she uses a heat pack when
          she is in pain.
        </p>
        <button
          onClick={handleStart}
          className="px-6 py-2 rounded-md bg-green-600 text-white font-bold transition duration-200 hover:bg-green-500 border-2 border-transparent"
        >
          Play
        </button>
      </div>
      <div className="flex-shrink-0">
        <img
          src={sailorAmy}
          alt="Sailor Amy"
          className="w-64 mr-10 h-auto rounded-lg"
        />
      </div>
      <audio ref={audioRef} src={sailorAmyStory} />
    </div>
  );
};

export default SailorAmy;
