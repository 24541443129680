import React from "react";
import coinIcon from "../../assets/Icons/Coin.png"; 
import deepPressureImage from "../../assets/pain diary/Deep Pressure.png"; 

const DeepPressure = ({ onNext }) => {
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-6">
          Deep Pressure
          <img
            src={coinIcon}
            alt="coin"
            className="w-12 h-12 ml-4 heartbeat"
          />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full mt-8">
        {/* Left: Image */}
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={deepPressureImage}
            alt="Deep Pressure"
            className="w-full md:w-64 lg:w-72 h-auto"
          />
        </div>

        {/* Right: Text Content */}
        <div className="w-full md:w-1/2 text-lg md:text-xl text-left space-y-4 mr-5">
          <p>
            Deep pressure is a special skill that <br/> you can do when you have pain.
          </p>
          <p>It can help you feel better.</p>
          <button
            onClick={onNext}
            className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md"
          >
            Let’s find out more <br/>about deep pressure
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeepPressure;
