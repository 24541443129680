import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import face1 from "../../assets/audios/speech/PainDiary/Face1.mp3";
import face2 from "../../assets/audios/speech/PainDiary/Face2.mp3";
import face3 from "../../assets/audios/speech/PainDiary/Face3.mp3";
import face4 from "../../assets/audios/speech/PainDiary/Face4.mp3";
import face5 from "../../assets/audios/speech/PainDiary/Face5.mp3";
import face6 from "../../assets/audios/speech/PainDiary/Face6.mp3";
import painScale0 from "../../assets/pain scale/0.png";
import painScale10 from "../../assets/pain scale/10.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";
import Correct1 from "./Correct1";
import TryAgain from "./TryAgain";

const TaskAfterSailorStories = ({
  carrier,
  onTryAgain,
  setSubmitted,
  handleSpeech,
  setIsOnChildComponent,
  audioRef,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(1); // Track current question
  const responses = useRef({});
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const questionRef = useRef(null);
  const audioArray = [face1, face2, face3, face4, face5, face6];

  const userId = carrier?.userId;

  const placeholders = [
    { src: painScale0, label: "0\nNo hurt" },
    { src: painScale2, label: "2\nHurts little bit" },
    { src: painScale4, label: "4\nHurts little more" },
    { src: painScale6, label: "6\nHurts even more" },
    { src: painScale8, label: "8\nHurts whole lot" },
    { src: painScale10, label: "10\nHurts worst" },
  ];

  // Reordered questions and corresponding options
  const questions = [
    "How was Matey Morgan Feeling?",
    "What did Sailor Polly and Matey Morgan do to feel better?",
  ];

  const options = [
    [
      "0\nNo hurt",
      "2\nHurts little bit",
      "4\nHurts little more",
      "6\nHurts even more",
      "8\nHurts whole lot",
      "10\nHurts worst",
    ], // Options for the first question
    [
      "Watch TV ",
      "Tummy Breathing to make the pain go away",
      "Laugh with their friends.",
    ], // Options for the second question
  ];

  const correctAnswers = [
    [3, 4, 5], // Indices for correct answers in the first question (painScale6, painScale8, painScale10)
    1, // Index for the correct answer in the second question (Tummy Breathing)
  ];

  // When a child component is active, disable the forward arrow
  useEffect(() => {
    if (currentQuestion === 1) {
      handleSpeech(31.1);
    } else if (currentQuestion === 2) {
      handleSpeech(31.2);
    }

    setIsOnChildComponent(currentQuestion === 1);
  }, [currentQuestion, setIsOnChildComponent]);

  // Handle zoom-in effect when the current question changes
  useEffect(() => {
    const questionElement = questionRef.current;
    if (questionElement) {
      questionElement.classList.remove("zoom-in");
      void questionElement.offsetWidth;
      questionElement.classList.add("zoom-in");
    }
  }, [currentQuestion]);

  const handleOptionClick = async (index) => {
    setSelectedIndex(index);
    responses.current[`Q${currentQuestion}`] = options[1][index];

    if (index === correctAnswers[currentQuestion - 1]) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }

    // Check if the current question is the last one
    if (currentQuestion < questions.length) {
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedIndex(null);
      }, 1000);
    } else {
      // Finalize quiz
      setTimeout(async () => {
        checkAnswers();
        setIsQuizCompleted(true);
        await saveActivity();
      }, 1000);
    }
  };

  const handleImageClick = async (index) => {
    setSelectedIndex(index);
    responses.current[`Q${currentQuestion}`] = placeholders[index].label;

    const audio = new Audio(audioArray[index]);
    audio.play();

    if (correctAnswers[0].includes(index)) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }

    // Check if the current question is the last one
    if (currentQuestion < questions.length) {
      setTimeout(() => {
        setSelectedIndex(null);
        setCurrentQuestion(currentQuestion + 1);
      }, 1500);
    } else {
      // Finalize quiz
      setTimeout(async () => {
        checkAnswers();
        setIsQuizCompleted(true);
        await saveActivity();
      }, 500);
    }
    setSubmitted(true);
  };

  const checkAnswers = () => {
    const firstQuestionCorrect = correctAnswers[0].includes(
      placeholders.findIndex(
        (placeholder) => placeholder.label === responses.current["Q1"]
      )
    );
    const secondQuestionCorrect =
      responses.current["Q2"] === options[1][correctAnswers[1]];

    setIsCorrect(firstQuestionCorrect && secondQuestionCorrect);
  };

  const saveActivity = async () => {
    const payload = [
      {
        userId: carrier.userId,
        missionId: "M7",
        pageNum: 37,
        activityId: "M7TaskAfterSailorStories",
        activityValue: JSON.stringify(responses.current),
      },
    ];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      toast.success("Activity saved successfully!");
    } catch (error) {
      toast.error("Error saving activity!");
    }
  };

  if (isQuizCompleted) {
    if (isQuizCompleted && isCorrect) {
      sessionStorage.setItem("taskAfterSailorStories", true);
      handleSpeech(12.4);
      return <Correct1 carrier={carrier} audioRef={audioRef} />;
    } else if (isQuizCompleted && !isCorrect) {
      handleSpeech(12.5);
      return (
        <TryAgain
          onTryAgain={onTryAgain}
          setSubmitted={setSubmitted}
          setSelectedIndex={setSelectedIndex}
          setIsCorrect={setIsCorrect}
        />
      );
    }
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Task
        </h2>
      </div>

      <div className="mb-10">
        <style>
          {`
          @keyframes zoomIn {
            0% {
              transform: scale(0.5);
              opacity: 0;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }
          .zoom-in {
            animation: zoomIn 0.5s ease-out;
          }
          .hover-highlight:hover {
            background-color: rgba(107, 191, 89, 0.2);
          }
        `}
        </style>
        <div className="flex flex-col items-center justify-center"></div>
        <div
          className="mt-10 text-xl font-bold mb-5 text-center"
          ref={questionRef}
        >
          {currentQuestion}. {questions[currentQuestion - 1]}
        </div>

        {currentQuestion === 1 ? (
          <div className="flex justify-around items-center gap-5 w-full max-w-3xl mx-auto">
            {placeholders.map((placeholder, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={placeholder.src}
                  alt={`pain-scale-${index}`}
                  className={`transform transition duration-300 ease-in-out cursor-pointer ${
                    selectedIndex === index
                      ? "scale-110 border-4 rounded-full border-green-500"
                      : "hover:scale-110"
                  }`}
                  onClick={() => handleImageClick(index)}
                />
                <span className="mt-2 text-center font-gillSans whitespace-pre-line">
                  {placeholder.label}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center gap-2">
            {options[1].map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionClick(index)}
                className={`text-center w-full p-2 rounded-md text-lg transition-colors hover-highlight`}
              >
                {String.fromCharCode(97 + index)}. {option}
                {selectedIndex !== null && (
                  <img
                    src={index === correctAnswers[1] ? greenTick : redCross}
                    alt={index === correctAnswers[1] ? "Correct" : "Incorrect"}
                    className="w-6 h-6 inline-block ml-2"
                    style={{
                      visibility:
                        selectedIndex === index ? "visible" : "hidden",
                    }}
                  />
                )}
              </button>
            ))}
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default TaskAfterSailorStories;
