import React, { useEffect, useRef } from "react";
import tummyBreathing from "../../assets/videos/2. Tummy Breathing_F.mp4";

const TummyBreathing3 = () => {
    const audioRef = useRef(null);

    useEffect(() => {
        // Automatically play the audio when the component loads
        if (audioRef.current) {
            audioRef.current.play();
        }
    }, []);

    return (
        <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28 mx-auto">
            <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-8 lg:text-4xl font-gillSans">
                Tummy Breathing
            </h2>
            <div className="flex items-start space-x-6">
                {/* Story content */}
                <div className="flex-grow text-left text-gray-700 font-gillSans text-xl mt-1">
                    <p className="mt-6">
                        When you are in pain, sometimes your body gets sore, but tummy breathing helps your body feel
                        better. It's like a magic trick your body can do!
                    </p>
                    <p className="mt-6">
                        Click on the video to hear Annie talk about what tummy breathing looks like.
                    </p>
                </div>
            </div>
            <div className="flex items-center justify-center mt-4">
                <video
                    src={tummyBreathing}
                    controls
                    className="w-full max-w-md h-auto mb-4"
                    loop={false}
                ></video>
            </div>

            {/* Add audio */}
            {/* <audio ref={audioRef} src={sailorAmyStory} /> */}
        </div>
    );
};

export default TummyBreathing3;
