import React, { useEffect, useRef, useState } from "react";
import annie from "../../assets/Characters/Annie.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
const YourSpecialSkills = ({ onNext }) => {
  const audioRef = useRef(null);
  const [ready, setReady] = useState(false);

  const handleStart = () => {
    setReady(true);
    onNext();
  };
  useEffect(() => {
    const audio = new Audio(coins);    
    
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  useEffect(() => {    
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-10 lg:text-4xl font-gillSans flex items-center justify-center">
        Your Special Skills
        <img src={coin} className="w-14 h-12 ml-2 md:ml-3 heartbeat" alt="coin" />
      </h2>
      <div className="flex items-start space-x-6">
        {/* Sailor Amy image */}
        <img
          src={annie}
          alt="Annie"
          className="w-64 h-auto rounded-lg"        
        />

        {/* Story content */}
        <div className="text-left text-gray-700 font-gillSans text-xl">
          <p>
            Annie will teach you about 6 special skills you can do to help you feel better when you have pain.
          </p>
          <p>Are you ready to hear what these special skills are?</p>

          <div>
            <p className=" mt-10 mb-10 lg:text-2xl md:text-xl">Are you ready?</p>
            {ready ? (
                <p>Started!</p>
            ) : (
                <button
                    className="px-8 py-2 rounded-md bg-green-600 text-white text-xl font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-black"
                    onClick={handleStart}
                >
                  Yes, Let's Go
                </button>
            )}
          </div>

        </div>
      </div>

      {/* Audio */}
      {/*<audio ref={audioRef} src={sailorAmyStoryContinued}/>*/}
    </div>
  );
};

export default YourSpecialSkills;
