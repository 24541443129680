import React, { useEffect } from "react";
import youcandoit from "../../assets/specialSkills/YCDI.png";
import tummyBreathing from "../../assets/pain diary/tummyBreathing.png"; 
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import pg34 from "../../assets/audios/speech/Mission7/pg34.mp3";

const YouAreInControl = () => {
    useEffect(() => {
        const audio = new Audio(coins);
        audio.play();
        
        // Clean up the audio when the component unmounts
        return () => {
          audio.pause();
          audio.currentTime = 0;
        };
      }, []);
    useEffect(() => {
        const speech = new Audio(pg34);
        speech.play();

        return () => {
            speech.pause();
            speech.currentTime = 0;
        };
    }, []);
    return (
        <div className="flex flex-col md:flex-row items-center justify-center max-w-4xl mx-auto">
            {/* Left Side: "You Can Do It" Image */}
            <div className="md:w-1/2 flex justify-center mb-6 md:mb-0">
                <img src={youcandoit} alt="You Can Do It" className="w-5/6 h-auto" />
            </div>

            {/* Right Side: Text and Laying Down Image */}
            <div className="md:w-1/2 flex flex-col items-center md:items-start text-center md:text-left max-w-md">
                <h2 className="text-2xl font-bold mb-4 text-black flex items-center">You're in Control
                    <img src={coin} className="w-12 ml-4 heartbeat" alt="coin" />
                </h2>
                <p className="text-base md:text-lg mb-4 px-4 md:px-0">
                    Tummy Breathing helps you feel like you can <br/>cope with your pain, making it seem less scary.
                </p>
                <div className="flex justify-center md:justify-start mt-4">
                    <img src={tummyBreathing} alt="Laying Down" className="w-2/3 h-auto" />
                </div>
            </div>
        </div>
    );
};

export default YouAreInControl;
