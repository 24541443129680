import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import helpAudio from "../../assets/audios/INeedHelp.mp3";
import pg2 from "../../assets/audios/speech/Mission4/pg2.mp3";
import pg3 from "../../assets/audios/speech/Mission4/pg3.mp3";
import pg43 from "../../assets/audios/speech/Mission4/pg55.mp3";
import pg44 from "../../assets/audios/speech/Mission5/pg44.mp3";
import pg45 from "../../assets/audios/speech/Mission4/pg57.mp3";
import pg47 from "../../assets/audios/speech/Mission4/pg59.mp3";
import pg6 from "../../assets/audios/speech/Mission4/pg6.mp3";
import pg9 from "../../assets/audios/speech/Mission4/pg8.mp3";
import { default as pg10, default as pg28 } from "../../assets/audios/speech/Mission4/pg9.mp3";
import pg11 from "../../assets/audios/speech/Mission5/pg11.mp3";
import pg12Correct from "../../assets/audios/speech/Mission5/pg12Correct.mp3";
import pg12Incorrect from "../../assets/audios/speech/Mission5/pg12Incorrect.mp3";
import pg12Q1 from "../../assets/audios/speech/Mission5/pg12Q1.mp3";
import pg12Q2 from "../../assets/audios/speech/Mission5/pg12Q2.mp3";
import pg12Q3 from "../../assets/audios/speech/Mission5/pg12Q3.mp3";
import pg15 from "../../assets/audios/speech/Mission5/pg15.mp3";
import pg16 from "../../assets/audios/speech/Mission5/pg16.mp3";
import pg18 from "../../assets/audios/speech/Mission5/pg18.mp3";
import pg19 from "../../assets/audios/speech/Mission5/pg19.mp3";
import pg20 from "../../assets/audios/speech/Mission5/pg20.mp3";
import pg21 from "../../assets/audios/speech/Mission5/pg21.mp3";
import pg22 from "../../assets/audios/speech/Mission5/pg22.mp3";
import pg23 from "../../assets/audios/speech/Mission5/pg23.mp3";
import pg24 from "../../assets/audios/speech/Mission5/pg24.mp3";
import pg25 from "../../assets/audios/speech/Mission5/pg25.mp3";
import pg26 from "../../assets/audios/speech/Mission5/pg26.mp3";
import pg27 from "../../assets/audios/speech/Mission5/pg27.mp3";
import pg29 from "../../assets/audios/speech/Mission5/pg29.mp3";
import pg31Q1 from "../../assets/audios/speech/Mission5/pg31Q1.mp3";
import pg31Q2 from "../../assets/audios/speech/Mission5/pg31Q2.mp3";
import pg32 from "../../assets/audios/speech/Mission5/pg32.mp3";
import pg33 from "../../assets/audios/speech/Mission5/pg33.mp3";
import pg34 from "../../assets/audios/speech/Mission5/pg34.mp3";
import pg35 from "../../assets/audios/speech/Mission5/pg35.mp3";
import pg36 from "../../assets/audios/speech/Mission5/pg36.mp3";
import pg37 from "../../assets/audios/speech/Mission5/pg37.mp3";
import pg38 from "../../assets/audios/speech/Mission5/pg38.mp3";
import pg4 from "../../assets/audios/speech/Mission5/pg4.mp3";
import pg41 from "../../assets/audios/speech/Mission5/pg41.mp3";
import pg42 from "../../assets/audios/speech/Mission5/pg42.mp3";
import pg46 from "../../assets/audios/speech/Mission5/pg46.mp3";
import pg5 from "../../assets/audios/speech/Mission5/pg5.mp3";
import pg7 from "../../assets/audios/speech/Mission5/pg7.mp3";
import pg8 from "../../assets/audios/speech/Mission5/pg8.mp3";
import homeButton from "../../assets/homeBtn.png";
import sectionsBG from "../../assets/sectionsBG.png";
import speaker from "../../assets/speaker.png";
import BreakTimer from "../BreakTImer";
import FirstThenBoard from "../FirstThenBoard";
import EndMission5Continued from "../mission4/EndMission4Continued";
import ProgressBar from "../ProgressBar";
import Ready from "../Ready";
import School from "../School";
import Badge5 from "./Badge5";
import BonusPoints from "./BonusPoints";
import EndMission5 from "./EndMission5";
import ExtraCoin from "./ExtraCoin";
import FeelingQuiz from "./FeelingQuiz";
import Mission5Complete from "./Mission5Complete";
import Mission5Intro from "./Mission5Intro";
import MissionPath from "./MissionPath";
import MissionPath1 from "./MissionPath1";
import MissionPath2 from "./MissionPath2";
import MissionPath3 from "./MissionPath3";
import MissionPath4 from "./MissionPath4";
import MissionPath5 from "./MissionPath5";
import PainScale from "./PainScale";
import PainThisWeek from "./PainThisWeek";
import PiratePainScaleGuide from "./PiratePainScaleGuide";
import PirateQuiz from "./PirateQuiz";
import PirateQuizQuestions from "./PirateQuizQuestions";
import QuickTask from "./QuickTask";
import QuickTaskOnPain from "./QuickTaskOnPain";
import QuizTaskPage from "./QuizTaskPage";
import Recap from "./Recap";
import SailorAmy from "./SailorAmy";
import SailorAmyStory from "./SailorAmyStory";
import SailorAmyStoryContinued from "./SailorAmyStoryContinued";
import SeeYou5 from "./SeeYou5";
import TaskParent from "./TaskParent";
import TaskVideo from "./TaskVideo";
import TellingYourTeacher from "./TellingYourTeacher";
import WelcomeBack4 from "./WelcomeBack4";
import YourMissionThisWeek5 from "./YourMissionThisWeek5";
import YourPain from "./YourPain";
import YourPainScaleBodyMap from "./YourPainScaleBodyMap";
import YourPainScaleWB from "./YourPainScaleWB";
const Mission5 = () => {
  // Initialize the section state using useState
  const [section, setSection] = useState(1);
  const [isBreak, setIsBreak] = useState(false);
  const audioRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [isOnChildComponent, setIsOnChildComponent] = useState(false);

  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  ); // Initialize with session storage value
  // useNavigate init.
  const navigate = useNavigate();

  // Function to handle answer submission from Activity component
  const handleSubmit = (isSubmitted) => {
    setSubmitted(isSubmitted);
  };

  // Decrease the section value when "Previous" button is clicked
  const handlePrevious = () => {    
    setSubmitted(false);
    setSection(section - 1);
    if ([6, 19, 22, 32].includes(section)) {
      setIsOnChildComponent(false);
    }
  };

  // Determine if the "Next" button should be disabled
  const handleNext = () => {
    if (isOnChildComponent) {
      console.log("Please complete the current step by clicking Next.");
      return;
    }
  
    // Check for session storage requirements for specific sections
    const incompleteMessages = {
      7: "FeelingBetterQuizCompleted",
      8: "Pain5Completed",
      12: "QuickTaskOnPainCompleted",
      17: "SailorAmytaskCompleted",
      19: "PiratePainScaleGuideCompleted",
      22: "TaskParentCompleted",
      23: "M5YourPainScaleBodyMap",
      24: "YourPainScaleWBCompleted",
      27: "QuickTaskCompleted",
      32: "PirateQuizCompleted"
    };
    if([7, 8, 12, 17, 19, 22, 23, 24, 27, 32].includes(section)){
      console.log(section," : ", incompleteMessages[section], " : ", sessionStorage.getItem(incompleteMessages[section]));
    }
  
    if (incompleteMessages[section] && (!sessionStorage.getItem(incompleteMessages[section]) || sessionStorage.getItem(incompleteMessages[section]) === null)) {
      toast.error("Please complete the required task before proceeding.");      
      return;
    }    
  
    if (isLastPage) {
      navigate("/map", { state: { carrier } });
    } else {
      setSection(section + 1);
    }
    if ([7, 8, 12, 17, 19, 22, 23, 24, 27, 32].includes(section)) {
      setSubmitted(false); // Reset the submitted state
    }
  };

  const isPreviousDisabled = section === 1;
  const isLastPage = section === 39;

  const handleInputChange = (value) => {
    setInputValue(value);
    sessionStorage.setItem("firstThenInput", value); // Save to session storage
  };
  const totalSections = 39;
  const location = useLocation();
  const carrier = location.state?.carrier;

  // Function to handle home button click
  const handleHomeClick = async () => {
    navigate(-1);
  };

  // Function to show toast notification
  const handleBreakClick = () => {
    toast.success("You can take a break now, your progress has been saved!");
    setIsBreak(true);
  };

  useEffect(() => {
    console.log("Section: ", section);
    
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null; // Clean up the audio reference
      }
    };
  }, [section]);
  // Function to play help audio
  const handleHelpClick = () => {
    const audio = new Audio(helpAudio);
    audio.play();
  };

  const userId = carrier?.userId;
  const saveStarsCollected = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}saveStarsCollected`,
        {
          userId: userId,
          missionId: 5,
          starsCollected: 19,
          totalStars: 19,
        }
      );
      if (response.status === 200) {
        const { data } = response.data;
        // console.log("Success", data);
      }
    } catch (error) {
      console.error("Error saving stars:", error);
    }
  };

  if (section === 39) {
    saveStarsCollected();
  }
  // Function to handle closing the break timer
  const handleBreakClose = () => {
    setIsBreak(false);
  };

  const handleTryAgain = () => {
    setSection(section - 1);
  };

  const handleTryAgain2 = () => {
    setSection(section - 2);
  };
  // Function to handle play text to speech
  const handleSpeech = (subsection = section) => {
    const sectionAudioMap = {
      2: pg2,
      3: pg3,
      4: pg4,
      5: pg5,
      6: pg6,
      7: pg7,
      8: pg8,
      9: pg9,
      10: pg10,
      11: pg11,
      12.1: pg12Q1,
      12.2: pg12Q2,
      12.3: pg12Q3,
      12.4: pg12Correct,
      12.5: pg12Incorrect,
      13: pg15,
      14: pg16,
      16: pg18,
      17: pg19,
      17.1: pg20,
      17.2: pg21,
      18: pg22,
      19: pg23,
      20: pg28,
      21: pg29,
      23: pg31Q1,
      24: pg31Q2,
      25: pg32,
      26: pg33,
      27: pg34,
      28: pg35,
      29: pg36,
      30: pg37,
      31: pg38,
      33: pg41,
      34: pg42,
      35: pg43,
      36: pg44,
      37: pg45,
      38: pg46,
      39: pg47,
    };
    const audioFile = sectionAudioMap[subsection];
    if (audioFile) {
      // Pause the previous audio if it's playing
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      // Create a new audio instance and play it
      audioRef.current = new Audio(audioFile);
      audioRef.current.play();
    }
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6]">
      <ToastContainer />
      {/* Home Button */}
      <div className="flex flex-row items-center justify-between w-full">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 ml-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleHelpClick}
        >
          Help
        </button>
        <div className="flex-grow ml-0">
          <ProgressBar currentSection={section} totalSections={totalSections} />
        </div>
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 mr-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleBreakClick}
        >
          Break
        </button>
        <img
          src={speaker}
          alt="speaker"
          className={`hover:scale-110 ease-in-out transition duration-300 ${
            submitted ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (!submitted) {
              handleSpeech();
            }
          }}
        />
      </div>
      {/* Render ProgressBar component */}

      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative zoomer"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {isBreak && <BreakTimer onClose={handleBreakClose} />}
          </div>
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {/* Render content based on the current section */}
            {section === 1 && <Mission5Intro />}
            {section === 2 && <MissionPath />}
            {section === 3 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
            {section === 4 && (
              <WelcomeBack4 carrier={carrier} onNext={handleNext} />
            )}
            {section === 5 && (
              <YourMissionThisWeek5 onNext={handleNext} carrier={carrier} />
            )}
            {section === 6 && (
              <School
                carrier={carrier}                
                setIsOnChildComponent={setIsOnChildComponent}
              />
            )}{" "}
            {/* Lock */}
            {section === 7 && (
              <FeelingQuiz
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
              />
            )}{" "}
            {/* Lock */}
            {section === 8 && (
              <PainThisWeek
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
              />
            )}
            {section === 9 && <MissionPath1 />}
            {section === 10 && <Ready onNext={handleNext} />}
            {section === 11 && <Recap carrier={carrier} />}
            {section === 12 && (
              <QuickTaskOnPain
                carrier={carrier}
                onTryAgain={handleTryAgain2}
                handleSpeech={handleSpeech}
                submitted={submitted}
                setSubmitted={handleSubmit}
                setIsOnChildComponent={setIsOnChildComponent}
              />
            )}
            {section === 13 && <MissionPath2 />}
            {section === 14 && <SailorAmy onNext={handleNext} />}
            {section === 15 && <SailorAmyStory />}
            {section === 16 && <SailorAmyStoryContinued />}
            {section === 17 && (
              <QuizTaskPage
                carrier={carrier}
                onTryAgain={handleTryAgain2}
                handleSpeech={handleSpeech}
                submitted={submitted}
                setSubmitted={handleSubmit}
              />
            )}
            {section === 18 && <BonusPoints />}
            {section === 19 && (
              <PiratePainScaleGuide
                setIsOnChildComponent={setIsOnChildComponent}
                handleSpeech={handleSpeech}
                audioRef={audioRef}                
              />
            )}
            {section === 20 && <Ready onNext={handleNext} />}
            {section === 21 && <TaskVideo onNext={handleNext} />}
            {section === 22 && (
              <TaskParent
                carrier={carrier}
                onNext={handleNext}
                handleSpeech={handleSpeech}
                submitted={submitted}
                setSubmitted={handleSubmit}
                setIsOnChildComponent={setIsOnChildComponent}
              />
            )}
            {section === 23 && (
              <YourPainScaleBodyMap
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
              />
            )}
            {section === 24 && <YourPainScaleWB carrier={carrier} />}
            {section === 25 && <ExtraCoin />}
            {section === 26 && <YourPain onNext={handleNext} />}
            {section === 27 && (
              <QuickTask carrier={carrier} onNext={handleNext} />
            )}
            {section === 28 && <PainScale />}
            {section === 29 && <TellingYourTeacher />}
            {section === 30 && <MissionPath3 />}
            {section === 31 && (
              <PirateQuiz onNext={handleNext} carrier={carrier} />
            )}
            {section === 32 && (
              <PirateQuizQuestions
                setIsOnChildComponent={setIsOnChildComponent}
              />
            )}            
            {section === 33 && <MissionPath4 />}
            {section === 34 && <Mission5Complete carrier={carrier} />}
            {section === 35 && <EndMission5 />}
            {section === 36 && <EndMission5Continued />}
            {section === 37 && <MissionPath5 />}
            {section === 38 && <SeeYou5 carrier={carrier} />}
            {section === 39 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-around">
        {/* "Previous" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            backgroundColor: "#3FA7D6",
            color: "#fffff",
          }}
        >
          <svg
            className="w-10 h-10 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        <div className="text-center text-xs text-white mt-6">
          <span style={{ fontSize: "1rem" }}>
            © 2024 | University of Galway, Ireland
          </span>
        </div>

        {/* "Next" button */}
        <button
          className={`px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200 ${
            isOnChildComponent ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isOnChildComponent} // Disable the button when on child component
          onClick={handleNext}
          style={{
            backgroundColor: "#3FA7D6",
          }}
        >
          <svg
            className="w-10 h-10"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Mission5;