import React, { useEffect } from "react";
import captainCharlie from "../../assets/cptCharlie.png";
import coinIcon from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
const CopingWithPain = () => {
  useEffect(() => {
    const audio = new Audio(coins);    
    
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-4">Coping with Pain
        <img
          src={coinIcon}
          alt="coin"
          className="w-14 h-12 ml-4 heartbeat"
        />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full mt-8">
        {/* Left: Image */}
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={captainCharlie}
            alt="Pirate"
            className="w-full md:w-64 lg:w-72 h-auto"
          />
        </div>

        {/* Right: Text Content */}
        <div className="w-full md:w-1/2 text-lg md:text-xl text-left space-y-4 mr-5">
          <p>There are 6 special skills that you can do to help you feel better when you have pain.</p>
          <p>This is called <strong>coping</strong>.</p>
          <p>So, let's find out your next special skill.</p>
        </div>
      </div>
    </div>
  );
};

export default CopingWithPain;
