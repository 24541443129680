import axios from "axios";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import face1 from "../../../assets/audios/speech/PainDiary/Face1.mp3";
import face2 from "../../../assets/audios/speech/PainDiary/Face2.mp3";
import face3 from "../../../assets/audios/speech/PainDiary/Face3.mp3";
import face4 from "../../../assets/audios/speech/PainDiary/Face4.mp3";
import face5 from "../../../assets/audios/speech/PainDiary/Face5.mp3";
import face6 from "../../../assets/audios/speech/PainDiary/Face6.mp3";
import painScale0 from "../../../assets/pain scale/0.png";
import painScale10 from "../../../assets/pain scale/10.png";
import painScale2 from "../../../assets/pain scale/2.png";
import painScale4 from "../../../assets/pain scale/4.png";
import painScale6 from "../../../assets/pain scale/6.png";
import painScale8 from "../../../assets/pain scale/8.png";

const PainFace = () => {
  const [selectedIndex, setSelectedIndex] = useState(null); // State to keep track of selected image
  const audioArray = [face1, face2, face3, face4, face5, face6];
  const location = useLocation();
  const carrier = location.state?.carrier;
  const userId = carrier?.userId;

  const placeholders = [
    { src: painScale0, label: "0\nNo hurt" },
    { src: painScale2, label: "2\nHurts little bit" },
    { src: painScale4, label: "4\nHurts little more" },
    { src: painScale6, label: "6\nHurts even more" },
    { src: painScale8, label: "8\nHurts whole lot" },
    { src: painScale10, label: "10\nHurts worst" },
  ];

  const handleImageClick = async (index) => {
    setSelectedIndex(index); // Set the selected image index
    
    // Play the corresponding audio
    const audio = new Audio(audioArray[index]);
    audio.play();
    
    // Log the pain diary entry
    await logPainDiary(index);
  };

  const logPainDiary = async (index) => {
    const trimmedLabel = `wong face: ${placeholders[index].label.split("\n")[1]}`;
    const payload = {
      userId: userId,
      bodyPart: trimmedLabel, 
      diaryEntryTime: new Date().toISOString(),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}logPainDiary`,
        payload
      );
      // console.log("Pain diary entry logged successfully:", response.data);
      toast.success("Pain diary entry logged successfully!");
    } catch (error) {
      console.error("Error logging pain diary entry:", error);
      toast.error("Error logging pain diary entry!");
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        How are you feeling?
      </h2>
      <div className="text-gray-700 text-base text-center font-gillSans">
        <ol>
          <li className="mt-3 text-blue-800 text-xl">
            Select the face that shows how much it hurts today
          </li>
          <li className="mt-5 text-2xl font-bold">Wong-Baker FACES Pain Rating Scale</li>
          <div className="flex mt-10 justify-around items-center fadeIn gap-5 max-w-3xl">
            {placeholders.map((placeholder, index) => (
              <div className="flex items-center flex-col" key={index}>
                <img
                  src={placeholder.src}
                  alt={`pain-scale-${index}`}
                  className={`transform transition duration-300 ease-in-out cursor-pointer ${
                    selectedIndex === index
                      ? "scale-110 border-4 rounded-full border-green-500"
                      : "hover:scale-110"
                  }`}
                  onClick={() => handleImageClick(index)}
                />
                <span className="mt-10 text-xl text-center font-gillSans whitespace-pre-line">
                  {placeholder.label}
                </span>
              </div>
            ))}
          </div>
        </ol>
      </div>
      <ToastContainer /> 
    </div>
  );
};

export default PainFace;
