import React, { useState, useEffect } from "react";
import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const PirateQuiz = ({ handleSpeech, carrier }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);

  useEffect(() => {
    handleSpeech(41.1);
  }, []);

  const questions = [
    {
      id: 1,
      text: "What can you do to feel better when you have pain?",
      options: [
        { id: "a", text: "Run around" },
        { id: "b", text: "Use a heat pack on the body part that hurts" },
        { id: "c", text: "Deep pressure" },
      ],
      correctAnswers: ["b", "c"],
    },
    {
      id: 2,
      text: "When can you use a heat pack?",
      options: [
        { id: "a", text: "When you are hungry" },
        { id: "b", text: "When you have pain (body part hurts)" },
        { id: "c", text: "When you are playing outside" },
      ],
      correctAnswers: ["b"],
    },
  ];

  const currentQuestionData = questions.find(
    (question) => question.id === currentQuestion
  );

  const handleOptionClick = (option) => {
    if (submitted) return;
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    const correct =
      selectedOptions.length === currentQuestionData.correctAnswers.length &&
      currentQuestionData.correctAnswers.every((answer) =>
        selectedOptions.includes(answer)
      );
    setIsCorrect(correct);

    try {
      const activityValue = selectedOptions.join(", ");
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M8",
          pageNum: 49,
          activityId: "PirateQuiz",
          activityValue: "Question " + currentQuestionData.id + ": " + activityValue,
        },
      ];     

      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload,       
      );

      toast.success("Activity saved successfully!");
    } catch (error) {
      console.error("Error saving activity:", error);
      toast.error("Failed to save activity. Please try again.");
    }

    // Save quiz completion in session storage
    if (currentQuestion === 2) {
      sessionStorage.setItem("PirateQuizCompleted", true);
    }
  };

  const handleNext = () => {
    setCurrentQuestion((prev) => prev + 1);
    setSelectedOptions([]);
    setSubmitted(false);
    setIsCorrect(false);
    handleSpeech(41.2);
  };

  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn">
      <h2 className="text-2xl md:text-3xl font-bold mb-6 ml-10">Pirate Quiz</h2>
      <p className="mb-4 ml-10 text-xl">{currentQuestionData.text}</p>
      <p className="mb-4 ml-10 text-xl">
        {currentQuestionData.id == 1 ? "Hint: there are 2 correct answers" : ""}
      </p>
      <ul className="space-y-3 text-xl ml-10">
        {currentQuestionData.options.map((option) => (
          <li
            key={option.id}
            className={`flex items-center text-lg cursor-pointer ${
              selectedOptions.includes(option.id) ? "bg-yellow-200" : ""
            }`}
            onClick={() => handleOptionClick(option.id)}
          >
            <span className="mr-3">{`${option.id})`}</span>
            <span className="text-xl">{option.text}</span>
            {submitted && selectedOptions.includes(option.id) && (
              <img
                src={
                  currentQuestionData.correctAnswers.includes(option.id)
                    ? greenTick
                    : redCross
                }
                alt={
                  currentQuestionData.correctAnswers.includes(option.id)
                    ? "Correct"
                    : "Incorrect"
                }
                className="ml-4 w-6 h-6"
              />
            )}
            {submitted &&
              !selectedOptions.includes(option.id) &&
              currentQuestionData.correctAnswers.includes(option.id) && (
                <img src={greenTick} alt="Correct" className="ml-4 w-6 h-6" />
              )}
          </li>
        ))}
      </ul>

      {!submitted ? (
        <button
          onClick={handleSubmit}
          className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md ml-10 text-xl"
        >
          Submit
        </button>
      ) : isCorrect ? (
        currentQuestion < questions.length ? (
          <div className="mt-4">
            <p className="text-green-600 text-xl ml-10 font-bold">
              Correct! Great job!
            </p>
            <button
              onClick={handleNext}
              className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md ml-10 text-xl"
            >
              Next Question
            </button>
          </div>
        ) : (
          <div className="mt-4">
            <p className="text-green-600 text-xl ml-10 font-bold">
              Well done! You completed the quiz.
            </p>
          </div>
        )
      ) : (
        <div className="mt-4">
          <p className="text-red-600 text-xl ml-10 font-bold">
            Incorrect. Please try again!
          </p>
          <button
            onClick={() => setSubmitted(false)}
            className="mt-4 px-6 py-2 bg-red-500 text-white rounded-md ml-10 text-xl"
          >
            Try Again
          </button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default PirateQuiz;
