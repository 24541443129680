import React, { useState } from "react";
import closedTreasureBox from "../../assets/miscellaneous/treasurebox.png"; // Replace with the correct path
import openTreasureBox from "../../assets/miscellaneous/chest.png"; // Replace with the correct path

const StepsForTummyBreathing = () => {
    const [isTreasureBoxOpen, setIsTreasureBoxOpen] = useState(false);
    const [isSittingChecked, setIsSittingChecked] = useState(false);
    const [isParentChecked, setIsParentChecked] = useState(false);

    const handleTreasureClick = () => {
        setIsTreasureBoxOpen(true);
    };

    return (
        <div className="w-full max-w-[90%] md:max-w-[75%] mx-auto p-4 md:p-6">
            <h1 className="text-2xl text-[#6BBF59] md:text-3xl font-bold mb-4 text-center">
                Steps for Tummy Breathing
            </h1>
            <p className="text-base md:text-lg mb-1 text-center">
                Now it’s your turn! Captain Charlie has set you a task.
            </p>
            <p className="text-base md:text-lg mb-4 text-center">
                Click into the treasure chest to find out your task.
            </p>

            {/* Display the treasure box */}
            <div className="flex justify-center mb-4">
                <img
                    src={isTreasureBoxOpen ? openTreasureBox : closedTreasureBox}
                    alt="Treasure Box"
                    className="cursor-pointer w-32 md:w-40 h-auto transition-transform transform hover:scale-105"
                    onClick={handleTreasureClick}
                />
            </div>

            {/* Content below the treasure box */}
            {isTreasureBoxOpen && (
                <div className="mt-4 md:mt-6 text-center">
                    <p className="text-lg md:text-xl font-bold mb-4">
                        Your challenge is to practice Tummy Breathing.
                    </p>
                    <p className="text-base md:text-lg mb-2">
                        Before you start, it is important that you are:
                    </p>
                    <p className="text-sm md:text-base mb-4">(Tick the boxes)</p>

                    <div className="flex flex-col items-start justify-center mx-auto space-y-3 max-w-[80%] md:max-w-[70%]">
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                checked={isSittingChecked}
                                onChange={() => setIsSittingChecked(!isSittingChecked)}
                                className="w-5 h-5 md:w-6 md:h-6 text-green-600 border-gray-300 rounded focus:ring-green-500"
                            />
                            <span className="text-sm md:text-lg">1. Sitting down</span>
                        </label>
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                checked={isParentChecked}
                                onChange={() => setIsParentChecked(!isParentChecked)}
                                className="w-5 h-5 md:w-6 md:h-6 text-green-600 border-gray-300 rounded focus:ring-green-500"
                            />
                            <span className="text-sm md:text-lg">2. Have your mum/dad with you</span>
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StepsForTummyBreathing;
