import React, { useState } from "react";
import tummyBreathingImage from "../../assets/pain diary/tummyBreathing.png";
import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";
import CorrectBBQuiz from "./CorrectBBQuiz";
import IncorrectBBQuiz from "./IncorrectBBQuiz";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BellyBreathingQuiz = ({
  submitted,
  setSubmitted,
  setSection,
  carrier,
  audioRef,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);  
  const [isCorrect, setIsCorrect] = useState(null);

  const handleOptionClick = (option) => {
    if (!submitted) {
      setSelectedOption(option);
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    setIsCorrect(selectedOption === "b");    
    sessionStorage.setItem("BellyBreathingQuizCompleted", "true");    
  };

  const handleTryAgain = () => {
    setSubmitted(false);
    setSelectedOption(null);
    setIsCorrect(null);
    setSection(12);
  };

  if (submitted) {
    return isCorrect ? (
      <CorrectBBQuiz      
        carrier={carrier}
        audioRef={audioRef}
      />
    ) : (
      <IncorrectBBQuiz        
        onTryAgain={handleTryAgain}
      />
    );
  }

  const options = [
    { id: "a", text: "Helps you sing better." },
    { id: "b", text: "Helps your body relax and feel better." }, // Correct answer
    { id: "c", text: "Makes you run faster." },
  ];

  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn">
      {/* Title Section */}
      <div className="mb-6">
        <h2 className="text-2xl md:text-3xl font-bold text-[#6BBF59]">
          Quick Task on Belly Breathing
        </h2>
      </div>

      {/* Quiz Content */}
      <div className="flex flex-col md:flex-row items-center md:items-start justify-between">
        {/* Image */}
        <div className="w-full md:w-1/2 flex justify-center mb-4 md:mb-0">
          <img
            src={tummyBreathingImage}
            alt="Tummy Breathing"
            className="w-48 md:w-64 lg:w-72 h-auto"
          />
        </div>

        {/* Question Section */}
        <div className="w-full md:w-1/2 space-y-4">
          <p className="text-lg md:text-xl font-semibold">
            Why is Tummy Breathing important when you are in pain?
          </p>
          <ul className="space-y-3">
            {options.map((option) => (
              <li
                key={option.id}
                className={`flex items-center text-lg md:text-xl cursor-pointer ${
                  selectedOption === option.id && !submitted
                    ? "bg-yellow-200"
                    : ""
                }`}
                onClick={() => handleOptionClick(option.id)}
              >
                <span className="mr-3">{`(${option.id})`}</span>
                <span>{option.text}</span>
                {submitted &&
                  selectedOption === option.id &&
                  option.id === "b" && (
                    <img
                      src={greenTick}
                      alt="Correct"
                      className="ml-4 w-6 h-6"
                    />
                  )}
                {submitted &&
                  selectedOption === option.id &&
                  option.id !== "b" && (
                    <img
                      src={redCross}
                      alt="Incorrect"
                      className="ml-4 w-6 h-6"
                    />
                  )}
                {submitted && option.id === "b" && selectedOption !== "b" && (
                  <img
                    src={greenTick}
                    alt="Correct Answer"
                    className="ml-4 w-6 h-6"
                  />
                )}
              </li>
            ))}
          </ul>
          {!submitted && (
            <button
              onClick={handleSubmit}
              className="mt-4 px-6 py-2 bg-green-500 text-white text-xl rounded-md"
            >
              Submit
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BellyBreathingQuiz;
