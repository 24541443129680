import React, { useEffect, useRef, useState } from "react";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const TummyBreathing5 = ({ onNext }) => {
    
    const [ready, setReady] = useState(false);

    const handleStart = () => {
        setReady(true);
        onNext();
    };

    useEffect(() => {
       const audio = new Audio(coins);
       audio.play();
       return () => {
        audio.pause();
        audio.currentTime = 0;
       };
    }, []);

    return (
        <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28 mx-auto flex flex-col items-center">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-2 text-center font-gillSans mt-10 flex items-center">
                Tummy Breathing
                <img src={coin} className="w-12 ml-4 heartbeat" alt="coin" />
            </h2>
            <div className="flex flex-col items-center text-center text-gray-700 font-gillSans text-xl md:text-xl lg:text-xl mt-5">
                <p className="mt-6">
                    Tummy Breathing helps you cope with pain.
                </p>
                <p className="mt-6">
                    Some of Captain Charlie’s crew want to tell you about how they use Tummy Breathing to help when they are in pain.
                </p>
                <p className="mt-6">
                    Are you ready to listen to some of the crew?
                </p>
                {ready ? (
                    <p className="mt-6">Started!</p>
                ) : (
                    <button
                        className="lg:text-xl px-10 py-3 mt-14 text-xl rounded-md bg-green-500 text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-black"
                        onClick={handleStart}
                    >
                        Yes, Let's Go!
                    </button>
                )}
            </div>

            {/* Add audio */}
            {/* <audio ref={audioRef} src={sailorAmyStory} /> */}
        </div>
    );
};

export default TummyBreathing5;
