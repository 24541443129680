import React, { useEffect, useRef } from "react";
import cptCharlie from "../../assets/cptCharlie.png";
import coin from "../../assets/Icons/Coin.png";

const CopingWithPain = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    // Automatically play the audio when the component loads
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-10 lg:text-4xl font-gillSans flex items-center justify-center">
        Coping with Pain
        <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
      </h2>
      <div className="flex items-start space-x-6">
        <div className="flex-shrink-0 w-1/3">
          {/* Image of captain Charlie */}
          <img src={cptCharlie} alt="cptCharlie" className="w-12/12 mt-10"/>
        </div>

        {/* Story content */}
        <div className="flex-grow text-left text-gray-700 font-gillSans text-xl mt-5">

          <p className="mt-6" >
            There are 6 special skills that you can do to help you feel better when you have pain.
          </p>
          <p className="mt-6">
            This is called <strong>Coping</strong>.
          </p>
          <p className="mt-6">So, let's go on this adventure together and discover all these fun ways to cope with pain!
          </p>
        </div>
      </div>
      {/*add audio*/}
      {/*<audio ref={audioRef} src={sailorAmyStory} />*/}
    </div>
  );
};

export default CopingWithPain;
