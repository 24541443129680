import React, { useEffect } from "react";
import meditate from "../../assets/specialSkills/Meditate.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import pg32 from "../../assets/audios/speech/Mission7/pg32.mp3";

const CalmsYourBody = ({ onNext }) => {
    useEffect(() => {
        const audio = new Audio(coins);
        audio.play();
        
        // Clean up the audio when the component unmounts
        return () => {
          audio.pause();
          audio.currentTime = 0;
        };
      }, []);
    useEffect(() => {
        const speech = new Audio(pg32);
        speech.play();

        // Clean up the audio when the component unmounts
        return () => {
            speech.pause();
            speech.currentTime = 0;
          };
    }, []);
    return (
        <div className="w-full max-w-4xl mx-auto p-6 md:p-10 flex flex-col items-center">
            {/* Header Section */}
            <h2 className="text-3xl font-bold mb-6 text-center flex items-center">Calms your Body
                <img src={coin} className="w-12 ml-4 heartbeat" alt="coin" />
            </h2>

            {/* Content Section */}
            <div className="flex flex-col md:flex-row items-center justify-center w-full">
                {/* Left Side: Image */}
                <div className="flex justify-center mb-4 md:mb-0 md:mr-6">
                    <img src={meditate} alt="Meditation" className="w-32 md:w-40 lg:w-48 h-auto" />
                </div>

                {/* Right Side: Text Content */}
                <div className="text-center md:text-left text-gray-700 text-base md:text-lg lg:text-xl leading-relaxed max-w-md">
                    <p className="mb-4">
                        Tummy Breathing helps your body relax and feel better.
                    </p>
                    <p className="mb-4">
                        It's very important to try and relax when you have pain.
                    </p>
                    <p>
                        If we don't relax, the pain might hurt more and take longer to go away.
                    </p>
                </div>
            </div>

            {/* Next Button */}
            <div className="flex justify-center mt-8">
                <button
                    onClick={onNext}
                    className="px-8 py-2 text-lg rounded-md bg-green-500 text-white font-bold"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default CalmsYourBody;
