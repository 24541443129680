import React from "react";
import MissionComplete from "../MissionComplete";

const Mission7Complete = ({ carrier }) => { 
  const completedMissionId = 7;  
  const rank = "Boatswain";
  const coins = 28;
  const userId = carrier.userId;
  const currentMissionId = 8;
  const currentMissionPage = 48;

  return (
    <>
      <MissionComplete
        completedMissionId={carrier.completedMissionId > 7 ? carrier.completedMissionId : completedMissionId}
        rank={rank}
        userId={userId}
        coins={coins}
        currentMissionId={carrier.currentMissionId > 8 ? carrier.currentMissionId : currentMissionId}
        currentMissionPage={currentMissionPage}
      />
    </>
  );
};

export default Mission7Complete;
