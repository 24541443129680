import {React, useState} from "react";
import drReily from "../../assets/Characters/Riley.png"; // Doctor image
import painScale from "../../assets/pain scale/Pain scale.png"; // Pain scale image
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import face1 from "../../assets/audios/speech/PainDiary/Face1.mp3";
import face2 from "../../assets/audios/speech/PainDiary/Face2.mp3";
import face3 from "../../assets/audios/speech/PainDiary/Face3.mp3";
import face4 from "../../assets/audios/speech/PainDiary/Face4.mp3";
import face5 from "../../assets/audios/speech/PainDiary/Face5.mp3";
import face6 from "../../assets/audios/speech/PainDiary/Face6.mp3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const DoctorQuestionPage = ({ carrier, setSubmitted }) => {
  const [selectedIndex, setSelectedIndex] = useState(null); // State to keep track of selected image
  const audioArray = [face1, face2, face3, face4, face5, face6];

  const placeholders = [
    { src: painScale0, label: "0\nNo hurt" },
    { src: painScale2, label: "2\nHurts little bit" },
    { src: painScale4, label: "4\nHurts little more" },
    { src: painScale6, label: "6\nHurts even more" },
    { src: painScale8, label: "8\nHurts whole lot" },
    { src: painScale10, label: "10\nHurts worst" },
  ];

  const handleImageClick = async (index) => {
    setSelectedIndex(index); 
    sessionStorage.setItem("DoctorQuestionPageCompleted", "true");
    
    // Play the corresponding audio
    const audio = new Audio(audioArray[index]);
    audio.play();
    
    // Save the activity with the selected face's value    
    await saveActivity(index);
  };

  const saveActivity = async (index) => {
    const trimmedLabel = `wong face: ${
      placeholders[index].label.split("\n")[1]
    }`;
    const payload = [
      {
        userId: carrier.userId,
        missionId: "M6",
        pageNum: 7,
        activityId: "M6RecapTask",
        activityValue: trimmedLabel, 
      },
    ];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      console.log("Response:", response);
      toast.success("Activity saved successfully!");
    } catch (error) {
      console.error("Error saving mission activity:", error);
      toast.error("Error saving activity!");
    }
  };
  return (
    <div className="flex flex-col items-center p-8 max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">
        Doctor Riley has a Question for you
      </h2>

      <div className="flex flex-col lg:flex-row items-start gap-8 mt-10">
        {/* Doctor Image */}
        <div className="flex justify-center lg:w-1/3">
          <img
            src={drReily}
            alt="Dr. Riley"
            className="w-full max-w-xs rounded-lg shadow-md"
          />
        </div>

        {/* Right Section - Text and Pain Scale */}
        <div className="lg:w-2/3 text-center lg:text-left">
          <p className="text-xl mb-4">Hi there {carrier.firstName}</p>

          <h3 className="text-2xl font-bold mb-2">How are you feeling?</h3>
          <p className="text-xl mb-4">
            Point to the face to tell Dr. Riley how you are feeling.
          </p>

          {/* Pain Scale Image */}
          <div className="flex justify-center lg:justify-start">
            <div className="flex justify-around items-center gap-3 w-full max-w-3xl mr-5">
              {placeholders.map((placeholder, index) => (
                <div className="flex items-center flex-col" key={index}>
                  <img
                    src={placeholder.src}
                    alt={`pain-scale-${index}`}
                    className={`transform transition duration-300 ease-in-out cursor-pointer ${
                      selectedIndex === index
                        ? "scale-110 border-4 rounded-full border-green-500"
                        : "hover:scale-110"
                    }`}
                    onClick={() => handleImageClick(index)}
                  />
                  <span className="mt-2 text-center font-gillSans whitespace-pre-line">
                    {placeholder.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default DoctorQuestionPage;
