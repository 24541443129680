import React, { useEffect } from "react";
import annieImage from "../../assets/Characters/Annie.png";
import coinIcon from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
const YourSpecialSkills = ({ onNext }) => {
  useEffect(() => {
    const audio = new Audio(coins);    
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-5">Your Special Skills
        <img src={coinIcon} alt="coin" className="w-14 h-12 ml-2 md:ml-3 heartbeat" />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full mt-8">
        {/* Left: Image */}
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={annieImage}
            alt="Annie"
            className="w-full md:w-64 lg:w-72 h-auto"
          />
        </div>

        {/* Right: Text Content */}
        <div className="w-full md:w-1/2 text-lg md:text-xl text-left space-y-4 mr-10">
          <p>
            In the last mission Annie showed you how to do Tummy Breathing to
            help you feel better when you have pain.
          </p>
          <p>
            Annie will teach you <span className="font-bold">6 special skills</span> you can do to cope with your pain
            and help you feel better.
          </p>
          <p>Let’s remember what they are?</p>
          <button
            onClick={onNext}
            className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md"
          >
            Yes, Let's Go
          </button>
        </div>
      </div>
    </div>
  );
};

export default YourSpecialSkills;
