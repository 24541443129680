import React, { useEffect, useRef } from "react";
import copingChampion from "../../assets/Badges/Coping Champion.png";
import pg40 from "../../assets/audios/speech/Mission5/pg40.mp3";

const Badge8 = () => {
  const audioRef = useRef(new Audio(pg40));

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play();
    }

    // Cleanup function to pause and reset audio when the component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; 
      }
    };
  }, [audioRef]);
  return (
    <div className="flex justify-center items-center p-10 rounded-lg max-w-4xl mx-auto fadeIn">
      {/* Left side - Text */}
      <div className="w-1/2 text-left pr-15 ml-3">
        <h2 className="text-2xl font-bold mb-4 text-left text-[#6BBF59] lg:text-3xl font-gillSans">
          Your Special Badge
        </h2>
        <p className="text-gray-700 text-xl font-gillSans mb-4">
          Congratulations, Captain Charlie has awarded you a special badge
          called <strong>‘Coping Champion’!</strong>
        </p>
        <p className="text-gray-700 text-xl font-gillSans">
          This badge shows you have done something great. You have learnt how to
          use a heat pack and deep pressure when you have pain.
        </p>
        <p className="text-gray-700 text-xl font-gillSans">
          Every time you look at your badge, you can remember when you have
          pain, you can use a heat pack or deep pressure to help make the
          pain less sore and feel better.
        </p>
      </div>
      {/* Right side - Image */}
      <div className="w-1/2 flex justify-center">
        <img
          src={copingChampion}
          alt="Coping Champion Badge"
          className="w-72 h-auto"
        />
      </div>
    </div>
  );
};

export default Badge8;
