import React, { useEffect } from "react";
import sailorAmyImage from "../../assets/Characters/SailorAmy.jpg";
import yellowFaceImage from "../../assets/pain scale/4.png";
import coinIcon from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const SailorAmyHeatPack = () => {
    useEffect(() => {
        const audio = new Audio(coins);    
        
        return () => {
          audio.pause();
          audio.currentTime = 0;
        };
      }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-4 mt-10">
          Sailor Amy
          <img src={coinIcon} alt="coin" className="w-14 h-12 ml-4 heartbeat" />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full mt-8">
        {/* Left: Sailor Amy Image */}
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={sailorAmyImage}
            alt="Sailor Amy"
            className="w-2/3 md:w-2/3 h-auto -mt-10"
          />
        </div>

        {/* Right: Text Content */}
        <div className="w-full md:w-1/2 text-lg md:text-xl text-left space-y-2 mr-16 -mt-20">
          <p>
            Hi, Remember me! I’m Sailor Amy! I am one of Captain Charlie’s
            Friends. Captain Charlie has set me a task to tell you about{" "}
            <span className="font-bold text-[#6BBF59]">a heat pack when you have pain</span>.
          </p>
          <p>
            <span className="font-bold text-[#6BBF59]">My leg hurts me a lot.</span> Most days I have pain in my legs. It can be{" "}
            <span className="font-semibold italic">really sore</span> and makes me sad sometimes. Most days I feel like
            the yellow face.
          </p>
          <div className="flex items-center">
            <img
              src={yellowFaceImage}
              alt="Yellow Face"
              className="w-12 h-12 ml-2"
            />
            <span className="ml-4">Hurts little more</span>
          </div>
          <p>
            <span className="font-bold text-[#6BBF59]">
              I like to use a heat pack to help with the pain in my legs.
            </span>{" "}
            My dad gives me a heat pack and I <span className="font-bold text-[#6BBF59]">put it on my legs</span>. I like to lie on the couch
            with my heat pack. After some time, my leg doesn’t hurt as much.{" "}
            <span className="font-bold text-[#6BBF59]">The heat pack makes my legs feel nice.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SailorAmyHeatPack;
