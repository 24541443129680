import React, { useState } from "react";
import Bodymap from "./Bodymap"; // Q1
import YourTurn from "./YourTurn"; // Q3
import Q2Component from "./Q2Component"; 
import CorrectComponent from "./Correct1"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TaskParent = ({ carrier, onNext, setSubmitted, handleSpeech, submitted, setIsOnChildComponent }) => {
  const [currentStep, setCurrentStep] = useState(1); // Track which question step user is on
  const [correctSteps, setCorrectSteps] = useState([]);

  // Handle going to the next step after a correct answer
  const handleNextStep = () => {
    setCorrectSteps([...correctSteps, currentStep]);
    console.log("currentStep", currentStep);

    // Set the isOnChildComponent state based on the current step
    if (currentStep === 1) {
      setIsOnChildComponent(true);
    } else {  
      setIsOnChildComponent(false);
    }    

    // Introduce a 2-second delay before moving to the next step
    setTimeout(() => {
      setCurrentStep(currentStep + 1); // Go to the next step after 2 seconds
    }, 1500); // 2000 milliseconds = 2 seconds

  };
  

  return (
    <div className="flex flex-col items-center p-10 text-center">
      {/* Show the current question based on the step */}
      {currentStep === 1 && (
        <Bodymap
          carrier={carrier}
          onCorrect={handleNextStep}
          handleSpeech={handleSpeech}
          submitted={submitted}
          setSubmitted={setSubmitted}        
        />
      )}
      {currentStep === 2 && (
        <Q2Component
          carrier={carrier}
          onCorrect={handleNextStep}
          handleSpeech={handleSpeech}
          submitted={submitted}
          setSubmitted={setSubmitted}
        />
      )}
      {currentStep === 3 && (
        <YourTurn
          carrier={carrier}
          onCorrect={onNext} // Assuming onNext navigates to the next section after Q3
          handleSpeech={handleSpeech}
          submitted={submitted}
          setSubmitted={setSubmitted}
        />
      )}

      {/* Optionally, show something after all questions are completed */}
      {currentStep > 3 && <CorrectComponent />}
      <ToastContainer />
    </div>
  );
};

export default TaskParent;
