import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import face1 from "../../assets/audios/speech/PainDiary/Face1.mp3";
import face2 from "../../assets/audios/speech/PainDiary/Face2.mp3";
import face3 from "../../assets/audios/speech/PainDiary/Face3.mp3";
import face4 from "../../assets/audios/speech/PainDiary/Face4.mp3";
import face5 from "../../assets/audios/speech/PainDiary/Face5.mp3";
import face6 from "../../assets/audios/speech/PainDiary/Face6.mp3";
import painScale0 from "../../assets/pain scale/0.png";
import painScale10 from "../../assets/pain scale/10.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import Correct1 from "./Correct1";
import TryAgain from "./TryAgain";

const QuickTaskOnPain = ({
  carrier,
  onTryAgain,
  setSubmitted,
  handleSpeech,
  setIsOnChildComponent,
  audioRef
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(1); // Track current question
  const responses = useRef({});
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const questionRef = useRef(null);
  const audioArray = [face1, face2, face3, face4, face5, face6];
  

  const placeholders = [
    { src: painScale0, label: "0\nNo hurt" },
    { src: painScale2, label: "2\nHurts little bit" },
    { src: painScale4, label: "4\nHurts little more" },
    { src: painScale6, label: "6\nHurts even more" },
    { src: painScale8, label: "8\nHurts whole lot" },
    { src: painScale10, label: "10\nHurts worst" },
  ];

  const questions = [
    "Can you remember which face shows no pain?",    
    "Can you remember which face shows the most pain?",
  ];

  // When a child component is active, disable the forward arrow
  useEffect(() => {
    console.log("Current Question: ", currentQuestion);
    console.log("isQuizCompleted: ", isQuizCompleted);
    console.log("isCorrect: ", isCorrect);

    if (currentQuestion === 1) {
      handleSpeech(12.1);
    } else if (currentQuestion === 2) {
      handleSpeech(12.2);
    }

    if (currentQuestion === 1) {
      setIsOnChildComponent(true);
    } else if (currentQuestion === 2) {
      setIsOnChildComponent(false);
    }
  }, [currentQuestion, setIsOnChildComponent]);

  // Handle zoom-in effect when the current question changes
  useEffect(() => {
    const questionElement = questionRef.current;
    if (questionElement) {
      questionElement.classList.remove("zoom-in");
      // Force reflow to restart the animation
      void questionElement.offsetWidth;
      questionElement.classList.add("zoom-in");
    }
  }, [currentQuestion]);

  const handleImageClick = async (index) => {
    setSelectedIndex(index); // Set the selected image index
    console.log(
      "Clicked Face:",
      index + 1,
      "Current Question:",
      currentQuestion
    );

    // Store response in the ref directly
    responses.current[`Q${currentQuestion}`] = placeholders[index].label;

    const audio = new Audio(audioArray[index]);
    audio.play();

    // Move to the next question or save activity if done
    if (currentQuestion < questions.length) {
      setTimeout(() => {
        setSelectedIndex(null); // Reset selection
        setCurrentQuestion(currentQuestion + 1); // Move to next question
      }, 1500);
    } else {
      // Ensure the last response is captured before saving
      console.log("Saving response after question ", currentQuestion);
      setTimeout(async () => {
        checkAnswers(); // Check if answers are correct
        setIsQuizCompleted(true); // Mark quiz as completed
        console.log("Quiz completed: ", isQuizCompleted);       
        await saveActivity(); // Save responses after the last question
      }, 500);
    }
    setSubmitted(true);
  };
  // Function to check the correctness of responses
  const checkAnswers = () => {
    let correct = true;

    // Loop over each question and check the response
    Object.keys(responses.current).forEach((question, idx) => {
      const response = responses.current[question];

      // For Question 1, check if the response matches the label for "0\nNo hurt"
      if (
        idx === 0 &&
        !response.includes(placeholders[0].label.split("\n")[1])
      ) {
        correct = false;
      }      

      // For Question 3, check if the response matches the label for "10\nHurts worst"
      if (
        idx === 1 &&
        !response.includes(placeholders[5].label.split("\n")[1])
      ) {
        correct = false;
      }
    });

    setIsCorrect(correct); // Set the result based on whether all conditions are satisfied
  };

  const saveActivity = async () => {
    // Log and use the responses from the ref
    console.log("Final responses: ", responses.current);

    const payload = [
      {
        userId: carrier.userId,
        missionId: "M6",
        pageNum: 12,
        activityId: "M6QuickTaskOnPain",
        activityValue: JSON.stringify(responses.current), // Add the responses as the activity value
      },
    ];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      console.log("Response:", response);
      toast.success("Activity saved successfully!");
    } catch (error) {
      console.error("Error saving mission activity:", error);
      toast.error("Error saving activity!");
    }
  };

  if (isQuizCompleted) {
    if (isQuizCompleted && isCorrect) {
      sessionStorage.setItem("quickTaskOnPainCompleted", true);
      handleSpeech(12.4);
    } else if (isQuizCompleted && !isCorrect) {
      handleSpeech(12.5);
    }
    return isCorrect ? (
      <Correct1 carrier={carrier} audioRef={audioRef}/>
    ) : (
      <TryAgain
        onTryAgain={onTryAgain}
        setSubmitted={setSubmitted}
        setSelectedIndex={setSelectedIndex}
        setIsCorrect={setIsCorrect}
        audioRef={audioRef}
      />
    ); // Show the correct/incorrect screen
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Quick Task on Pain
        </h2>
      </div>

      <div className="mb-10">
        <style>
          {`
          @keyframes zoomIn {
            0% {
              transform: scale(0.5);
              opacity: 0;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }
          .zoom-in {
            animation: zoomIn 0.5s ease-out;
          }
        `}
        </style>
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl text-center  md:text-base lg:text-xl font-gillSans flex items-center justify-center">
            Captain Charlie wants to test your knowledge on the pain scale.            
          </p>
        </div>
        <div
          className="mt-10 text-xl font-bold mb-5 text-center"
          ref={questionRef}
        >
          {currentQuestion}. {questions[currentQuestion - 1]}
        </div>
        <div className="flex justify-around items-center gap-5 w-full max-w-3xl mx-auto">
          {placeholders.map((placeholder, index) => (
            <div className="flex items-center flex-col" key={index}>
              <img
                src={placeholder.src}
                alt={`pain-scale-${index}`}
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === index
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(index)}
              />
              <span className="mt-2 text-center font-gillSans whitespace-pre-line">
                {placeholder.label}
              </span>
            </div>
          ))}
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default QuickTaskOnPain;
