import React from "react";
import MissionComplete from "../MissionComplete";

const Mission7Complete = ({ carrier }) => {
  const completedMissionId = 8;
  const rank = "Third Mate";
  const coins = 28;
  const userId = carrier.userId;
  const currentMissionId = 9;
  const currentMissionPage = 52;
 
  return (
    <>
      <MissionComplete
        completedMissionId={carrier.completedMissionId > 8 ? carrier.completedMissionId : completedMissionId}
        rank={rank}
        userId={userId}
        coins={coins}
        currentMissionId={carrier.currentMissionId > 9 ? carrier.currentMissionId : currentMissionId}
        currentMissionPage={currentMissionPage}      
      />
    </>
  );
};

export default Mission7Complete;
