import React from "react";
import tummyBreathing from "../../assets/pain diary/Tummy Breathing.png";
import heatPack from "../../assets/pain diary/Heat Pack 2.png";
import walkImg from "../../assets/pain diary/Walk.png";
import listenToMusic from "../../assets/pain diary/Listen to Music.png";
import deepPressure from "../../assets/pain diary/Deep Pressure.png";
import magicMedicine from "../../assets/pain diary/Medication.png";
import walkAudio from "../../assets/audios/speech/Mission7/Walk.mp3";
import tummyBreathingAudio from "../../assets/audios/speech/Mission7/TummyBreathing.mp3";
import heatPackAudio from "../../assets/audios/speech/Mission7/HeatPack.mp3";
import magicMedicineAudio from "../../assets/audios/speech/Mission7/MagicMedicine.mp3";
import listenToMusicAudio from "../../assets/audios/speech/Mission7/ListenToMusic.mp3";
import deepPressureAudio from "../../assets/audios/speech/Mission7/DeepPressure.mp3";

const YourSpecialSkillsDetails = () => {
    // Mapping images and sounds
    const skills = [
        { img: tummyBreathing, audio: tummyBreathingAudio, label: "Tummy Breathing" },
        { img: heatPack, audio: heatPackAudio, label: "Heat Pack" },
        { img: walkImg, audio: walkAudio, label: "Walk" },
        { img: listenToMusic, audio: listenToMusicAudio, label: "Listen to Music" },
        { img: deepPressure, audio: deepPressureAudio, label: "Deep Pressure" },
        { img: magicMedicine, audio: magicMedicineAudio, label: "Magic Medicine" },
    ];

    // Function to play audio
    const playAudio = (audioSrc) => {
        const audio = new Audio(audioSrc);
        audio.play();
    };

    return (
        <div className="w-full max-w-4xl mx-auto p-6 bg-transparent md:p-8 lg:p-10">
            {/* Header Section */}
            <div className="text-center mb-6 px-4 md:px-6 lg:mt-4">
                <h1 className="text-2xl md:text-3xl font-bold text-[#6BBF59] font-gillSans mb-4">
                    Your Special Skills
                </h1>
                <p className="text-sm md:text-base lg:text-lg mb-2">
                    These are your 6 special skills to help you feel better when you have pain. You will learn about these skills over the next 3 missions.
                </p>
                <p className="text-sm md:text-base lg:text-lg">
                    Click on each picture to hear about the special skill you will learn.
                </p>
            </div>

            {/* Skills Grid */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-6 place-items-center">
                {skills.map((skill, index) => (
                    <div
                        key={index}
                        className="text-center p-2"
                        onClick={() => playAudio(skill.audio)}
                    >
                        <img
                            src={skill.img}
                            alt={skill.label}
                            className="w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-md object-cover cursor-pointer hover:scale-105 transition-transform duration-200"
                        />
                        <p className="mt-2 text-xs md:text-sm font-semibold">
                            {skill.label}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default YourSpecialSkillsDetails;
