import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import sailorAmy from "../../assets/Characters/SailorAmy.jpg";
import face1 from "../../assets/audios/speech/PainDiary/Face1.mp3";
import face2 from "../../assets/audios/speech/PainDiary/Face2.mp3";
import face3 from "../../assets/audios/speech/PainDiary/Face3.mp3";
import face4 from "../../assets/audios/speech/PainDiary/Face4.mp3";
import face5 from "../../assets/audios/speech/PainDiary/Face5.mp3";
import face6 from "../../assets/audios/speech/PainDiary/Face6.mp3";
import CorrectHPQuiz from "./CorrectHPQuiz";
import IncorrectAmyQuiz from "./IncorrectAmyQuiz";

const TaskComponent = ({ carrier, setSection, audioRef, handleSpeech }) => {
  useEffect(() => {
    handleSpeech(30.1);
  }, []);
  const [selectedFaceIndex, setSelectedFaceIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const faceAudios = [face1, face2, face3, face4, face5, face6];
  const placeholders = [
    { src: painScale0, label: "No hurt" },
    { src: painScale2, label: "Hurts little bit" },
    { src: painScale4, label: "Hurts little more" },
    { src: painScale6, label: "Hurts even more" },
    { src: painScale8, label: "Hurts whole lot" },
    { src: painScale10, label: "Hurts worst" },
  ];

  const question2Options = [
    { id: "a", text: "Watch TV" },
    { id: "b", text: "Use a heat pack on her legs", isCorrect: true },
    { id: "c", text: "Laugh with their friends" },
  ];

  const handleFaceClick = (index) => {
    setSelectedFaceIndex(index);
    const audio = new Audio(faceAudios[index]);
    audio.play();
    setTimeout(() => {
      handleSpeech(30.2);
    }, 1500);
  };

  const handleOptionClick = (id) => {
    setSelectedOption(id);
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    if (selectedFaceIndex >= 2 && selectedOption === "b") {
      sessionStorage.setItem("TaskComponentCompleted", "true");
      await saveActivity();
    }
  };

  const saveActivity = async () => {
    try {
      const payload = [
        {
          userId: carrier.userId,
          missionId: "M8",
          pageNum: 34,
          activityId: "M8SailorAmyTask",
          activityValue: "Both questions answered correctly",
        },
      ];
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      toast.success("Activity saved successfully!");
    } catch (error) {
      console.error("Error saving activity:", error);
      toast.error("Error saving activity!");
    }
  };

  const handleTryAgain = () => {
    setSelectedFaceIndex(null);
    setSelectedOption(null);
    setIsSubmitted(false);
    setSection(28);
  };

  if (isSubmitted) {
    if (selectedFaceIndex >= 2 && selectedOption === "b") {
      return <CorrectHPQuiz carrier={carrier} audioRef={audioRef} />;
    } else {
      return <IncorrectAmyQuiz onTryAgain={handleTryAgain} />;
    }
  }

  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center">
      <h2 className="text-3xl md:text-4xl font-bold mb-6">Task</h2>
      <div className="flex flex-col md:flex-row items-center justify-between w-full">
        <div className="w-full md:w-1/2 flex justify-center">
          <img src={sailorAmy} alt="Captain Charlie" className="w-64 h-auto" />
        </div>
        <div className="w-full md:w-1/2 text-left text-lg md:text-xl space-y-4 mr-20">
          <p>1. How was Sailor Amy Feeling?</p>
          <div className="flex justify-around items-center gap-3">
            {placeholders.map((placeholder, index) => (
              <img
                key={index}
                src={placeholder.src}
                alt={`face-${index}`}
                className={`cursor-pointer ${
                  selectedFaceIndex === index
                    ? "scale-110 border-4 border-green-500"
                    : "hover:scale-110"
                }`}
                style={{ width: "50px", height: "50px" }}
                onClick={() => handleFaceClick(index)}
              />
            ))}
          </div>

          <p className="mt-6">2. What did Sailor Amy do to feel better?</p>
          <ul className="space-y-2">
            {question2Options.map((option) => (
              <li
                key={option.id}
                className={`cursor-pointer ${
                  selectedOption === option.id ? "bg-yellow-200" : ""
                }`}
                onClick={() => handleOptionClick(option.id)}
              >
                {`(${option.id}) ${option.text}`}
              </li>
            ))}
          </ul>

          <button
            onClick={handleSubmit}
            className="mt-6 px-6 py-2 bg-green-500 text-white rounded-md"
            disabled={selectedFaceIndex === null || selectedOption === null}
          >
            Submit
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TaskComponent;
