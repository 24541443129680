import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import helpAudio from "../../assets/audios/INeedHelp.mp3";
import pg3 from "../../assets/audios/speech/Mission4/pg3.mp3";
import pg6 from "../../assets/audios/speech/Mission4/pg6.mp3";
import { default as pg10 } from "../../assets/audios/speech/Mission4/pg9.mp3";
import pg7 from "../../assets/audios/speech/Mission5/pg7.mp3";
import pg8 from "../../assets/audios/speech/Mission5/pg8.mp3";
import pg2 from "../../assets/audios/speech/Mission6/pg2.mp3";
import pg11 from "../../assets/audios/speech/Mission8/Page 11.mp3";
import pg4 from "../../assets/audios/speech/Mission8/Page 4.mp3";
import pg5 from "../../assets/audios/speech/Mission8/Page 5.mp3";
import pg9 from "../../assets/audios/speech/Mission7/pg9.mp3";
import pg12 from "../../assets/audios/speech/Mission8/Page 12.mp3";
import pg13 from "../../assets/audios/speech/Mission8/Page 13.mp3";
import pg16 from "../../assets/audios/speech/Mission8/Page 16.mp3";
import pg18 from "../../assets/audios/speech/Mission8/Page 18.mp3";
import pg19 from "../../assets/audios/speech/Mission8/Page 19.mp3";
import pg20 from "../../assets/audios/speech/Mission8/Page 20.mp3";
import pg21 from "../../assets/audios/speech/Mission8/Page 21.mp3";
import pg22 from "../../assets/audios/speech/Mission8/Page 22.mp3";
import pg23 from "../../assets/audios/speech/Mission8/Page 23.mp3";
import pg24 from "../../assets/audios/speech/Mission8/Page 24.mp3";
import pg25 from "../../assets/audios/speech/Mission8/Page 25.mp3";
import pg26 from "../../assets/audios/speech/Mission8/Page 26.mp3";
import pg27 from "../../assets/audios/speech/Mission8/Page 27.mp3";
import pg28 from "../../assets/audios/speech/Mission8/Page 28.mp3";
import pg31 from "../../assets/audios/speech/Mission8/Page 31.mp3";
import pg32 from "../../assets/audios/speech/Mission8/Page 32.mp3";
import pg33 from "../../assets/audios/speech/Mission8/Page 33.mp3";
import Page34Q1 from "../../assets/audios/speech/Mission8/Page 34Q1.mp3";
import Page34Q2 from "../../assets/audios/speech/Mission8/Page 34Q2.mp3";
import pg37 from "../../assets/audios/speech/Mission8/Page 37.mp3";
import pg38 from "../../assets/audios/speech/Mission8/Page 38.mp3";
import pg39 from "../../assets/audios/speech/Mission8/Page 39.mp3";
import pg40 from "../../assets/audios/speech/Mission8/Page 40.mp3";
import pg41 from "../../assets/audios/speech/Mission8/Page 41.mp3";
import pg42 from "../../assets/audios/speech/Mission8/Page 43.mp3";
import pg43 from "../../assets/audios/speech/Mission8/Page 44.mp3";
import Page44Q1 from "../../assets/audios/speech/Mission8/Page 44Q1.mp3";
import Page44Q2 from "../../assets/audios/speech/Mission8/Page 44Q2.mp3";
import pg47 from "../../assets/audios/speech/Mission8/Page 47.mp3";
import pg48 from "../../assets/audios/speech/Mission8/Page 48.mp3";
import Page49Q1 from "../../assets/audios/speech/Mission8/Page 49Q1.mp3";
import Page49Q2 from "../../assets/audios/speech/Mission8/Page 49Q2.mp3";
import pg51 from "../../assets/audios/speech/Mission8/Page 51.mp3";
import pg52 from "../../assets/audios/speech/Mission8/Page 52.mp3";
import pg53 from "../../assets/audios/speech/Mission8/Page 53.mp3";
import pg54 from "../../assets/audios/speech/Mission8/Page 54.mp3";
import pg55 from "../../assets/audios/speech/Mission8/page 55.mp3";
import pg56 from "../../assets/audios/speech/Mission8/Page 56.mp3";
import pg57 from "../../assets/audios/speech/Mission8/Page 57.mp3";
import PirateQuizReady from "./PirateQuizReady";
import homeButton from "../../assets/homeBtn.png";
import sectionsBG from "../../assets/sectionsBG.png";
import speaker from "../../assets/speaker.png";
import BreakTimer from "../BreakTImer";
import FirstThenBoard from "../FirstThenBoard";
import ProgressBar from "../ProgressBar";
import Ready from "../Ready";
import School from "../School";
import BellyBreathingQuiz from "./BellyBreathingQuiz";
import CaptainCharlieCheckIn from "./CaptainCharlieCheckIn";
import CopingWithPain from "./CopingWithPain";
import DeepPressure from "./DeepPressure";
import DeepPressureHead from "./DeepPressureHead";
import DeepPressureInfo from "./DeepPressureInfo";
import DeepPressureTask from "./DeepPressureTask";
import DeepPressureVideo from "./DeepPressureVideo";
import ExcellentJob from "./ExcellentJob";
import ExcellentJobDeepPressure from "./ExcellentJobDeepPressure";
import FeelingQuiz from "./FeelingQuiz";
import GoodJob from "./GoodJob";
import HeatPackTask from "./HeatPackTask";
import HeatPackVideoPage from "./HeatPackVideoPage";
import Mission7Intro from "./Mission7Intro";
import MissionPath from "./MissionPath";
import MissionPath1 from "./MissionPath1";
import MissionPath2 from "./MissionPath2";
import MissionPath3 from "./MissionPath3";
import MissionPath4 from "./MissionPath4";
import PainThisWeek from "./PainThisWeek";
import Recap from "./Recap";
import SailorAmy from "./SailorAmy";
import SailorAmyHeatPack from "./SailorAmyHeatPack";
import SpecialSkill1 from "./SpecialSkill1";
import SpecialSkillDeepPressure from "./SpecialSkillDeepPressure";
import SpecialSkills from "./SpecialSkills";
import TaskComponent from "./TaskComponent";
import TummyBreathing from "./TummyBreathing";
import UsingHeatPack from "./UsingHeatPack";
import UsingAHeatPackAmy from "./UsingHeatPackAmy";
import UsingHeatPackInfo from "./UsingHeatPackInfo";
import WelcomeBack7 from "./WelcomeBack7";
import YourMissionThisWeek5 from "./YourMissionThisWeek5";
import YourSpecialSkills from "./YourSpecialSkills";
import YourSpecialSkillsDetails from "./YourSpecialSkillsDetails";
import PirateQuiz from "./PirateQuiz";
import MissionPath5 from "./MissionPath5";
import MissionPath6 from "./MissionPath6";
import Mission7Complete from "./Mission7Complete";
import EndMission7 from "./EndMission7";
import EndMission7Continued from "./EndMission7Continued";
import SeeYou7 from "./SeeYou7";
import Badge8 from "./Badge8";

const Mission8 = () => {
  const [section, setSection] = useState(1);
  const [isBreak, setIsBreak] = useState(false);
  const audioRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [isOnChildComponent, setIsOnChildComponent] = useState(false);
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  );
  
  const handleSubmit = (isSubmitted) => {
    setSubmitted(isSubmitted);
  };
  
  const handlePrevious = () => {
    setSubmitted(false);
    setSection(section - 1);
    setIsOnChildComponent(false);
  };
  
  const handleNext = () => {
    console.log("setSubmited: ", submitted);
    if (isOnChildComponent) {
      console.log("Please complete the current step by clicking Next.");
      return;
    }

    if ([7, 8, 13, 26].includes(section)) {
      setSubmitted(false);
    }

    // Check for session storage requirements for specific sections
    const incompleteMessages = {
      7: "FeelingBetterQuizCompleted",
      8: "pain7Completed",
      13: "BellyBreathingQuizCompleted",
      14: "FeelingBetterQuizCompleted",
      26: "HeatPackTaskCompleted",
      30: "TaskComponentCompleted",
      38: "DeepPressureTaskCompleted",
      41: "PirateQuizCompleted",
    };

    if (
      incompleteMessages[section] &&
      (!sessionStorage.getItem(incompleteMessages[section]) ||
        sessionStorage.getItem(incompleteMessages[section]) === null)
    ) {
      toast.error("Please complete the task before proceeding.");
      return;
    }

    if (isLastPage) {
      navigate("/map", { state: { carrier } });
    } else {
      setSection(section + 1);
    }
  };

  const isPreviousDisabled = section === 1;
  const isLastPage = section === 49;

  const handleInputChange = (value) => {
    setInputValue(value);
    sessionStorage.setItem("firstThenInput", value);
  };
  const totalSections = 49;
  const location = useLocation();
  const carrier = location.state?.carrier;

  // Function to handle home button click
  const handleHomeClick = async () => {
    navigate(-1);
  };

  // Function to show toast notification
  const handleBreakClick = () => {
    toast.success("You can take a break now, your progress has been saved!");
    setIsBreak(true);
  };

  useEffect(() => {
    console.log("Section: ", section);

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [section]);
  // Function to play help audio
  const handleHelpClick = () => {
    const audio = new Audio(helpAudio);
    audio.play();
  };

  const userId = carrier?.userId;
  const saveStarsCollected = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}saveStarsCollected`,
        {
          userId: userId,
          missionId: 8,
          starsCollected: 28,
          totalStars: 28,
        }
      );
      if (response.status === 200) {
        const { data } = response.data;
      }
    } catch (error) {
      console.error("Error saving stars:", error);
    }
  };

  if (section === 41) {
    saveStarsCollected();
  }
  // Function to handle closing the break timer
  const handleBreakClose = () => {
    setIsBreak(false);
  };

  const handleTryAgain = () => {
    setSection(section - 1);
  };

  const handleTryAgain2 = () => {
    setSection(section - 2);
  };
  // Function to handle play text to speech
  const handleSpeech = (subsection = section) => {
    const sectionAudioMap = {
      2: pg2,
      3: pg3,
      4: pg4,
      5: pg5,
      6: pg6,
      7: pg7,
      8: pg8,
      9: pg9,
      10: pg10,
      11: pg11,
      12: pg12,
      13: pg13,
      14: pg16,
      16: pg18,
      17: pg19,
      18: pg20,
      19: pg21,
      20: pg22,
      21: pg23,
      22: pg24,
      23: pg25,
      24: pg26,
      25: pg27,
      26: pg28,
      27: pg31,
      28: pg32,
      29: pg33,
      30.1: Page34Q1,
      30.2: Page34Q2,
      31: pg37,
      32: pg38,
      33: pg39,
      34: pg40,
      35: pg41,
      36: pg42,
      37: pg43,
      38.1: Page44Q1,
      38.2: Page44Q2,
      39: pg47,
      40: pg48,
      41.1: Page49Q1,
      41.2: Page49Q2,
      43: pg51,
      44: pg52,
      45: pg53,
      46: pg54,
      47: pg55,
      48: pg56,
      49: pg57,
    };
    const audioFile = sectionAudioMap[subsection];
    if (audioFile) {
      // Pause the previous audio if it's playing
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      // Create a new audio instance and play it
      audioRef.current = new Audio(audioFile);
      audioRef.current.play();
    }
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6]">
      <ToastContainer />
      {/* Home Button */}
      <div className="flex flex-row items-center justify-between w-full">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 ml-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleHelpClick}
        >
          Help
        </button>
        <div className="flex-grow ml-0">
          <ProgressBar currentSection={section} totalSections={totalSections} />
        </div>
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 mr-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleBreakClick}
        >
          Break
        </button>
        <img
          src={speaker}
          alt="speaker"
          className={`hover:scale-110 ease-in-out transition duration-300 ${
            submitted ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (!submitted) {
              handleSpeech();
            }
          }}
        />
      </div>
      {/* Render ProgressBar component */}

      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative zoomer"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {isBreak && <BreakTimer onClose={handleBreakClose} />}
          </div>
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {/* Render content based on the current section */}
            {section === 1 && <Mission7Intro />}
            {section === 2 && <MissionPath />}
            {section === 3 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
            {section === 4 && (
              <WelcomeBack7 carrier={carrier} onNext={handleNext} />
            )}
            {section === 5 && (
              <YourMissionThisWeek5 onNext={handleNext} carrier={carrier} />
            )}
            {section === 6 && (
              <School
                carrier={carrier}
                setIsOnChildComponent={setIsOnChildComponent}
              />
            )}          
            {section === 7 && (
              <FeelingQuiz
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
              />
            )}
            {section === 8 && (
              <PainThisWeek
                carrier={carrier}
                submitted={submitted}
                setSubmitted={handleSubmit}
              />
            )}
            {section === 9 && <MissionPath1 />}
            {section === 10 && <Ready onNext={handleNext} />}
            {section === 11 && <Recap carrier={carrier} />}
            {section === 12 && <TummyBreathing />}
            {section === 13 && (
              <BellyBreathingQuiz
                submitted={submitted}
                setSubmitted={setSubmitted}
                setSection={setSection}
                carrier={carrier}
                audioRef={audioRef}
              />
            )}
            {section === 14 && <CaptainCharlieCheckIn carrier={carrier} />}
            {section === 15 && <GoodJob />}
            {section === 16 && <MissionPath2 />}
            {section === 17 && <SpecialSkills carrier={carrier} />}
            {section === 18 && <CopingWithPain />}
            {section === 19 && <MissionPath3 />}
            {section === 20 && <YourSpecialSkills onNext={handleNext} />}
            {section === 21 && <YourSpecialSkillsDetails />}
            {section === 22 && <SpecialSkill1 />}
            {section === 23 && <UsingHeatPack onNext={handleNext} />}
            {section === 24 && <UsingHeatPackInfo onNext={handleNext} />}
            {section === 25 && <HeatPackVideoPage />}
            {section === 26 && (
              <HeatPackTask
                setSection={setSection}
                carrier={carrier}
                audioRef={audioRef}
              />
            )}
            {section === 27 && <UsingAHeatPackAmy onAmyClick={handleNext} />}
            {section === 28 && <SailorAmy onNext={handleNext} />}
            {section === 29 && <SailorAmyHeatPack onNext={handleNext} />}
            {section === 30 && (
              <TaskComponent
                handleSpeech={handleSpeech}
                setSection={setSection}
                carrier={carrier}
                audioRef={audioRef}
              />
            )}
            {section === 31 && <ExcellentJob onNext={handleNext} />}
            {section === 32 && (
              <SpecialSkillDeepPressure onTreasureClick={handleNext} />
            )}
            {section === 33 && <DeepPressure onNext={handleNext} />}
            {section === 34 && <DeepPressureInfo onNext={handleNext} />}
            {section === 35 && <DeepPressureVideo onNext={handleNext} />}
            {section === 36 && <DeepPressureHead onNext={handleNext} />}
            {section === 37 && (
              <ExcellentJobDeepPressure onNext={handleNext} carrier={carrier} />
            )}
            {section === 38 && (
              <DeepPressureTask
                handleSpeech={handleSpeech}
                setSection={setSection}
                carrier={carrier}
                audioRef={audioRef}
              />
            )}
            {section === 39 && <MissionPath4 />}
            {section === 40 && <PirateQuizReady carrier={carrier} onStart={handleNext}/>}
            {section === 41 && <PirateQuiz handleSpeech={handleSpeech} carrier={carrier} onComplete={handleNext} />}
            {section === 42 && <Badge8 />}
            {section === 43 && <MissionPath5 />}
            {section === 44 && <Mission7Complete carrier={carrier} />}
            {section === 45 && <EndMission7 />}
            {section === 46 && <EndMission7Continued />}
            {section === 47 && <MissionPath6 /> }
            {section === 48 && <SeeYou7 carrier={carrier} />}
            {section === 49 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-around">
        {/* "Previous" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            backgroundColor: "#3FA7D6",
            color: "#fffff",
          }}
        >
          <svg
            className="w-10 h-10 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        <div className="text-center text-xs text-white mt-6">
          <span style={{ fontSize: "1rem" }}>
            © 2024 | University of Galway, Ireland
          </span>
        </div>

        {/* "Next" button */}
        <button
          className={`px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200 ${
            isOnChildComponent ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isOnChildComponent} // Disable the button when on child component
          onClick={handleNext}
          style={{
            backgroundColor: "#3FA7D6",
          }}
        >
          <svg
            className="w-10 h-10"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Mission8;
