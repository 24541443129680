import React from "react";
import chest from "../../assets/miscellaneous/chest.png";
import cptCharlie from "../../assets/cptCharlie.png";

const WelcomeBack7 = ({ carrier, onNext }) => {
  const handleNext = () => {
    onNext();
  };

  return (
    <div className=" w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Welcome Back
      </h2>
      <div className="flex flex-row">
        <div className="text-gray-700 text-base font-gillSans">
          <ol>
            <li className=" mt-5 lg:text-xl">
              Ahoy, Boatswain {carrier?.firstName}, Welcome aboard.
            </li>
            <li className=" mt-5 lg:text-xl">
            You have a very special mission from Captain Charlie. Click the treasure chest to find out your mission.
            </li>           
            <li className=" mt-4 ml-10 floater cursor-pointer">
              <img
                src={chest}
                onClick={handleNext}
                className="w-32"
                alt="chest"
              />
            </li>
            <li  className=" mt-5 lg:text-xl font-semibold">           
            Your mission today is: Learn things you can do to feel better when you have pain.
            </li>
          </ol>
        </div>
        <div className="ml-10">
          <img src={cptCharlie} alt="cptCharlie" className="mt-10 w-80" />
        </div>
      </div>
    </div>
  );
};

export default WelcomeBack7;
