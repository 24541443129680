import React, { useEffect } from "react";
import mateyMorgan from "../../assets/Characters/MateyMorgan.png";
import pg40 from "../../assets/audios/speech/Mission7/pg40.mp3";

const MateyMorganStory2 = ({ onNext }) => {
    useEffect(() => {
        const speech = new Audio(pg40);
        speech.play();

        return () => {
            speech.pause();
            speech.currentTime = 0;
        };
    }, []);
    return (
        <div className="w-full max-w-3xl mx-auto p-4 md:p-6 flex flex-col items-center">
            {/* Title */}
            <h1 className="text-3xl md:text-4xl font-bold mb-4 text-center font-gillSans">
                Matey Morgan
            </h1>

            {/* Content Section */}
            <div className="flex flex-col md:flex-row items-center">
                {/* Left Side: Image */}
                <div className="md:w-1/3 flex flex-col items-center mb-6 md:mb-0">
                    <img src={mateyMorgan} alt="Matey Morgan" className="w-3/4 md:w-full h-auto rounded-lg mb-4" />
                    <button
                        onClick={onNext}
                        className="bg-green-500 text-white px-6 py-2 text-lg font-bold rounded-md shadow-md hover:bg-green-600 transition duration-200"
                    >
                        Next
                    </button>
                </div>

                {/* Right Side: Story Content */}
                <div className="md:w-2/3 md:pl-4">
                    <p className="text-base md:text-lg lg:text-xl mb-4">
                        Hi, My name is Matey Morgan. I am one of Captain Charlie’s Friends. Captain Charlie has set me a task to tell you about using{" "}
                        <span className="text-green-600 font-semibold">Tummy Breathing to help feel better when you are in pain.</span>
                    </p>
                    <p className="text-base md:text-lg lg:text-xl mb-4">
                        Sometimes when I fall off the swing in the playground at school, I cry, because my leg hurts. My teacher helps me take some tummy breaths to feel better. I take{" "}
                        <span className="text-green-600 font-semibold">big deep breaths in through my nose for 4 seconds and then let them out through my mouth for 7 seconds.</span>
                    </p>
                    <p className="text-base md:text-lg lg:text-xl">
                        I practice this three times with my teacher.{" "}
                        <span className="text-green-600 font-semibold">After doing tummy breathing, my leg doesn't hurt as much, and I feel much better.</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MateyMorganStory2;
