import React, { useEffect } from "react";
import tummyBreathing from "../../assets/pain diary/tummyBreathing.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const ImportantToRemember = () => {
    useEffect(() => {
        const audio = new Audio(coins);
        audio.play();
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, []);
    return (
        <div className="w-full max-w-3xl mx-auto p-4 md:p-6 flex flex-col md:flex-row items-center bg-transparent">
            {/* Left Side: Text Content */}
            <div className="md:w-3/5 text-left md:pr-6">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 text-black flex items-center ">Important to Remember
                    <img src={coin} className="w-12 ml-4 heartbeat" alt="coin" />
                </h2>
                <p className="text-base md:text-lg mb-4 leading-relaxed">
                    Tummy Breathing is super special because it helps your body relax and feel better.
                </p>
                <p className="text-base md:text-lg mb-4 leading-relaxed">
                    When you're in pain, sometimes your body hurts, but Tummy Breathing helps your body feel better.
                </p>
                <p className="text-base md:text-lg leading-relaxed">
                    It's like a magic trick your body can do!
                </p>
            </div>

            {/* Right Side: Image */}
            <div className="md:w-2/5 flex justify-center md:justify-start mt-6 md:mt-0">
                <img src={tummyBreathing} alt="Laying Down" className="w-4/5 h-auto rounded-lg" />
            </div>
        </div>
    );
};

export default ImportantToRemember;
