import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BodyMap from "../../BodyMapNew";
import pg2 from "../../../assets/audios/speech/PainDiary/pg2.mp3";
import pg3 from "../../../assets/audios/speech/PainDiary/pg3.mp3";

const Hurt = () => {
  const [clickedAreas, setClickedAreas] = useState([]);
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showBodyMap, setShowBodyMap] = useState(false);
  const [noPainMessage, setNoPainMessage] = useState("");
  const location = useLocation();
  const carrier = location.state?.carrier;
  const userId = carrier?.userId;

  useEffect(() => {
    if (showBodyMap) {
      const audioRef = new Audio(pg3);
      audioRef.play();
      return () => {
        audioRef.pause();
        audioRef.currentTime = 0;
      };
    } else {
      const audioRef = new Audio(pg2);
      audioRef.play();
      return () => {
        audioRef.pause();
        audioRef.currentTime = 0;
      };
    }
  }, [showBodyMap]); 

  useEffect(() => {
    fetchPainDiary(userId);
  }, [userId]);
  const fetchPainDiary = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}painDiary/${userId}`
      );
      const data = await response.json();
      setSelectedBodyParts(data.bodyParts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pain diary:", error);
      setLoading(false);
    }
  };

  const logPainDiary = async () => {
    setLoading(true);

    const payload = {
      userId: userId,
      bodyPart: clickedAreas.join(","),
      diaryEntryTime: new Date().toISOString(),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}logPainDiary`,
        payload
      );

      toast.success("Pain diary entry logged successfully!");
    } catch (error) {
      console.error("Error logging pain diary entry:", error);
      toast.error("Error logging pain diary entry!");
    } finally {
      setLoading(false);
    }
  };

  const logNoPainDiary = async () => {
    setLoading(true);

    const payload = {
      userId: userId,
      bodyPart: "No Pain",
      diaryEntryTime: new Date().toISOString(),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}logPainDiary`,
        payload
      );

      toast.success("Pain diary entry logged successfully!");
      setNoPainMessage("Great! Click on the arrow to continue");
    } catch (error) {
      console.error("Error logging pain diary entry:", error);
      toast.error("Error logging pain diary entry!");
    } finally {
      setLoading(false);
    }
  };

  const handleAreaClick = (area) => {
    if (clickedAreas.includes(area)) {
      setClickedAreas((prevClickedAreas) =>
        prevClickedAreas.filter((clickedArea) => clickedArea !== area)
      );
    } else {
      setClickedAreas((prevClickedAreas) => [...prevClickedAreas, area]);
    }
  };

  const numColumns = clickedAreas.length > 10 ? 2 : 1;

  return (
    <div className="w-full flex justify-center items-center">
      {!showBodyMap ? (
        <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn mt-20">
          <div className="flex flex-row items-center justify-center gap-2">
            <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-xl md:mt-2 lg:text-5xl font-gillSans">
              Body Parts that hurt
            </h2>
          </div>
          <div className="text-gray-700 text-base font-gillSans">
            <p className="lg:mt-10 text-center lg:text-xl md:mr-1">
              Did you have any body parts that hurt today?
            </p>
            <div className="flex flex-row space-x-5 items-center justify-center lg:mt-8 md:mt-4">
              <button
                className="px-12 py-4 rounded-full bg-green-500 font-bold text-white tracking-widest uppercase transform hover:scale-105 hover:bg-green-700 transition-colors duration-200"
                onClick={() => setShowBodyMap(true)}
              >
                Yes
              </button>
              <button
                className="px-12 py-4 rounded-full bg-red-500 font-bold text-white tracking-widest uppercase transform hover:scale-105 hover:bg-red-700 transition-colors duration-200"
                onClick={logNoPainDiary}
              >
                No
              </button>
            </div>
            {/* Display the message when "No" is clicked */}
            {noPainMessage && (
              <p className="text-green-600 font-bold text-center mt-4">
                {noPainMessage}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-24">
          <div className="flex flex-row items-center justify-center gap-2">
            <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-xl md:mt-2 lg:text-5xl font-gillSans">
              My Body Map
            </h2>
          </div>
          <div className="text-gray-700 text-base font-gillSans">
            <p className="lg:mt-2 text-center lg:text-xl md:mr-1">
              What body parts hurt today?
            </p>
            <div className="text-gray-700 text-base flex justify-evenly font-gillSans">
              <ol className="mt-5">
                <li className="mt-5 text-xl text-[#00008B]">
                  Select the body parts on the body map to show which body parts
                  hurt you today.
                </li>
                <p className="mt-5 text-xl">You selected:</p>
                <div className="grid grid-cols-2 gap-4">
                  {[...Array(numColumns)].map((_, colIndex) => (
                    <ol
                      key={colIndex}
                      start={
                        colIndex * Math.ceil(clickedAreas.length / numColumns) +
                        1
                      }
                      className="list-decimal mt-2"
                    >
                      {clickedAreas
                        .slice(
                          colIndex *
                            Math.ceil(clickedAreas.length / numColumns),
                          (colIndex + 1) *
                            Math.ceil(clickedAreas.length / numColumns)
                        )
                        .map((area, index) => (
                          <li key={index}>{area}</li>
                        ))}
                    </ol>
                  ))}
                </div>
                <button
                  onClick={logPainDiary}
                  className="bg-[#267e8c] p-2 rounded-lg text-white font-gillSans"
                >
                  Log Pain Diary
                </button>
              </ol>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <BodyMap
                  onAreaClick={handleAreaClick}
                  selectedAreas={selectedBodyParts}
                />
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default Hurt;
