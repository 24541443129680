import React, { useState, useEffect, useRef } from "react";
import closedTreasureBox from "../../assets/miscellaneous/treasurebox.png";
import openTreasureBox from "../../assets/miscellaneous/chest.png"; 
import coin from "../../assets/Icons/Coin.png";
import heatpack from "../../assets/pain diary/Heat Pack 2.png";
import coins from "../../assets/audios/coins.mp3";

const SpecialSkill1 = () => {
    const [isTreasureBoxOpen, setIsTreasureBoxOpen] = useState(false);
    const audioRef = useRef(null);
    useEffect(() => {
        const audio = new Audio(coins);    
        
        return () => {
          audio.pause();
          audio.currentTime = 0;
        };
      }, []);

    useEffect(() => {
        
        if (audioRef.current) {
            audioRef.current.play();
        }
    }, []);

    const handleTreasureClick = () => {
        setIsTreasureBoxOpen(true);
    };

    return (
        <div className="w-full text-sm max-w-4xl bg-transparent md:w-[80%] lg:w-[70%] xl:w-[60%] fadeIn -mt-10 mx-auto">
            <h2 className="text-2xl font-bold mb-4 text-center text-[#6BBF59] md:text-3xl lg:text-4xl font-gillSans flex items-center justify-center">
                Your Special Skill
                <img src={coin} className="w-14 h-12 ml-3 heartbeat" alt="coin" />
            </h2>
            <p className="text-center text-2xl mt-4 font-gillSans">Your first skill is:</p>
            <div className="flex flex-row items-center justify-center space-x-10 mt-8">                
                {/* Display the treasure box */}
                <img
                    src={isTreasureBoxOpen ? openTreasureBox : closedTreasureBox}
                    alt="Treasure Box"
                    className="cursor-pointer w-60 h-auto transition-transform transform hover:scale-110 heartbeat"

                    onClick={handleTreasureClick}
                />

                {/* Display the Heat Pack image when the box is opened */}
                {isTreasureBoxOpen && (
                    <div className="flex flex-col items-center">
                        <img
                            src={heatpack}
                            alt="Heat Pack"
                            className="w-64 h-auto rounded-md"
                        />
                        <p className="text-center text-2xl mt-4 font-gillSans">Using a heat pack</p>
                    </div>
                )}
            </div>
            {!isTreasureBoxOpen && (                
                <p className="text-center font-gillSans text-2xl mt-6">
                    You will learn about 2 special skills today. <br/>
                    Click the treasure chest to learn about the first special skill.
                </p>
            )}
        </div>
    );
};

export default SpecialSkill1;
