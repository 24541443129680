import React, { useState, useEffect } from "react";
import coinIcon from "../../assets/Icons/Coin.png";
import CorrectHPQuiz from "./CorrectHPQuiz";
import IncorrectDPQuiz from "./IncorrectHPQuiz";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeepPressureTask = ({ setSection, carrier, audioRef, handleSpeech }) => {
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    useEffect(() => {
        handleSpeech(38.1);
    }, []);
    const correctAnswers = {
      q1: "b",
      q2: "a",
    };
  
    const handleOptionSelect = (question, option) => {
      if (!isSubmitted) {
        setSelectedAnswers((prev) => ({ ...prev, [question]: option }));
      }
    };
  
    const handleNext = () => {
      if (currentQuestion < 2) {
        setCurrentQuestion((prev) => prev + 1);
        handleSpeech(38.2);
      } else {
        setIsSubmitted(true);
      }
    };
  
    const handleTryAgain = () => {
      setSelectedAnswers({});
      setIsSubmitted(false);
      setCurrentQuestion(1);
      setSection(38);
    };
  
    if (
      isSubmitted &&
      selectedAnswers.q1 === correctAnswers.q1 &&
      selectedAnswers.q2 === correctAnswers.q2
    ) {
      sessionStorage.setItem("DeepPressureTaskCompleted", true);
      return <CorrectHPQuiz carrier={carrier} audioRef={audioRef}/>;
    } else if (isSubmitted) {
      return <IncorrectDPQuiz audioRef={audioRef} onTryAgain={handleTryAgain} />;
    }
  
    return (
      <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
        {/* Title Section */}
        <div className="flex items-center justify-between w-full">
          <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-16">
            Task
            <img
              src={coinIcon}
              alt="coin"
              className="w-12 h-12 ml-4 heartbeat"
            />
          </h2>
        </div>
  
        {/* Content Section */}
        <div className="text-lg md:text-xl text-left space-y-4 mt-8">
          <p>
            Excellent work learning all about deep pressure. Keep up the good
            work! <br/> There is a special badge waiting for you at the end of this
            mission.
          </p>
          <p>Captain Charlie wants to test your knowledge on deep pressure.</p>
          {currentQuestion === 1 && (
            <>
              <p className="mt-6">
                <strong>1. What can you do to feel better when you have pain?</strong>
              </p>
              <ul className="list-none space-y-4">
                {["a", "b", "c"].map((option) => (
                  <li
                    key={`q1-${option}`}
                    className={`cursor-pointer p-2 rounded ${
                      selectedAnswers.q1 === option ? "bg-yellow-200" : ""
                    }`}
                    onClick={() => handleOptionSelect("q1", option)}
                  >
                    {option === "a" && "a) Jump up and down"}
                    {option === "b" &&
                      "b) Use deep pressure on the body part that hurts"}
                    {option === "c" && "c) Eats lots of food"}
                  </li>
                ))}
              </ul>
            </>
          )}
          {currentQuestion === 2 && (
            <>
              <p className="mt-6">
                <strong>
                  2. Why is deep pressure important when you have pain?
                </strong>
              </p>
              <ul className="list-none space-y-4">
                {["a", "b", "c"].map((option) => (
                  <li
                    key={`q2-${option}`}
                    className={`cursor-pointer p-2 rounded ${
                      selectedAnswers.q2 === option ? "bg-yellow-200" : ""
                    }`}
                    onClick={() => handleOptionSelect("q2", option)}
                  >
                    {option === "a" &&
                      "a) Helps the pain go away and you feel better"}
                    {option === "b" && "b) It makes you laugh"}
                    {option === "c" && "c) Makes you run faster"}
                  </li>
                ))}
              </ul>
            </>
          )}
          <button
            onClick={handleNext}
            className="mt-4 px-6 py-2 bg-green-500 text-white rounded-md ml-5"
          >
            {currentQuestion === 2 ? "Submit" : "Next"}
          </button>
        </div>
        <ToastContainer />
      </div>
    );
  };

export default DeepPressureTask;
