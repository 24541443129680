import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import helpAudio from "../../assets/audios/INeedHelp.mp3";
import SchoolAudio from "../../assets/audios/speech/Common/School.mp3";
import PainCorrect from "../../assets/audios/speech/Mission 3/16.1.mp3";
import PainIncorrect from "../../assets/audios/speech/Mission 3/16.2.mp3";
import Activity2Correct from "../../assets/audios/speech/Mission 3/34.1.mp3";
import FinalQuizInCorrect from "../../assets/audios/speech/Mission 3/39.2.mp3";
import ActivityCorrect from "../../assets/audios/speech/Mission 3/ActivityCorrect.mp3";
import ActivityIncorrect from "../../assets/audios/speech/Mission 3/ActivityIncorrect.mp3";
import BonusAudio from "../../assets/audios/speech/Mission 3/BonusAudio.mp3";
import Congo2 from "../../assets/audios/speech/Mission 3/Congo2.mp3";
import Congratulations from "../../assets/audios/speech/Mission 3/Congratulations.mp3";
import WelcomeBackAudio from "../../assets/audios/speech/Mission 3/M3 Welcome back.mp3";
import NoPainAudio from "../../assets/audios/speech/Mission 3/M3 pg 26.mp3";
import SomePainAudio from "../../assets/audios/speech/Mission 3/M3 pg 27.mp3";
import MostPainAudio from "../../assets/audios/speech/Mission 3/M3 pg 28.mp3";
import PainAudio from "../../assets/audios/speech/Mission 3/M3 pg14 Pain.mp3";
import PainAudio2 from "../../assets/audios/speech/Mission 3/M3 pg15 What is pain.mp3";
import m2pg1 from "../../assets/audios/speech/Mission 3/M3 pg2 Today's mission.mp3";
import PointingAudio from "../../assets/audios/speech/Mission 3/M3 pg21.mp3";
import BodyMapAudio from "../../assets/audios/speech/Mission 3/M3 pg22.mp3";
import PainScaleAudio from "../../assets/audios/speech/Mission 3/M3 pg24.mp3";
import PainScaleUseAudio from "../../assets/audios/speech/Mission 3/M3 pg25.mp3";
import MissionthisWeekAudio from "../../assets/audios/speech/Mission 3/M3 pg5 Mission this week.mp3";
import BodyPartsAudio from "../../assets/audios/speech/Mission 3/M3 pg9 Recap Using the Body Map.mp3";
import M3pg3 from "../../assets/audios/speech/Mission 3/M3pg3.mp3";
import BodyMapUseAudio from "../../assets/audios/speech/Mission 3/Pain Diary and M3 pg 10.mp3";
import PainDiaryAudio from "../../assets/audios/speech/Mission 3/PainDiaryAudio.mp3";
import PirateQuizAudio from "../../assets/audios/speech/Mission 3/PirateQuizAudio.mp3";
import PirateQuizAudio2 from "../../assets/audios/speech/Mission 3/PirateQuizAudio2.mp3";
import ReadyAudio from "../../assets/audios/speech/Mission 3/ReadyAudio.mp3";
import SeeyouAudio from "../../assets/audios/speech/Mission 3/SeeyouAudio.mp3";
import ShowPain from "../../assets/audios/speech/Mission 3/ShowPain.mp3";
import TaskAudio from "../../assets/audios/speech/Mission 3/TaskAudio.mp3";
import ThisWeek from "../../assets/audios/speech/Mission 3/ThisWeek.mp3";
import ThisWeek2 from "../../assets/audios/speech/Mission 3/ThisWeek2.mp3";
import VisualAudio1 from "../../assets/audios/speech/Mission 3/VisualAudio1.mp3";
import VisualAudio2 from "../../assets/audios/speech/Mission 3/VisualAudio2.mp3";
import VisualAudio3 from "../../assets/audios/speech/Mission 3/VisualAudio3.mp3";
import VisualAudio4 from "../../assets/audios/speech/Mission 3/VisualAudio4.mp3";
import VisualAudio5 from "../../assets/audios/speech/Mission 3/VisualAudio5.mp3";
import VisualAudio6 from "../../assets/audios/speech/Mission 3/VisualAudio6.mp3";
import badgeAudio from "../../assets/audios/speech/Mission 3/badgeAudio.mp3";
import m3pg21 from "../../assets/audios/speech/Mission 3/m3pg21.mp3";
import QuestionTime from "../../assets/audios/speech/Mission 3/pg16.mp3";
import pg30 from "../../assets/audios/speech/Mission 3/pg30.mp3";
import pg31 from "../../assets/audios/speech/Mission 3/pg31.mp3";
import pg33 from "../../assets/audios/speech/Mission 3/pg33.mp3";
import pg34 from "../../assets/audios/speech/Mission 3/pg34.mp3";
import pg35 from "../../assets/audios/speech/Mission 3/pg35.mp3";
import pg36 from "../../assets/audios/speech/Mission 3/pg36.mp3";
import homeButton from "../../assets/homeBtn.png";
import sectionsBG from "../../assets/sectionsBG.png";
import speaker from "../../assets/speaker.png";
import BonusPoint from "../BonusPoint";
import BreakTimer from "../BreakTImer";
import ExcellentJob from "../ExcellentJob";
import FirstThenBoard from "../FirstThenBoard";
import GoodJob from "../GoodJob";
import ProgressBar from "../ProgressBar";
import Ready from "../Ready";
import School from "../School";
import TaskTime from "../mission2/TaskTime";
import Activity from "./Activity";
import Activity2 from "./Activity2";
import Activity2Question from "./Activity2Question";
import Badge3 from "./Badge3";
import CommunicatingPain from "./CommunicatingPain";
import EndMission3 from "./EndMission3";
import EndMission3Continued from "./EndMission3Continued";
import FinalQuiz from "./FinalQuiz";
import KeepUp from "./KeepUp";
import MapExpert from "./MapExpert";
import Mission3Complete from "./Mission3Complete";
import Mission3Intro from "./Mission3Intro";
import MissionPath from "./MissionPath";
import MissionPath1 from "./MissionPath1";
import MissionPath2 from "./MissionPath2";
import MissionPath3 from "./MissionPath3";
import MissionPath4 from "./MissionPath4";
import MissionPath5 from "./MissionPath5";
import MissionPath6 from "./MissionPath6";
import MissionThisWeek3 from "./MissionThisWeek3";
import MostPain from "./MostPain";
import NoPain from "./NoPain";
import PQIntroM3 from "./PQIntroM3";
import Pain from "./Pain";
import PainLevels from "./PainLevels";
import PainQuiz from "./PainQuiz";
import PainScaleIntro from "./PainScaleIntro";
import PartExpert from "./PartExpert";
import Recap from "./Recap";
import RecapMap from "./RecapMap";
import Section1 from "./Section1";
import Section3 from "./Section3";
import Section4 from "./Section4";
import SeeYou3 from "./SeeYou3";
import SomePain from "./SomePain";
import UsingPainScale from "./UsingPainScale";
import WelcomeBack3 from "./WelcomeBack3";
import WhatIsPain from "./WhatIsPain";

const Mission3 = () => {
  // Initialize the section state using useState
  const [section, setSection] = useState(1);
  const [isBreak, setIsBreak] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [Task1Completed, setTask1Completed] = useState(false);
  const [Task2Completed, setTask2Completed] = useState(false);
  const [Task3Completed, setTask3Completed] = useState(false);
  const [Task4Completed, setTask4Completed] = useState(false);
  const [isOnChildComponent, setIsOnChildComponent] = useState(false);
  // Function to handle answer submission from Activity component
  const handleSubmit = (isSubmitted) => {
    setSubmitted(isSubmitted);
  };

  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  ); // Initialize with session storage value
  // useNavigate init.
  const navigate = useNavigate();
  const audioRef = useRef(null);
  const location = useLocation();
  const carrier = location.state?.carrier;
  const userId = carrier?.userId;
  const [Coins, setCoins] = useState(26);

  const saveStarsCollected = async () => {
    

    try {
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}saveStarsCollected`,
        {
          userId: userId,
          missionId: 3,
          starsCollected: Coins, 
          totalStars: 26, 
        }
      );
      if (response.status === 200) {
        const { data } = response.data;
        
      }
    } catch (error) {
      console.error("Error saving stars:", error);
    }
  };
  // Function to handle play text to speech
  const handleSpeech = (subsection = section) => {
    const sectionAudioMap = {
      2: m2pg1,
      3: M3pg3,
      4: WelcomeBackAudio,
      5: MissionthisWeekAudio,
      6: SchoolAudio,
      7: VisualAudio1,
      9: BodyPartsAudio,
      10: BodyMapUseAudio,
      11: Congratulations,
      12: PainDiaryAudio,
      13: VisualAudio2,
      14: PainAudio,
      15: PainAudio2,
      16: QuestionTime,
      16.1: PainCorrect,
      16.2: PainIncorrect,
      17: VisualAudio3,
      18: ShowPain,
      19: PointingAudio,
      20: BodyMapAudio,
      21: m3pg21,
      22: PainScaleAudio,
      23: PainScaleUseAudio,
      24: NoPainAudio,
      25: SomePainAudio,
      26: MostPainAudio,
      27: ReadyAudio,
      28: TaskAudio,
      28.2: ActivityCorrect,
      28.3: ActivityIncorrect,
      29: BonusAudio,
      30: pg30,
      31: pg31,
      32: ReadyAudio,
      33: pg33,
      34: pg34,
      34.1: Activity2Correct,
      35: pg35,
      36: pg36,
      37: VisualAudio4,
      38: PirateQuizAudio,
      39: PirateQuizAudio2,
      39.2: FinalQuizInCorrect,
      40: badgeAudio,
      41: VisualAudio5,
      42: Congo2,
      43: ThisWeek,
      44: ThisWeek2,
      45: VisualAudio6,
      46: SeeyouAudio,
      
    };

    const audioFile = sectionAudioMap[subsection];
    if (audioFile) {
      // Pause the previous audio if it's playing
      if (audioRef.current) {
        audioRef.current.pause();
      }
      // Create a new audio instance and play it
      audioRef.current = new Audio(audioFile);
      audioRef.current.play();
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null; // Clean up the audio reference
      }
    };
  }, [section]);

  // Decrease the section value when "Previous" button is clicked
  const handlePrevious = () => {
    setSubmitted(false);
    setSection(section - 1);    
  };

  // Determine if the "Next" button should be disabled
  const handleNext = () => {
    if (isOnChildComponent) {
      console.log("Please complete the current step by clicking Next.");
      return;
    }
    if (
      (section === 6 && !sessionStorage.getItem("schoolQuizCompleted")) ||
      (section === 10 && !sessionStorage.getItem("painCompleted")) ||
      (section === 16 && !sessionStorage.getItem("painQuizCompleted")) ||
      (section === 28 && !sessionStorage.getItem("activityCompleted")) ||
      (section === 30 && !sessionStorage.getItem("painLevelsCompleted")) ||
      (section === 34 && !sessionStorage.getItem("activity2Completed")) ||
      (section === 39 && !sessionStorage.getItem("pQ3Completed"))
    ) {
      toast.error("Please complete the quiz before proceeding.");
      return;
    }

    if (isLastPage) {
      navigate("/map", { state: { carrier } });
    } else {
      setSection(section + 1);
    }
    if ([16, 28, 34, 39].includes(section)) {
      setSubmitted(false); // Reset the submitted state if section is 16, 28, 34, or 39
    }
    // console.log("Section: ",section);
  };

  const handleTryAgain = () => {
    setSection(section - 5);
  };

  const isPreviousDisabled = section === 1;
  const isLastPage = section === 47;

  const totalSections = 47;
  if (section === 47) {
    saveStarsCollected();
  }

  const handleInputChange = (value) => {
    setInputValue(value);
    sessionStorage.setItem("firstThenInput", value); // Save to session storage
  };

  // Function to handle home button click
  const handleHomeClick = async () => {

    // const payload = [
    //   {
    //     userId: carrier.userId,
    //     pirateRank: carrier.pirateRank,
    //     completedMissionId: carrier.completedMissionId,
    //     currentMissionId: carrier.currentMissionId,
    //     currentMissionPage: section,
    //   },
    // ];
    // console.log("Payload: ", payload);
    // try {
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_API_BASE_URL}mission/saveMissionDetailsForUser`,
    //     payload
    //   );
    // } catch (error) {
    //   console.error("Error saving answer:", error);
    // }
    // Navigate back to the previous screen
    navigate(-1);
  };

  // Function to show toast notification
  const handleBreakClick = () => {
    toast.success("You can take a break now, your progress has been saved!");
    setIsBreak(true);
  };

  // Function to play help audio
  const handleHelpClick = () => {
    const audio = new Audio(helpAudio);
    audio.play();
  };

  // Function to handle closing the break timer
  const handleBreakClose = () => {
    setIsBreak(false);
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6]">
      <ToastContainer />
      {/* Top Bar */}
      <div className="flex flex-row items-center justify-between w-full">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 ml-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleHelpClick}
        >
          Help
        </button>
        <div className="flex-grow ml-0">
          <ProgressBar currentSection={section} totalSections={totalSections} />
        </div>
        <button
          className="bg-[#EE6352] text-white font-bold rounded-lg px-6 py-2 mr-4 transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]"
          onClick={handleBreakClick}
        >
          Break
        </button>
        <img
          src={speaker}
          alt="speaker"
          className={`hover:scale-110 ease-in-out transition duration-300 ${
            submitted ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => {
            if (!submitted) {
              handleSpeech();
            }
          }}
        />
      </div>
      {/* Render ProgressBar component */}

      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative zoomer"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          {" "}
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {isBreak && <BreakTimer onClose={handleBreakClose} />}
          </div>
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {/* Render content based on the current section */}
            {section === 1 && <Mission3Intro />}
            {section === 2 && <MissionPath />}
            {section === 3 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
            {section === 4 && (
              <WelcomeBack3 onNext={handleNext} carrier={carrier} />
            )}
            {section === 5 && (
              <MissionThisWeek3 onNext={handleNext} carrier={carrier} />
            )}
            {/* coin*/}
            {section === 6 && <School carrier={carrier} setIsOnChildComponent={setIsOnChildComponent}/>} {/* lock */}
            {section === 7 && <MissionPath1 />}
            {section === 8 && <Ready onNext={handleNext} />}
            {section === 9 && <RecapMap />}
            {section === 10 && <Pain carrier={carrier} />} {/* coin*/}
            {/* lock */}
            {section === 11 && <ExcellentJob />}
            {section === 12 && <KeepUp />}
            {section === 13 && <MissionPath2 />}
            {section === 14 && <Section1 />} {/* coin*/}
            {section === 15 && <WhatIsPain carrier={carrier} />} {/* coin*/}
            {section === 16 && (
              <PainQuiz
                carrier={carrier}
                handleSpeech={handleSpeech}
                submitted={submitted}
                setSubmitted={handleSubmit}
              />
            )}{" "}
            {/* lock */}
            {section === 17 && <MissionPath3 />}
            {section === 18 && <Section3 />}
            {section === 19 && <PartExpert />} {/* coin*/}
            {section === 20 && <MapExpert />} {/* coin*/}
            {section === 21 && <PainScaleIntro onNext={handleNext} />}
            {section === 22 && <Section4 />}
            {section === 23 && <UsingPainScale />} {/* Audios pending */}
            {section === 24 && <NoPain />} {/* coin*/}
            {section === 25 && <SomePain />} {/* coin*/}
            {section === 26 && <MostPain />} {/* coin*/}
            {section === 27 && <TaskTime onNext={handleNext} />}
            {section === 28 && (
              <Activity
                carrier={carrier}
                onTryAgain={handleTryAgain}
                handleSpeech={handleSpeech}
                submitted={submitted}
                setSubmitted={handleSubmit}
                setTask2Completed={setTask2Completed}
              />
            )}
            {/* lock */} {/* coin*/}
            {section === 29 && <BonusPoint />}
            {section === 30 && (
              <PainLevels
                carrier={carrier}
                setTask1Completed={setTask1Completed}
                Task1Completed={Task1Completed}
              />
            )}{" "}
            {/* lock */}
            {section === 31 && <GoodJob />} {/* coin*/}
            {section === 32 && <TaskTime onNext={handleNext} />}
            {section === 33 && <Activity2 />}
            {section === 34 && (
              <Activity2Question
                carrier={carrier}
                setTask3Completed={setTask3Completed}
                handleSpeech={handleSpeech}
                submitted={submitted}
                setSubmitted={handleSubmit}
              />
            )}
            {/* coin*/} {/* lock */}
            {section === 35 && <CommunicatingPain />} {/* coin*/}
            {section === 36 && <Recap carrier={carrier} />}
            {section === 37 && <MissionPath4 />}
            {section === 38 && (
              <PQIntroM3 onNext={handleNext} carrier={carrier} />
            )}
            {section === 39 && (
              <FinalQuiz
                carrier={carrier}
                setTask3Completed={setTask3Completed}
                handleSpeech={handleSpeech}
                submitted={submitted}
                setTask4Completed={setTask4Completed}
              />
            )}{" "}
            {/* coin*/}
            {/* lock */}
            {section === 40 && <Badge3 />}
            {section === 41 && <MissionPath5 />}
            {section === 42 && <Mission3Complete carrier={carrier} />}
            {section === 43 && <EndMission3 />}
            {section === 44 && <EndMission3Continued />}
            {section === 45 && <MissionPath6 />}
            {section === 46 && <SeeYou3 />}
            {section === 47 && (
              <FirstThenBoard
                inputValue={inputValue}
                onInputChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-around">
        {/* "Previous" button */}
        <button
          className="px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            backgroundColor: "#3FA7D6",
            color: "#fffff",
          }}
        >
          <svg
            className="w-10 h-10 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        <div className="text-center text-xs text-white mt-6">
          <span style={{ fontSize: "1rem" }}>
            © 2024 | University of Galway, Ireland
          </span>
        </div>

        {/* "Next" button */}
        <button
          className={`px-12 py-4 rounded-full bg-[#9c6334] font-bold text-white tracking-widest uppercase transform hover:scale-110 hover:bg-[#623e20] transition-colors duration-200 ${
            isOnChildComponent ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isOnChildComponent}
          onClick={handleNext}
          style={{
            backgroundColor: "#3FA7D6",
          }}
        >
          <svg
            className="w-10 h-10"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Mission3;
