import React, { useEffect } from "react";
import polly from "../../assets/Characters/SailorPolly.png";
import pg38 from "../../assets/audios/speech/Mission7/pg38.mp3";

const TummyBreathingWithPolly = ({ onNext, audioRef }) => {
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        const speech = new Audio(pg38);
        speech.play();

        return () => {
            speech.pause();
            speech.currentTime = 0;
        };
    }, []);
    return (
        <div className="w-full max-w-3xl mx-auto p-4 md:p-6">
            <h1 className="text-3xl md:text-4xl font-bold mb-4 text-center text-[#6BBF59] font-gillSans mt-8">
                Tummy Breathing with Polly
            </h1>
            <div className="flex flex-col md:flex-row items-center md:items-start">
                {/* Image and Button Section */}
                <div className="w-full md:w-1/3 flex flex-col items-center mb-4 md:mb-0">
                    <img src={polly} alt="Sailor Polly" className="w-3/4 md:w-full h-auto rounded-lg mb-4"/>
                    <button
                        onClick={onNext}
                        className="px-6 py-2 text-lg rounded-md bg-green-500 text-white font-bold"
                    >
                        Next
                    </button>
                </div>

                {/* Text Content Section */}
                <div
                    className="w-full md:w-2/3 text-gray-800 font-gillSans md:pl-4 lg:pl-6 text-base md:text-lg lg:text-xl leading-relaxed max-w-md md:max-w-none">
                    <p className="mb-4 md:mb-6">
                        Hi, remember me! My name is Sailor Polly. I am one of Captain Charlie’s Friends. Captain Charlie
                        has set me a task to tell you about using
                        <span className="font-semibold text-[#6BBF59]"> Tummy Breathing to help you feel better when you have pain.</span>
                    </p>
                    <p className="mb-4 md:mb-6">
                        Sometimes my <span className="font-semibold text-[#6BBF59]">tummy feels very full and can hurt a lot.</span> Especially
                        when I haven't made a poo in a few days. I find it hard to poo sometimes. I don’t like having a
                        pain in my tummy. So, to help me feel better, <span className="font-semibold text-[#6BBF59]">I take some deep breaths</span> with
                        my mum.
                    </p>
                    <p className="mb-4 md:mb-6">
                        I take <span className="font-semibold text-[#6BBF59]">big deep breaths in through my nose for 4 seconds and then let them out through my mouth for 7 seconds.</span> I
                        do this 3 times with my mum. <span className="font-semibold text-[#6BBF59]">Afterwards my pain doesn’t hurt so much.</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TummyBreathingWithPolly;
