import React, { useState, useEffect } from "react";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import cptCharlie from "../../assets/cptCharlie.png";
import mateyMorgan from "../../assets/Characters/MateyMorgan.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MorganQuiz = ({ carrier, onTryAgain, handleSpeech, submitted, setSubmitted }) => {
  const [feedback, setFeedback] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [quizCompleted, setQuizCompleted] = useState(false);

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  const handleImageClick = (index) => {
    if (index >= 2 && index <= 5) {
      // If correct answer
      setFeedback("Correct! Matey Morgan is feeling some level of pain.");
      setIsCorrect(true);
      setQuizCompleted(true);
      audioPlay();
      sessionStorage.setItem("morganQuizCompleted", "true");
    } else {
      // If incorrect answer
      setFeedback("Incorrect. Please try again.");
      setIsCorrect(false);
      setQuizCompleted(true);
    }
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted) {
      if (isCorrect) {
        handleSpeech(27.1);  // Play correct answer audio when correct
      } else {
        handleSpeech(31.2);  // Play incorrect answer audio when incorrect
      }
    }
  }, [submitted, isCorrect, handleSpeech]);

  // Correct Answer Feedback
  const correctFeedback = (
    <div className="flex flex-col items-center bounceIn font-gillSans">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-green-600 md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
          Correct Answer!
        </h2>
        <img src={coin} className="w-12 ml-20 heartbeat" alt="coin" />
      </div>
      <div className="flex text-base flex-row mt-10">
        <img src={cptCharlie} className="w-56" alt="Captain" />
        <ol className="lg:text-xl mt-3">
          <li>Excellent Job Powder Monkey {carrier?.firstName}!</li>
          <li className="mt-3">That's correct!</li>
        </ol>
      </div>
    </div>
  );

  // Incorrect Answer Feedback
  const incorrectFeedback = (
    <div className="flex flex-col shake font-gillSans">
      <h2 className="text-3xl mt-5 text-center text-red-600">
        Incorrect Answer!
      </h2>
      <div className="flex text-base flex-row mt-10">
        <img src={cptCharlie} className="w-56" alt="Captain" />
        <div className="flex flex-col">
          <ol className="lg:text-xl mt-3">
            <li>Let's Try Again.</li>
            <li className="mt-3">
              Click into the picture of Matey Morgan to hear how he is feeling
            </li>
            <li className="mt-3 ml-20">
              <img
                src={mateyMorgan}
                className="w-20 cursor-pointer"
                onClick={onTryAgain}
                alt="Matey Morgan"
              />
            </li>
            <li className="mt-3">
              Remember if you need help, click the help button at the top of the
              page.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      {/* Quiz Section */}
      {!quizCompleted && (
        <>
          <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
            Matey Morgan
          </h2>
          <div className="text-gray-700 text-base font-gillSans">
            <ol className="lg:text-xl mt-5">
              <li className="mt-1">How is Matey Morgan feeling?</li>
              <li className="mt-1">
                Select the face below to show how Matey Morgan is feeling.
              </li>
              <div className="flex mt-10 justify-around items-center fadeIn gap-5 max-w-3xl">
                <div className="flex items-center flex-col">
                  <img
                    src={painScale0}
                    alt="pain-scale"
                    className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                    onClick={() => handleImageClick(0)}
                  />
                  <span className="mt-2 text-center font-gillSans">
                    0 <br />
                    No hurt
                  </span>
                </div>
                <div className="flex items-center flex-col">
                  <img
                    src={painScale2}
                    alt="pain-scale"
                    className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                    onClick={() => handleImageClick(1)}
                  />
                  <span className="mt-2 text-center font-gillSans">
                    2 <br />
                    Hurts little bit
                  </span>
                </div>
                <div className="flex items-center flex-col">
                  <img
                    src={painScale4}
                    alt="pain-scale"
                    className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                    onClick={() => handleImageClick(2)}
                  />
                  <span className="mt-2 text-center font-gillSans">
                    4 <br />
                    Hurts little more
                  </span>
                </div>
                <div className="flex items-center flex-col">
                  <img
                    src={painScale6}
                    alt="pain-scale"
                    className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                    onClick={() => handleImageClick(3)}
                  />
                  <span className="mt-2 text-center font-gillSans">
                    6 <br />
                    Hurts even more
                  </span>
                </div>
                <div className="flex items-center flex-col">
                  <img
                    src={painScale8}
                    alt="pain-scale"
                    className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                    onClick={() => handleImageClick(4)}
                  />
                  <span className="mt-2 text-center font-gillSans">
                    8 <br />
                    Hurts whole lot
                  </span>
                </div>
                <div className="flex items-center flex-col">
                  <img
                    src={painScale10}
                    alt="pain-scale"
                    className="hover:scale-110 transform transition duration-300 ease-in-out cursor-pointer"
                    onClick={() => handleImageClick(5)}
                  />
                  <span className="mt-2 text-center font-gillSans">
                    10 <br />
                    Hurts worst
                  </span>
                </div>
              </div>
            </ol>
          </div>
        </>
      )}

      {/* Feedback Section */}
      {quizCompleted && <>{isCorrect ? correctFeedback : incorrectFeedback}</>}
      <ToastContainer />
    </div>
  );
};

export default MorganQuiz;
