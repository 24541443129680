import React from "react";
import MissionComplete from "../MissionComplete";

const Mission5Complete = ({ carrier }) => {
  const completedMissionId = 6;
  const rank = "Gunner";
  const coins = 24;
  const userId = carrier.userId;
  const currentMissionId = 7;
  const currentMissionPage = 53;
 
  return (
    <>
      <MissionComplete
        completedMissionId={carrier.completedMissionId > 6 ? carrier.completedMissionId : completedMissionId}
        rank={rank}
        userId={userId}
        coins={coins}
        currentMissionId={carrier.currentMissionId > 7 ? carrier.currentMissionId : currentMissionId}
        currentMissionPage={currentMissionPage}      
      />
    </>
  );
};

export default Mission5Complete;
