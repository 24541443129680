import React from "react";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
import cptCharlie from "../../assets/cptCharlie.png";

const Impressive = ({ carrier }) => {
  
  return (
      <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28">
        <h2 className="text-4xl mt-10 font-bold mb-2 text-center font-gillSans flex items-center justify-center">
          Excellent Job!
          <img src={coin} className="w-12 ml-4 heartbeat" alt="coin" />
        </h2>
        <div className="flex items-start space-x-6">
          <div className="flex-shrink-0 w-1/3">
            {/* Image of captain Charlie */}
            <img src={cptCharlie} alt="cptCharlie" className="w-12/12 mt-10"/>
          </div>

          {/* Story content */}
          <div className="flex-grow text-left text-gray-700 font-gillSans text-xl mt-5">
            <p className="mt-6">
              <h4  className= "text-xl mb-2 text-center font-gillSans flex items-center justify-center">
                Excellent Gunner {carrier?.firstName}!
              </h4>
            </p>
            <p className="mt-6">
              <h4 className= "text-xl  mb-2 text-center font-gillSans flex items-center justify-center">
                Well done on practicing your tummy breathing!
              </h4>
            </p>

            <p className="mt-6">
              <h4 className= "text-xl  mb-2 text-center font-gillSans flex items-center justify-center">
                Keep up the good work.
              </h4>
            </p>
          </div>
        </div>     
      </div>
  );
};

export default Impressive;
