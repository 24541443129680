import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import homeButton from "../../../assets/homeBtn.png";
import sectionsBG from "../../../assets/sectionsBG.png";
import speaker from "../../../assets/speaker.png";
import ProgressBar from "../../ProgressBar";
import End from "./End";
import Hurt from "./Hurt";
import Intro from "./Intro";
import PainFace from "./PainFace";
import WhatIDid from "./WhatIDid";
import pg4 from "../../../assets/audios/speech/PainDiary/pg4.mp3";
import pg5 from "../../../assets/audios/speech/PainDiary/pg5.mp3";
import pg6 from "../../../assets/audios/speech/PainDiary/pg6.mp3";


const PainDiary = () => {
  const [section, setSection] = useState(1);
  const audioRef = useRef(null);
  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  );
  const navigate = useNavigate();
  const location = useLocation();
  const carrier = location.state?.carrier;
  const currentMissionId = carrier.currentMissionId;

  // Define allowed sections based on currentMissionId
  const getAllowedSections = () => {
    if (currentMissionId === 1 || currentMissionId === 2) {
      return [1, 2, 5];
    } else if (currentMissionId >= 3 && currentMissionId <= 7) {
      return [1, 2, 3, 5];
    } else if (currentMissionId >= 7 && currentMissionId <= 12) {
      return [1, 2, 3, 4, 5];
    }
    return []; 
  };

  const allowedSections = getAllowedSections();
  const isLastPage = section === allowedSections[allowedSections.length - 1];
  const isPreviousDisabled = section === allowedSections[0];
  const totalSections = allowedSections.length;

  const handlePrevious = () => {
    const currentIndex = allowedSections.indexOf(section);
    if (currentIndex > 0) setSection(allowedSections[currentIndex - 1]);
  };

  const handleNext = () => {
    const currentIndex = allowedSections.indexOf(section);
    if (currentIndex < allowedSections.length - 1) {
      setSection(allowedSections[currentIndex + 1]);
    } else {
      navigate("/home", { state: { carrier } });
    }
  };

  const handleHomeClick = () => {
    navigate(-1);
  };
  const handleSpeech = (subsection = section) => {
    const sectionAudioMap = {      
      3: pg4,
      4: pg5,
      5: pg6,      
    };
    const audioFile = sectionAudioMap[subsection];
    if (audioFile) {      
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      
      audioRef.current = new Audio(audioFile);
      audioRef.current.play();
    }
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6] fadeIn">
      <div className="flex flex-row items-center justify-between w-full">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer z-10 w-9 hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <div className="pl-56">
          <ProgressBar
            currentSection={allowedSections.indexOf(section) + 1}
            totalSections={totalSections}
          />
        </div>
        <img
          src={speaker}
          alt="speaker"
          className="hover:scale-110 ease-in-out transition duration-300"
          onClick={() => {
            handleSpeech();
          }}
        />
      </div>
      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {section === 1 && <Intro />}
            {section === 2 && <Hurt />}
            {section === 3 && <PainFace />}
            {section === 4 && <WhatIDid />}
            {section === 5 && <End />}
          </div>
        </div>
      </div>
      <div className="flex justify-around">
        <button
          className="px-12 py-4 rounded-full font-bold text-white tracking-widest uppercase transform transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            color: "#ffffff",
          }}
        >
          <svg
            className="w-10 h-10 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        <button
          className="px-12 py-4 rounded-full font-bold text-white tracking-widest uppercase transform transition-colors duration-200"
          onClick={handleNext}
          style={{
            backgroundColor: "#3FA7D6",
          }}
        >
          <svg
            className="w-10 h-10"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default PainDiary;
