import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UnderWater from "../../../assets/UnderWater.jpg";
import PainDiaryDash from "./PainDiaryDash";
import StarsRecordDash from "./StarsRecordDash";

const AdminDashboard = () => {
  const [Pain, setPain] = useState(false);
  const [Star, setStar] = useState(false);
  const navigate = useNavigate();

  const handlePain = () => {
    setPain(true);
    setStar(false);
  };

  const handleStar = () => {
    setStar(true);
    setPain(false);
  };

  const signUpHandler = () => {
    navigate("/sign-up");
  };

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <div
      className="h-screen flex flex-col items-center justify-center relative"
      style={{
        backgroundImage: `url(${UnderWater})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#2cdee2",
      }}
    >
      {/* Back Button */}
      <button
        onClick={backHandler}
        className="absolute top-5 left-5 px-4 py-2 text-white bg-gray-700 hover:bg-gray-800 rounded-md shadow-md transition-all duration-200"
      >
        ← Back to Home
      </button>      

      {/* Dashboard Title */}
      <h1 className="text-4xl font-bold text-white mb-8 text-center drop-shadow-lg">
        Admin Dashboard
      </h1>

      {/* Button Group */}
      <div className="flex gap-4 mb-8">
        <button
          className={`px-6 py-3 rounded-lg font-semibold text-white ${
            Pain ? "bg-yellow-500" : "bg-gray-400"
          } hover:bg-yellow-400 transition-all duration-300`}
          onClick={handlePain}
        >
          Pain Diary
        </button>
        <button
          className={`px-6 py-3 rounded-lg font-semibold text-white ${
            Star ? "bg-yellow-500" : "bg-gray-400"
          } hover:bg-yellow-400 transition-all duration-300`}
          onClick={handleStar}
        >
          Star Dashboard
        </button>
        <button
          className="px-6 py-3 rounded-lg font-semibold text-white bg-green-500 hover:bg-green-600 transition-all duration-300"
          onClick={signUpHandler}
        >
          Sign Up
        </button>
      </div>

      {/* Content Section */}
      <div className="w-full max-w-4xl bg-white bg-opacity-80 rounded-lg shadow-lg p-8">
        {Pain ? <PainDiaryDash /> : <StarsRecordDash />}
      </div>
    </div>
  );
};

export default AdminDashboard;
