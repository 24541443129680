import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import homeButton from "../../../assets/homeBtn.png";
import sectionsBG from "../../../assets/sectionsBG.png";
import ProgressBar from "../../ProgressBar";
import AboutFB from "./AboutFB";
import Benefits from "./Benefits";
import ChronicPain from "./ChronicPain";
import Contact from "./Contact";
import CptCharlie from "./CptCharlie";
import FBASD from "./FBASD";
import GettingStarted from "./GettingStarted";
import ImpactCP from "./ImpactCP";
import Support from "./Support";
import Team from "./Team";
import Welcome from "./Welcome";
import WhatIsPain from "./WhatIsPain";

const Parents = () => {
  const [section, setSection] = useState(1);
  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  ); // Initialize with session storage value
  const navigate = useNavigate();
  const location = useLocation();
  const carrier = location.state?.carrier;
  const [counter, setCounter] = useState(1);

  const handlePrevious = () => {
    if (section > 1) setSection(section - 1);
  };

  const handleNext = () => {
    if (isLastPage) {
        navigate("/home", { state: { carrier } });
    } else {
        setSection(section + 1);
    }
    
  };

  const isPreviousDisabled = section === 1;
  const isLastPage = section === 12;

  const totalSections = 12;

  const handleHomeClick = () => {
    navigate(-1);
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6] fadeIn">
      <div className="flex flex-row gap-5 items-center -mb-9 z-10">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer z-10 w-9 hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <div className="pl-56">
          <ProgressBar currentSection={section} totalSections={totalSections} />
        </div>
      </div>
      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {section === 1 && <Welcome />}
            {section === 2 && <Team />}
            {section === 3 && <AboutFB />}
            {section === 4 && <WhatIsPain />}
            {section === 5 && <ChronicPain />}
            {section === 6 && <ImpactCP />}
            {section === 7 && <FBASD />}
            {section === 8 && <Benefits />}
            {section === 9 && <CptCharlie />}
            {section === 10 && <Support />}
            {section === 11 && <Contact />}
            {section === 12 && <GettingStarted />}
          </div>
        </div>
      </div>
      <div className="flex justify-around">
        <button
          className="px-12 py-4 rounded-full font-bold text-white tracking-widest uppercase transform transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            backgroundColor: isPreviousDisabled ? "#1e1e1e" : "#3FA7D6",
            color: isPreviousDisabled ? "#000000" : "#ffffff",
          }}
        >
          <svg
            className="w-5 h-5 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        <button
          className="px-12 py-4 rounded-full font-bold text-white tracking-widest uppercase transform transition-colors duration-200"
          onClick={handleNext}          
          style={{
            backgroundColor: isLastPage ? "#1e1e1e" : "#3FA7D6",
          }}
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Parents;
