import React, { useEffect } from "react";
import Captain from "../../assets/cptCharlie.png";
import drRiley from "../../assets/Characters/Riley.png";
import pg14 from "../../assets/audios/speech/Mission7/pg14.mp3";
const TryAgain = ({
  onTryAgain,
  setSubmitted,
  setSelectedIndex,
  setIsCorrect,
}) => {
  useEffect(() => {
    const tryAgainAudio = new Audio(pg14);
    tryAgainAudio.play();

    // Clean up the timeout and audio when the component unmounts
    return () => {
      clearTimeout(tryAgainAudio);
      tryAgainAudio.pause();
      tryAgainAudio.currentTime = 0;
    };
  }, []);
  const handleTryAgain = () => {
    setSubmitted(true);
    setSelectedIndex(null);
    setIsCorrect(null);
    onTryAgain();
  };

  return (
    <div className="w-full h-full max-w-4xl mx-auto bg-transparent md:w-[80%] lg:w-[70%] lg:h-auto xl:h-auto xl:pr-7 fadeIn p-4">
      <h2 className="text-xl font-bold mb-2 text-center md:text-2xl md:mt-2 lg:text-3xl font-gillSans">
        Incorrect Answer!
      </h2>
      <div className="flex flex-col md:flex-row justify-evenly items-center gap-4">
        <div className="flex-shrink-0">
          <img
            src={Captain}
            className="w-40 md:w-52 lg:w-60 h-auto"
            alt="Captain"
          />
        </div>
        <div className="text-gray-700 text-base lg:text-lg font-gillSans flex flex-col items-center md:items-start max-w-sm">
          <p className="text-center md:text-left">Let’s Try Again.</p>
          <p className="mt-4 text-center md:text-left">
            Let's find out why doctors are super important. Click on Doctor
            Riley to find out.
          </p>
          <img
            src={drRiley}
            alt="Dr. Riley"
            onClick={handleTryAgain}
            className="w-36 md:w-44 lg:w-48 h-auto mt-4 cursor-pointer"
          />
          <p className="mt-4 text-center md:text-left">
            Remember if you need help, click the help button at the top of the
            page.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TryAgain;
