import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import greenTick from "../../assets/green-tick.png";
import redCross from "../../assets/red-cross.png";
import Correct1 from "./Correct1";
import TryAgain2 from "./TryAgain2";

const TaskOnTummyBreathing = ({
  carrier,
  onTryAgain,
  setSubmitted,
  handleSpeech,
  setIsOnChildComponent,
  audioRef,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(1); // Track current question
  const responses = useRef({});
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const questionRef = useRef(null);

  const userId = carrier?.userId;

  const questions = [
    "What is Tummy Breathing?",
    "Why is Tummy Breathing important?",
  ];

  const options = [
    [
      "Breathing with your tummy",
      "Breathing with your hands",
      "Breathing with your toes",
    ],
    [
      "Makes you run faster",
      "Helps you feel better when you have pain",
      "Makes you jump higher",
    ],
  ];

  const correctAnswers = [0, 1]; // Indexes for the correct answers: 'a' for the first question, 'b' for the second

  // Handle zoom-in effect when the current question changes
  useEffect(() => {
    const questionElement = questionRef.current;
    if (questionElement) {
      questionElement.classList.remove("zoom-in");
      void questionElement.offsetWidth;
      questionElement.classList.add("zoom-in");
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (currentQuestion === 1) {
      handleSpeech(26.1);
    } else if (currentQuestion === 2) {
      handleSpeech(26.2);
    }

    setIsOnChildComponent(currentQuestion === 1);
  }, [currentQuestion, setIsOnChildComponent]);

  const handleOptionClick = async (index) => {
    setSelectedOption(index);
    responses.current[`Q${currentQuestion}`] =
      options[currentQuestion - 1][index];

    if (index === correctAnswers[currentQuestion - 1]) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }

    if (currentQuestion < questions.length) {
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedOption(null);
      }, 1000);
    } else {
      setTimeout(async () => {
        setIsQuizCompleted(true);
        await saveActivity();
      }, 1000);
    }
  };

  const saveActivity = async () => {
    const payload = [
      {
        userId: carrier.userId,
        missionId: "M7",
        pageNum: 26,
        activityId: "M7TaskOnTummyBreathing",
        activityValue: JSON.stringify(responses.current),
      },
    ];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      toast.success("Activity saved successfully!");
    } catch (error) {
      toast.error("Error saving activity!");
    }
  };

  if (isQuizCompleted) {
    if (isQuizCompleted && isCorrect) {
      sessionStorage.setItem("TaskOnTummyBreathingCompleted", true);
      // handleSpeech(26.1);
    } else if (isQuizCompleted && !isCorrect) {
      // handleSpeech(26.2);
    }
    return isCorrect ? (
      <Correct1 carrier={carrier} audioRef={audioRef} />
    ) : (
      <TryAgain2
        onTryAgain={onTryAgain}
        setSubmitted={setSubmitted}
        setIsCorrect={setIsCorrect}
      />
    );
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
      <div className="flex flex-row items-center justify-center gap-2">
        <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
          Task
        </h2>
      </div>

      <div className="mb-10">
        <style>
          {`
            @keyframes zoomIn {
              0% {
                transform: scale(0.5);
                opacity: 0;
              }
              100% {
                transform: scale(1);
                opacity: 1;
              }
            }
            .zoom-in {
              animation: zoomIn 0.5s ease-out;
            }
            .hover-highlight:hover {
              background-color: rgba(107, 191, 89, 0.2);
            }
          `}
        </style>
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl text-center md:text-base lg:text-xl font-gillSans">
            Excellent work learning all about tummy breathing. Keep up the good
            work! There is a special badge waiting for you at the end of this
            mission.
          </p>
          <p className="text-xl text-center md:text-base lg:text-xl font-gillSans">
            Captain Charlie has two questions for you. Can you answer these two
            questions?
          </p>
        </div>
        <div
          className="mt-10 text-xl font-bold mb-5 text-center"
          ref={questionRef}
        >
          {currentQuestion}. {questions[currentQuestion - 1]}
        </div>

        {currentQuestion <= questions.length && (
          <div className="flex flex-col items-center gap-2">
            {options[currentQuestion - 1].map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionClick(index)}
                className="text-center w-full p-2 rounded-md text-lg transition-colors hover-highlight"
              >
                {String.fromCharCode(97 + index)}. {option}
                {selectedOption === index && (
                  <img
                    src={
                      index === correctAnswers[currentQuestion - 1]
                        ? greenTick
                        : redCross
                    }
                    alt={
                      index === correctAnswers[currentQuestion - 1]
                        ? "Correct"
                        : "Incorrect"
                    }
                    className="w-6 h-6 inline-block ml-2"
                  />
                )}
              </button>
            ))}
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default TaskOnTummyBreathing;
