import React, { useState, useEffect } from "react";
import cptCharlie from "../../assets/cptCharlie.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";

const SpecialSkills = ({ onNext, carrier }) => {
    // State to track if the user is ready to start
    const [ready, setReady] = useState(false);

    // Function to handle click on the start button
    const handleStart = () => {
        setReady(true);
        onNext();
    };
    const audioPlay = () => {
        const audio = new Audio(coins);
        audio.play();
    };
    useEffect(() => {
        audioPlay();
    });

    return (
        <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-10">
            <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
                Special Skills
                <img src={coin} className="w-12 ml-2 heartbeat" alt="coin" />
            </h2>
            <div className="text-gray-700 text-base flex flex-row font-gillSans">
                <div>
                    <p className="mt-4 lg:text-xl">
                        Gunner {carrier?.firstName}, over the next three missions,
                        you are going to learn about <strong>6 special skills</strong> that you can do to feel better when you are in pain.

                    </p>
                    <p className="mt-4 lg:text-xl">
                        Each mission, Captain Charlie will teach you a special skill.
                    </p>
                    {/* Render different content based on whether the user is ready or not */}
                    {ready ? (
                        <p className="mt-4 lg:text-xl">Your mission has begun!</p>
                    ) : (
                        <button
                            onClick={handleStart}
                            className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-[#6BBF59] transition duration-300 ease-out border-2 border-black rounded-full shadow-md group mt-8 ml-20"
                        >
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#3FA7D6] group-hover:translate-x-0 ease">
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
                            <span className="absolute flex items-center justify-center w-full h-full text-gray-700 transition-all duration-300 transform group-hover:translate-x-full ease">
                Yes, I am ready to start!
              </span>
                            <span className="relative invisible">
                Yes, I am ready to start!
              </span>
                        </button>
                    )}
                </div>
                <div>
                    <img src={cptCharlie} alt="cptCharlie" className=" w-9/12 mt-10" />
                </div>
            </div>
        </div>
    );
};

export default SpecialSkills;
