import React, { useEffect } from "react";
import coinIcon from "../../assets/Icons/Coin.png"; 
import deepPressureVideo from "../../assets/videos/Deep Pressure part 1.mp4"; 
import coins from "../../assets/audios/coins.mp3";

const DeepPressureVideo = ({ onNext }) => {
    useEffect(() => {
        const audio = new Audio(coins);
        audio.play();
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, []); 
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-6">
          Deep Pressure
          <img
            src={coinIcon}
            alt="coin"
            className="w-12 h-12 ml-4 heartbeat"
          />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col items-center mt-8">
        <p className="text-lg md:text-xl text-center">
          Click on the video to hear Annie talk about using deep pressure when
          her leg hurts.
        </p>

        {/* Video Section */}
        <div className="mt-6 w-full md:w-2/3 lg:w-1/2">
          <video
            controls
            className="w-full rounded-md shadow-lg"
            src={deepPressureVideo}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default DeepPressureVideo;
