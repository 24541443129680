import React from "react";
import weekMission from "../../assets/mission path/FOATM.png";
import recap from "../../assets/mission path/ROLM.png";
import TTD from "../../assets/mission path/TTD.png";
import copingWithPain from "../../assets/mission path/CWP.png";
import pirateQuiz from "../../assets/mission path/PQ.png";
import finished from "../../assets/mission path/F.png";
import greenTick from "../../assets/tick.png";

const MissionPath = () => {
    const missions = [
        { src: weekMission, isCompleted: false, isCurrent: true },
        { src: recap, isCompleted: false },
        { src: copingWithPain, isCompleted: false },
        { src: TTD, isCompleted: false },
        { src: pirateQuiz, isCompleted: false },
        { src: finished, isCompleted: false },
    ];

    return (
        <div className="w-full max-w-4xl h-auto p-4 bg-transparent mx-auto overflow-hidden">
            <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl lg:text-4xl font-gillSans">
                Mission 8
            </h2>
            <ol className="lg:text-xl mt-3 text-gray-700 text-base font-gillSans mx-auto max-w-xl">
                <li className="mt-3">
                    On today's mission you will have 5 tasks to complete. Once these are
                    completed you will be finished this mission.
                </li>
                <li className="mt-3">
                    At the end of the mission, you will receive a special surprise from
                    Captain Charlie.
                </li>
                <li className="font-semibold mt-3">
                    Click on the arrow to find out about this mission.
                </li>
            </ol>
            <div className="text-gray-700 text-base font-gillSans">
                <div className="flex flex-row flex-wrap justify-center items-center gap-4 mt-8 mx-auto w-full max-w-3xl px-4 lg:px-6">
                    {/* Missions Map */}
                    {missions.map((mission, index) => (
                        <div
                            key={index}
                            className="relative flex justify-center items-center w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24"
                        >
                            <img
                                src={mission.src}
                                alt="missionMap"
                                className={`w-full h-full rounded border-black object-contain ${
                                    mission.isCompleted ? "opacity-50 grayscale" : ""
                                } ${mission.isCurrent ? "floater" : ""}`}
                            />
                            {mission.isCompleted && (
                                <img
                                    src={greenTick}
                                    alt="completed"
                                    className="absolute top-0 right-0 w-4 h-4"
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MissionPath;
