import React, { useEffect } from "react";
import captainCharlie from "../../assets/cptCharlie.png"; 
import coinIcon from "../../assets/Icons/Coin.png"; 
import coins from "../../assets/audios/coins.mp3";
const SpecialSkills = ({ carrier }) => {
  useEffect(() => {
    const audio = new Audio(coins);    
    
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn">
      {/* Title Section */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl md:text-4xl font-bold ml-10 text-[#6BBF59] flex items-center gap-2">Special Skills
          <img src={coinIcon} alt="coin icon" className="w-14 h-12 heartbeat" />
        </h2>        
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center md:items-start justify-between">
        {/* Left: Image */}
        <div className="w-full md:w-1/2 flex justify-center mb-4 md:mb-0">
          <img
            src={captainCharlie}
            alt="Boatswain"
            className="w-48 md:w-64 lg:w-72 h-auto rounded-md"
          />
        </div>

        {/* Right: Text Content */}
        <div className="w-full md:w-1/2 text-xl space-y-4 mr-10">
          <p>
            Remember Boatswain <span className="font-bold">{carrier.firstName}</span> over the next 2 missions, you are going to learn about{" "}
            <span className="text-[#6BBF59] font-bold">special skills</span> that you can use to help you feel better
            when you have pain.
          </p>
          <p>Captain Charlie will teach you 6 special skills.</p>
        </div>
      </div>
    </div>
  );
};

export default SpecialSkills;
