import React, { useEffect } from "react";
import mateyMorgan from "../../assets/Characters/MateyMorgan.png";
import pg39 from "../../assets/audios/speech/Mission7/pg39.mp3";

const MateyMorganStory = ({ onPlay, audioRef }) => {
  useEffect(() => {

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    const speech = new Audio(pg39);
    speech.play();

    return () => {
      speech.pause();
      speech.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-full max-w-3xl mx-auto p-4 md:p-4 flex flex-col md:flex-row items-center">
      {/* Left Side: Text and Play Button */}
      <div className="md:w-1/2 text-left md:pr-4">
        <h1 className="text-2xl md:text-3xl font-bold mb-3 text-black">
          Matey Morgan
        </h1>
        <p className="text-base md:text-lg mb-4">
          Have a listen to Matey Morgan talk about how tummy breathing helps him
          cope with his pain.
        </p>
        <div className="flex items-center">
          <button
            onClick={onPlay}
            className="bg-green-500 text-white px-4 py-2 text-base md:text-lg font-bold rounded-md shadow-lg hover:bg-green-600 transition duration-200"
          >
            PLAY
          </button>
        </div>
      </div>

      {/* Right Side: Image */}
      <div className="md:w-1/2 mt-4 md:mt-0 flex justify-center">
        <img
          src={mateyMorgan}
          alt="Matey Morgan"
          className="w-2/3 h-auto rounded-lg"
        />
      </div>
    </div>
  );
};

export default MateyMorganStory;
