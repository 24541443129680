import React from "react";
import coinIcon from "../../assets/Icons/Coin.png";
import treasureChest from "../../assets/miscellaneous/treasurebox.png"; 
import deepPressureImage from "../../assets/pain diary/Deep Pressure.png";

const SpecialSkillDeepPressure = ({ onTreasureClick }) => {
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-4">
          Your Special Skill
          <img
            src={coinIcon}
            alt="coin"
            className="w-12 h-12 ml-4 heartbeat"
          />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full mt-8">
        {/* Left: Treasure Chest */}
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
          <img
            src={treasureChest}
            alt="Treasure Chest"
            className="w-48 md:w-64 lg:w-72 h-auto cursor-pointer"
            onClick={onTreasureClick}
          />
          <p className="text-lg md:text-xl mt-4 text-center">
            Click the treasure chest to find out what the last special skill for today is!
          </p>
        </div>

        {/* Right: Deep Pressure */}
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
          <h3 className="text-lg md:text-xl font-bold mb-4">Deep Pressure</h3>
          <img
            src={deepPressureImage}
            alt="Deep Pressure"
            className="w-40 md:w-56 lg:w-64 h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default SpecialSkillDeepPressure;
