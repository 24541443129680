import React, { useState, useEffect } from "react";
import axios from "axios";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import Correct2 from "./Correct2";
import TryAgain2 from "./TryAgain2";
import pg44Q2 from "../../assets/audios/speech/Mission6/pg44Q2.mp3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DrRileyNeedsHelp2 = ({ carrier, setSection }) => {  
  const [isCorrect, setIsCorrect] = useState(null); // Track if answer is correct or incorrect
  const [showHint, setShowHint] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {    
    const audio = new Audio(pg44Q2);
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  const placeholders = [
    { src: painScale0, label: "0\nNo hurt" },
    { src: painScale2, label: "2\nHurts little bit" },
    { src: painScale4, label: "4\nHurts little more" },
    { src: painScale6, label: "6\nHurts even more" },
    { src: painScale8, label: "8\nHurts whole lot" },
    { src: painScale10, label: "10\nHurts worst" },
  ];

  const handleImageClick = async (index) => {
    setShowHint(false);
    if (index >= 0 && index <= 5) {
      setSelectedIndex(index);
      setClickedIndex(index);
      if (index >= 3) {
        // Correct answer (last 3 faces: index 3, 4, 5)
        setIsCorrect(true); // Set correct        
      } else {
        // Incorrect answer (first 3 faces: index 0, 1, 2)
        setIsCorrect(false); // Set incorrect
      }
      setShowFeedback(true);
      sessionStorage.setItem("DrRileyNeedsHelp2Completed", "true");

      // Hit the API to save activity details
      let userId = carrier.userId;
      const trimmedLabel = `wong face: ${placeholders[index].label.split("\n")[1]}`;      
      try {
        const payload = [
          {
            userId: userId, 
            missionId: "M6", 
            pageNum: 44, 
            activityId: "M6DrRileyNeedsHelpPainScale", 
            activityValue: trimmedLabel,
          },
        ];
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
          payload
        );
        console.log("payload:", payload);
      } catch (error) {
        console.error("Error saving mission activity:", error);
      }
    } else {
      console.error(
        "Index out of range. Please provide an index between 0 and 5."
      );
      return;
    }
  };

  const resetQuiz = () => {
    // Resets the quiz state for a new attempt
    setSelectedIndex(null);
    setIsCorrect(null);
    setShowFeedback(false);
    setShowHint(false);
    setClickedIndex(null);    
    setSection(29);
  };

   // If the task is completed, show the Correct2 or TryAgain2 component after 2 seconds
   if (showFeedback) {
    return isCorrect ? <Correct2 carrier={carrier}/> : <TryAgain2 onTryAgain={resetQuiz} />;
  }

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <h2 className="text-xl font-bold mb-4 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Your Turn
      </h2>
      <div className="text-gray-700 text-base font-gillSans">
        <ol className="text-xl">
          <li className="mt-1">
            Q2. How is Annie Feeling?
          </li>          
          <div className="flex mt-10 justify-around items-center fadeIn gap-5 max-w-3xl">
            <div className="flex items-center flex-col">
              <img
                src={painScale0}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 0
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(0)}
              />
              <span className="mt-2 text-center font-gillSans">
                0 <br />
                No hurt
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale2}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 1
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(1)}
              />
              <span className="mt-2 text-center font-gillSans">
                2 <br />
                Hurts little bit
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale4}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 2
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(2)}
              />
              <span className="mt-2 text-center font-gillSans">
                4 <br />
                Hurts little more
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale6}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 3
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(3)}
              />
              <span className="mt-2 text-center font-gillSans">
                6 <br />
                Hurts even more
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale8}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 4
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(4)}
              />
              <span className="mt-2 text-center font-gillSans">
                8 <br />
                Hurts whole lot
              </span>
            </div>
            <div className="flex items-center flex-col">
              <img
                src={painScale10}
                alt="pain-scale"
                className={`transform transition duration-300 ease-in-out cursor-pointer ${
                  selectedIndex === 5
                    ? "scale-110 border-4 rounded-full border-green-500"
                    : "hover:scale-110"
                }`}
                onClick={() => handleImageClick(5)}
              />
              <span className="mt-2 text-center font-gillSans">
                10 <br />
                Hurts worst
              </span>
            </div>
          </div>
        </ol>
      </div>
      {/* Hint button */}
      <div className="text-center mt-4">
        <button
          className="lg:text-lg px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-400"
          onClick={() => setShowHint(!showHint)}
        >
          Hint
        </button>
      </div>

      {/* Conditionally rendered hint */}
      {showHint && (
        <div className="lg:text-xl mt-4 text-center text-blue-600 font-gillSans">
          Select orange face onwards
        </div>
      )}      
    </div>
  );
};

export default DrRileyNeedsHelp2;
