import React, { useEffect } from "react";
import Captain from "../../assets/cptCharlie.png";
import tummyBreathing from "../../assets/videos/2. Tummy Breathing_F.mp4";
import pg15 from "../../assets/audios/speech/Mission8/pg15.mp3";

const IncorrectBBQuiz = ({ onTryAgain }) => {
    useEffect(() => {
        const correctAudio = new Audio(pg15);
        const playAudio = setTimeout(() => {
          correctAudio.play();
        }, 2000);
          
        return () => {
          clearTimeout(playAudio);
          correctAudio.pause();
          correctAudio.currentTime = 0;
        };
      }, []);
      
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-20">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Incorrect Answer!
      </h2>
      <div className="flex justify-evenly items-center">
        <img src={Captain} className="w-72" alt="Captain" />
        <div className="text-gray-700 text-base lg:text-xl font-gillSans">
          <p className="ml-4">Let’s Try Again.</p>
          <p className="mt-2 ml-4">
            Let’s watch the video again to find out why tummy breathing is super
            important when you have pain.
          </p>
          <div className="flex items-center justify-center mt-4">
            <video
              src={tummyBreathing}
              controls
              className="w-3/4 h-auto mb-4"
              loop={false}
            ></video>
          </div>
          <p className="mt-2 ml-4">
            Remember if you need help. Click the help button at the top of the
            page.
          </p>
          <button
            className="bg-[#6BBF59] text-white px-4 py-2 rounded-md mt-2"
            onClick={onTryAgain}
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default IncorrectBBQuiz;
