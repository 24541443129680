import React, { useEffect, useRef } from "react";
import okface from "../../assets/pain scale/0.png";
import pg33 from "../../assets/audios/speech/Mission7/pg33.mp3";
const DistractYourMind = ({ onNext }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    // Automatically play the audio when the component loads
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []);
  useEffect(() => {
    const speech = new Audio(pg33);
    speech.play();

    return () => {
      speech.pause();
      speech.currentTime = 0;
    };
  }, []);

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn -mt-28">
      <h2 className="text-xl mt-12 font-bold mb-2 text-center md:text-2xl md:mt-10 lg:text-4xl font-gillSans flex items-center justify-center">
        Distracts your Mind
      </h2>
      <div className="flex items-start space-x-6">
        <div className="flex-shrink-0 w-1/3 flex flex-col items-center">
          {/* Image of captain Charlie */}
          <img src={okface} alt="okface" className="w-10/12 mt-10" />
          <span className="mt-2 text-center text-2xl"> 0 <br />
            No hurt
          </span>
        </div>

        {/* Story content */}
        <div className="flex-grow text-left text-gray-700 font-gillSans text-xl mt-5">
          <p className="mt-6">
            Tummy Breathing helps you to not think about the pain which can help
            make the pain less sore.
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          onClick={onNext}
          className="px-8 py-2 text-lg rounded-md bg-green-500 text-white font-bold"
        >
          Next
        </button>
      </div>      
    </div>
  );
};

export default DistractYourMind;
