import React, { useEffect } from "react";
import tummyBreathing from "../../assets/pain diary/tummyBreathing.png";
import coin from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
const Recap = () => {
    useEffect(() => {
        const audio = new Audio(coins);    
        
        return () => {
          audio.pause();
          audio.currentTime = 0;
        };
      }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn -mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-center mb-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-[#6BBF59] flex items-center">
          Recap
          <img
            src={coin}
            className="w-14 h-12 ml-2 md:ml-3 heartbeat"
            alt="coin"
          />
        </h2>
      </div>
      <div className="flex flex-col md:flex-row items-center md:items-start justify-between px-2 md:px-5 font-gillSans text-lg md:text-xl">
        {/* Image Section */}
        <div className="w-full md:w-1/2 lg:w-2/5 flex justify-center mb-4 md:mb-0">
          <img
            src={tummyBreathing}
            alt="Recap Illustration"
            className="w-48 md:w-64 lg:w-72 h-auto"
          />
        </div>

        {/* Content Section */}
        <div className="w-full md:w-1/2 lg:w-3/5 mt-6 md:mt-0 space-y-4 md:pl-6">
          <p className="text-base md:text-lg lg:text-xl">
            Last week you learned that there are special skills that you can do
            to help you feel better when you have pain.
          </p>
          <p className="text-base md:text-lg lg:text-xl font-semibold">
            This is called coping.
          </p>
          <p className="text-base md:text-lg lg:text-xl">
            When you have pain, you can do Tummy Breathing to help you cope with
            your pain.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Recap;
