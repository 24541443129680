import React, { useEffect } from "react";
import tummyBreathingVideo from "../../assets/videos/2. Tummy Breathing_F.mp4";
import coinIcon from "../../assets/Icons/Coin.png";
import coins from "../../assets/audios/coins.mp3";
const TummyBreathing = () => {
  useEffect(() => {
    const audio = new Audio(coins);    
    
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn -mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-3xl md:text-4xl font-bold text-left text-[#6BBF59] flex items-center">
          Tummy Breathing
          <img
            src={coinIcon}
            className="w-14 h-12 ml-2 md:ml-3 heartbeat"
            alt="coin"
          />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-start justify-between px-2 md:px-5 font-gillSans text-lg md:text-xl space-y-5 md:space-y-0">
        {/* Left Side: Text Content */}
        <div className="w-full md:w-1/2 pr-8">
          <p className="text-base md:text-lg lg:text-xl mb-4">
            Tummy breathing is a special way of taking deep breaths that helps
            you relax and feel better.
          </p>
          <p className="text-base md:text-lg lg:text-xl mb-4">
            It’s like giving your body a big hug to feel better.
          </p>
          <p className="text-base md:text-lg lg:text-xl mb-4">
            Let’s watch the video to remember how to do this special skill.
          </p>
        </div>

        {/* Right Side: Video */}
        <div className="w-full md:w-1/2 mr-10">
          <video
            controls
            className="w-full rounded-md shadow-lg"
            src={tummyBreathingVideo}
            alt="Tummy Breathing Video"
          />
        </div>
      </div>
    </div>
  );
};

export default TummyBreathing;
