import React, { useEffect } from "react";
import coinIcon from "../../assets/Icons/Coin.png";
import heatpack from "../../assets/pain diary/Heat Pack 2.png";
import coins from "../../assets/audios/coins.mp3";

const UsingHeatPackInfo = ({ onNext }) => {
    useEffect(() => {
        const audio = new Audio(coins);    
        
        return () => {
          audio.pause();
          audio.currentTime = 0;
        };
      }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-6">
          Using a Heat Pack
          <img src={coinIcon} alt="coin" className="w-14 h-12 ml-2 md:ml-3 heartbeat" />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full mt-8">
        {/* Left: Image */}
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={heatpack}
            alt="Heat Pack"
            className="w-full md:w-64 lg:w-72 h-auto"
          />
        </div>

        {/* Right: Text Content */}
        <div className="w-full md:w-1/2 text-lg md:text-xl text-left space-y-4 mr-7">
          <p>
            When you have pain, you can use a heat pack on the body part that
            hurts.
          </p>
          <p>
            A heat pack feels <span className="text-[#6BBF59] font-bold">warm and nice</span> when <br /> you put it on your
            body.
          </p>
          <p className="text-[#6BBF59] font-bold">
            It can help make your pain not hurt as much.
          </p>
          <p>
            Annie loves to use a heat pack when <br /> she has a pain in her tummy.
          </p>
          <p>Let’s find out more from Annie.</p>
        </div>
      </div>
    </div>
  );
};

export default UsingHeatPackInfo;
