import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import homeButton from "../../../assets/homeBtn.png";
import sectionsBG from "../../../assets/sectionsBG.png";
import ProgressBar from "../../ProgressBar";
import Crew from "./Crew";
import GetStarted from "./GetStarted";
import Introduction from "./Introduction";
import Missions from "./Missions";
import MissionToYou from "./MissionToYou";
import NeedBreak from "./NeedBreak";
import NeedHelp from "./NeedHelp";
import Ranking from "./Ranking";
import ReadAloud from "./ReadAloud";

const Information = () => {
  const [section, setSection] = useState(1);
  const [inputValue, setInputValue] = useState(
    sessionStorage.getItem("firstThenInput") || ""
  ); // Initialize with session storage value
  const navigate = useNavigate();
  const location = useLocation();
  const carrier = location.state?.carrier;
  const [counter, setCounter] = useState(1);

  const handlePrevious = () => {
    if (section > 1) setSection(section - 1);
  };

  const handleNext = () => {
    if (isLastPage) {
        navigate("/map", { state: { carrier } });
    } else {
        setSection(section + 1);
    }    
  };

  const isPreviousDisabled = section === 1;
  const isLastPage = section === 9;

  const totalSections = 9;

  const handleHomeClick = () => {
    navigate(-1);
  };

  return (
    <div className="h-screen flex flex-col justify-between pb-4 pr-3 pl-3 pt-1 bg-[#3FA7D6] fadeIn">
      <div className="flex flex-row gap-5 items-center -mb-9 z-10">
        <img
          src={homeButton}
          alt="home-btn"
          className="cursor-pointer z-10 w-9 hover:scale-110 hover:invert"
          onClick={handleHomeClick}
        />
        <div className="pl-56">
          <ProgressBar currentSection={section} totalSections={totalSections} />
        </div>
      </div>
      <div className="sections flex-grow">
        <div
          className="h-full flex items-center justify-center relative"
          style={{
            backgroundImage: `url(${sectionsBG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 flex flex-col justify-center items-center pt-4 md:pt-0">
            {section === 1 && <Introduction />}
            {section === 2 && <Missions />}
            {section === 3 && <Crew />}
            {section === 4 && <Ranking />}
            {section === 5 && <NeedHelp />}
            {section === 6 && <NeedBreak />}
            {section === 7 && <ReadAloud />}
            {section === 8 && <MissionToYou />}
            {section === 9 && <GetStarted />}
          </div>
        </div>
      </div>
      <div className="flex justify-around">
        <button
          className="px-12 py-4 rounded-full font-bold text-white tracking-widest uppercase transform transition-colors duration-200"
          onClick={handlePrevious}
          disabled={isPreviousDisabled}
          style={{
            backgroundColor: isPreviousDisabled ? "#1e1e1e" : "#3FA7D6",
            color: isPreviousDisabled ? "#000000" : "#ffffff",
          }}
        >
          <svg
            className="w-5 h-5 transform rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        <button
          className="px-12 py-4 rounded-full font-bold text-white tracking-widest uppercase transform transition-colors duration-200"
          onClick={handleNext}          
          style={{
            backgroundColor: isLastPage ? "#1e1e1e" : "#3FA7D6",
          }}
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Information;
