import React from "react";
import cptCharlie from "../../assets/cptCharlie.png";
import tummyBreathing from "../../assets/pain diary/tummyBreathing.png";
import arrow from "../../assets/pain diary/arrow.png";
import resources from "../../assets/pain diary/Resources.png";

const EndMission7Continued = () => {
  return (
    <div className="w-full text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-auto xl:pr-7 fadeIn mx-auto">
      <h2 className="text-xl font-bold mb-4 text-center md:text-2xl lg:text-4xl font-gillSans">
        Mission this week continued
      </h2>
      <div className="text-gray-700 flex flex-col lg:flex-row items-center gap-8 text-base font-gillSans">
        {/* Left Text Section */}
        <div className="flex-1">
          <p className="mt-2 lg:text-xl">
            Captain Charlie has an extra task for you to do..
          </p>
          <p className="mt-5 lg:text-xl">
            <span className="font-semibold">
              Extra task: If a body part hurts, try practicing Tummy Breathing. Captain Charlie wants to know if you practiced it during the week.
            </span>
          </p>
          <p className="mt-5 lg:text-xl">
            You can watch the video on Tummy Breathing in the resources section.
          </p>
        </div>

        {/* Right Image Section */}
        <div className="flex-1">
          <img
            src={cptCharlie}
            alt="Captain Charlie"
            className="w-48 md:w-60 lg:w-72 mx-auto"
          />
        </div>
      </div>

      {/* Image Row Below Text */}
      <div className="flex items-center justify-center gap-4 ">
        {/* Resources Image */}
        <img
          src={resources}
          alt="Resources"
          className="w-20 md:w-24 lg:w-28"
        />
        {/* Arrow Image */}
        <img
          src={arrow}
          alt="Arrow"
          className="w-12 md:w-16 lg:w-20"
        />
        {/* Tummy Breathing Image */}
        <img
          src={tummyBreathing}
          alt="Tummy Breathing"
          className="w-38 md:w-34 lg:w-38"
        />
      </div>
    </div>
  );
};

export default EndMission7Continued;
