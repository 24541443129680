import axios from "axios";
import React, { useEffect, useState } from "react";
import coin from "../assets/Icons/Coin.png";
import coins from "../assets/audios/coins.mp3";

const School = ({ carrier, setIsOnChildComponent }) => {
  const [showMissedDays, setShowMissedDays] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const [missedDays, setMissedDays] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const userId = carrier.userId;
  const missionId = "M" + carrier.currentMissionId;
  const pageNum = 6;
  const activityId = missionId + "School";

  useEffect(() => {
    const audio = new Audio(coins);    
    setIsOnChildComponent(true);
    // Clean up the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);

  const audioPlay = () => {
    const audio = new Audio(coins);
    audio.play();
  };

  const handleYesButtonClick = async () => {
    setShowMissedDays(true);
    setShowContinue(false);
    audioPlay();
    setIsOnChildComponent(false);    
  };

  const handleNoButtonClick = async () => {
    setShowMissedDays(false);
    setShowContinue(true);
    await saveAnswer(0);
    audioPlay();
    sessionStorage.setItem("schoolQuizCompleted", "true");
    setIsOnChildComponent(false);
  };

  const handleDayClick = async (day) => {
    setMissedDays(day);
    setSelectedDay(day);
    await saveAnswer(day);
    sessionStorage.setItem("schoolQuizCompleted", "true");
  };

  const saveAnswer = async (activityValue) => {
    const payload = [
      {
        userId: userId,
        missionId: missionId,
        pageNum: pageNum,
        activityId: activityId,
        activityValue: activityValue,
      },
    ];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
    } catch (error) {
      console.error("Error saving answer:", error);
    }
  };

  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans flex items-center justify-center">
        School
        <img src={coin} className="w-14 h-12 ml-2 md:ml-3 heartbeat" alt="coin" />
      </h2>

      <div className="text-gray-700 text-base font-gillSans">
        <p className="lg:mt-10 text-center lg:text-xl md:mr-1">
          Did you miss any days from school this week because you were sick/or
          had pain?
        </p>
        <div className="flex flex-row space-x-5 items-center justify-center lg:mt-8 md:mt-4">
          <button
            className="px-12 py-4 rounded-full bg-green-500 font-bold text-white tracking-widest uppercase transform hover:scale-105 hover:bg-green-700 transition-colors duration-200"
            onClick={handleYesButtonClick}
          >
            Yes
          </button>
          <button
            className="px-12 py-4 rounded-full bg-red-500 font-bold text-white tracking-widest uppercase transform hover:scale-105 hover:bg-red-700 transition-colors duration-200"
            onClick={handleNoButtonClick}
          >
            No
          </button>
        </div>
        {showMissedDays && (
          <div className="text-center mt-5 fadeIn">
            <p className="mb-3 text-xl">How many days did you miss?</p>
            <ol className="list-inside text-center slideUp lg:text-lg">
              {[1, 2, 3, 4, 5].map((day) => (
                <li
                  key={day}
                  className={`hover:scale-110 transition-transform duration-300 cursor-pointer hover:text-[#6BBF59] ${
                    selectedDay === day
                      ? "text-blue-500 border border-blue-500 rounded-full px-14 py-2 w-1/5 mx-auto"
                      : ""
                  }`}
                  onClick={() => handleDayClick(day)}
                >
                  {day} Day{day > 1 ? "s" : ""}
                </li>
              ))}
            </ol>
          </div>
        )}
        {showContinue && (
          <div className="text-center mt-5 fadeIn">
            <p>Great! Click next to continue!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default School;
