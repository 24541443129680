import React from "react";
import drReillyImage from "../../assets/Characters/Riley.png";
import coin from "../../assets/Icons/Coin.png";

const RecapPage = () => {
    return (
        <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn -mt-10">
            {/* Title Section */}
            <div className="flex items-center justify-center mb-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center flex items-center">
                    Recap
                    <img src={coin} className="w-6 md:w-8 ml-2 md:ml-3 heartbeat" alt="coin"/>
                </h2>
            </div>
            <div className="flex flex-col md:flex-row items-center md:items-start justify-between px-2 md:px-5 font-gillSans text-lg md:text-xl">
                {/* Doctor's Image */}
                <div className="w-full md:w-1/2 lg:w-2/5 flex justify-center mb-4 md:mb-0">
                    <img
                        src={drReillyImage}
                        alt="Dr. Reilly"
                        className="w-48 md:w-64 lg:w-72 h-auto"
                    />
                </div>

                {/* Content Section */}
                <div className="w-full md:w-1/2 lg:w-3/5 mt-6 md:mt-0 space-y-4 md:pl-6">
                    <p className="text-base md:text-lg lg:text-xl">In the last mission you met Doctor Riley.</p>
                    <p className="text-base md:text-lg lg:text-xl">
                        You learned about two very special skills that a Doctor can do.
                    </p>
                    <ul className="list-decimal pl-5 text-base md:text-lg lg:text-xl space-y-2">
                        <li><span className="font-semibold">Find out why your body part hurts</span></li>
                        <li><span className="font-semibold">Help you feel better</span></li>
                    </ul>
                    <p className="text-base md:text-lg lg:text-xl">
                        Doctors are <span className="font-semibold">Health Superheros</span>: They help you feel better
                        when you have pain.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RecapPage;
