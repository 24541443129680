import React, { useEffect } from "react";
import coinIcon from "../../assets/Icons/Coin.png";
import heatPackVideo from "../../assets/videos/Heat Pack .mp4";
import coins from "../../assets/audios/coins.mp3";
const HeatPackVideoPage = ({ onNext }) => {
  useEffect(() => {
    const audio = new Audio(coins);

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn flex flex-col items-center justify-center mt-10">
      {/* Title Section */}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl md:text-4xl font-bold text-[#6BBF59] flex items-center ml-6">
          Using a Heat Pack
          <img src={coinIcon} alt="coin" className="w-14 h-12 ml-2 md:ml-3 heartbeat" />
        </h2>
      </div>

      {/* Content Section */}
      <div className="flex flex-col items-center justify-between w-full mt-8">
        {/* First Row: Text Content */}
        <div className="w-full text-lg md:text-xl text-left space-y-4 mb-5">
          <p>
            Play the video to hear Annie talk about using a heat pack when her
            tummy hurts.
          </p>
        </div>

        {/* Second Row: Video Content */}
        <div className="w-full flex justify-center">
          <video
            controls
            className="w-2/3
             h-auto rounded-lg shadow-lg"
            src={heatPackVideo}
            alt="Heat Pack Video"
          />
        </div>
      </div>
    </div>
  );
};

export default HeatPackVideoPage;
