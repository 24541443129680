import React, { useState } from "react";
import axios from "axios";
import painScale0 from "../../assets/pain scale/0.png";
import painScale2 from "../../assets/pain scale/2.png";
import painScale4 from "../../assets/pain scale/4.png";
import painScale6 from "../../assets/pain scale/6.png";
import painScale8 from "../../assets/pain scale/8.png";
import painScale10 from "../../assets/pain scale/10.png";
import face1 from "../../assets/audios/speech/PainDiary/Face1.mp3";
import face2 from "../../assets/audios/speech/PainDiary/Face2.mp3";
import face3 from "../../assets/audios/speech/PainDiary/Face3.mp3";
import face4 from "../../assets/audios/speech/PainDiary/Face4.mp3";
import face5 from "../../assets/audios/speech/PainDiary/Face5.mp3";
import face6 from "../../assets/audios/speech/PainDiary/Face6.mp3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CaptainCharlieCheckIn = ({ carrier }) => {
  const [selectedFace, setSelectedFace] = useState(null);

  const faces = [
    { src: painScale0, label: "0\nNo Hurt", audio: face1 },
    { src: painScale2, label: "2\nHurts Little Bit", audio: face2 },
    { src: painScale4, label: "4\nHurts Little More", audio: face3 },
    { src: painScale6, label: "6\nHurts Even More", audio: face4 },
    { src: painScale8, label: "8\nHurts Whole Lot", audio: face5 },
    { src: painScale10, label: "10\nHurts Worst", audio: face6 },
  ];

  const handleFaceClick = async (index) => {
    setSelectedFace(index);
    sessionStorage.setItem("FeelingBetterQuizCompleted", "true");

    // Play the audio corresponding to the face
    const audio = new Audio(faces[index].audio);
    audio.play();

    // Save activity
    await saveActivity(index);
  };

  const saveActivity = async (index) => {
    const activityValue = `wong face: ${faces[index].label.split("\n")[1]}`;
    const payload = [
      {
        userId: carrier.userId,
        missionId: "M8",
        pageNum: 16,
        activityId: "M8CaptainCharlieCheckIn",
        activityValue: activityValue,
      },
    ];

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}mission/activity/saveMissionActivityDetails`,
        payload
      );
      console.log("Activity saved successfully:", response.data);
      toast.success("Activity saved successfully!");
    } catch (error) {
      console.error("Error saving activity:", error);
      toast.error("Failed to save activity. Please try again.");
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto text-sm bg-transparent p-4 md:p-8 lg:p-10 fadeIn">
      {/* Title Section */}
      <div className="mb-6">
        <h2 className="text-2xl md:text-3xl font-bold text-center text-[#6BBF59]">
          Captain Charlie Check-In
        </h2>
      </div>

      {/* Question Section */}
      <div className="flex flex-col items-center space-y-6">
        <p className="text-lg md:text-xl text-center">
          How did you feel after you do tummy breathing?
        </p>

        {/* Pain Scale Faces */}
        <div className="flex justify-around items-center gap-4 w-full max-w-3xl">
          {faces.map((face, index) => (
            <div key={index} className="flex flex-col items-center">
              <img
                src={face.src}
                alt={`Pain Scale ${index}`}
                className={`cursor-pointer transform transition-transform duration-200 ${
                  selectedFace === index
                    ? "scale-110 border-4 border-green-500 rounded-full"
                    : "hover:scale-105"
                }`}
                onClick={() => handleFaceClick(index)}
              />
              <span className="text-center text-sm md:text-base font-semibold whitespace-pre-line mt-2">
                {face.label}
              </span>
            </div>
          ))}
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default CaptainCharlieCheckIn;
