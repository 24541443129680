import React from "react";
import speaker from "../../../assets/speaker.png";

const Support = () => {
  return (
    <div className="w-80 h-72 text-sm max-w-4xl bg-transparent md:w-[60%] lg:h-96 xl:h-1/2 xl:pr-7 fadeIn">
      <h2 className="text-xl font-bold mb-2 text-center text-[#6BBF59] md:text-2xl md:mt-2 lg:text-4xl font-gillSans">
        Support Options
      </h2>
      <div className="text-gray-700 text-base font-gillSans mx-10 -mt-4">
        <p className="mt-5">
          There are a number of support options available to your child during
          the programme.
        </p>
        <div className="flex flex-row">
          <ul>
            <li className="mt-2 text-sm">
              <div className="flex flex-row gap-2">
                <button className="px-8 py-2 rounded-md bg-[#EE6352] text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]">
                  Break
                </button>
                <p>
                  If your child needs to take a break during the mission there
                  is a break card available in the top right corner of the
                  screen. Your child can select the break card if they require a
                  break and a break symbol will appear. When your child is ready
                  to start back after their break they select end break and the
                  mission will begin where they left off.
                </p>
              </div>
            </li>
            <li className="mt-5 text-sm">
              <div className="flex flex-row gap-2">
                <button className="px-8 py-2 rounded-md bg-[#EE6352] text-white font-bold transition duration-200 hover:bg-[#ebdbc4] hover:text-[#374151] border-2 border-transparent hover:border-[#9c6334]">
                  Help
                </button>
                <p>
                  If your child requires help during the mission, they can
                  select the help button at the top right side of the programme
                  page. When they select this, audio will play saying 'I need
                  help'. If your child selects this option, it is important that
                  you the caregiver (parent/guardian) are available to offer
                  help. If you notice that your child needs help during the
                  mission but doesn't select the help button themselves, it is
                  recommended that you prompt your child to select the help
                  button.
                </p>
              </div>
            </li>
            <li className="mt-5 text-sm">
              <div className="flex flex-row gap-2">
                <img src={speaker} alt="Speaker" className=" w-20 h-20" />
                <p>
                  A read aloud option is available if you child would like the
                  text to read aloud.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Support;
